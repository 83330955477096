import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { Banner } from '../model/banner';



@Injectable({
    providedIn: 'root'
})
export class BannerService {
    getCategoryNameById(id: number) {
        throw new Error('Method not implemented.');
    }
    private apiPath: string;
    constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }


    async addBanner(Banner: Banner) {
        Banner.BannerId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("Banner", Banner);
        console.log(res);
        return res;
    }


    async getBanner(id: number): Promise<any> {
        try {
          return await this.service.Data.ExecuteAPI_Get<any>("Banner/"+id+"?bannerId="+id);
        } catch (error) {
          console.error('Error fetching categories:', error);
          return null;
        }
      }

    async getAllBanners(){
        //return this.http.get<any>(this.apiPath + '/api/Banner');
        try {
            return await this.service.Data.ExecuteAPI_Get<any>("Banner");
          } catch (error) {
            console.error('Error fetching Bannerlist:', error);
            return [];
          }
    }

    getUserBanners(userName: string) {
        return this.http.get<Banner[]>(this.apiPath + '/api/Banner/userBanner/' + userName);
    }

    async updateBanner(Banner: any) {
        let res = await this.service.Data.ExecuteAPI_Put("Banner",`${Banner.BannerId}?BannerId=${Banner.BannerId}`, Banner);
        console.log(res);
        return res;
    }

    async deleteBanner(id: number) {
        let res = await this.service.Data.ExecuteAPI_Delete("Banner/delete", `${id}?bannerid=${id}`);
        return res;

    }
}
