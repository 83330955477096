import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from '../../model/blog';
import { AddCommentService } from '../../services/comments.service';
import { AlertifyService } from '../../services/alertify.service';
import { BlogBannerService } from '../../services/blogBanner.service';
import { CommentBoxService } from '../../services/commentBox.service';
import { PostsService } from '../../services/posts.service';
import { DatePipe } from '@angular/common';
import { SubCategoryService } from '../../services/subcategory.service';
import { CategoryService } from '../../services/category.service';
import { SystemService } from '../../services/system.service';
// import * as BadWordsFilter from 'bad-words';
import { ProfileService } from '../../services/profileService';
import { BlogCommentService } from '../../services/blogcomment.service';
@Component({
    selector: 'app-blog-details-user-view',
    templateUrl: './blog-details-user-view.component.html',
    styleUrls: ['./blog-details-user-view.component.scss'],
    providers: [DatePipe],
})
export class BlogDetailsUserViewComponent implements OnInit {
    isUser: boolean; isLoading = false;
    public postId: number;
    post: Blog;
    userSubmitted: boolean;
    commentForm: FormGroup;
    comment: Comment;
    comments: any[];
    currentUser = localStorage.getItem('token');
    isEdit = false;
    newTitle: string;
    newDescription: string;
    loggedinUser: string;
    admin: string;
    postdetails: any;
    category: any;
    subcategoty: any;
    addBlogForm: any;
    imageUrl: any; categories: any; categoryCountsArray: { name: string, count: number }[] = [];
    filteredTypeCardlistposts: any;
    filteredTypeCardlistCats: any;
    visitCount: number = 0;
    apiPath: any;
    isLoggedIn = localStorage.getItem("id");
    commentdata: any;
    badWordsFilter: any;
    showReplyForm: boolean = false;
    showReplyFormId: any;Profiles: any;
    rowData: any;



    constructor(private route: ActivatedRoute,
        private postsService: PostsService,
        private addCommentService: AddCommentService,
        private fb: FormBuilder,private ProfileService: ProfileService,
        private commentBoxJS: CommentBoxService,
        private blogaBannerService: BlogBannerService,
        private alertify: AlertifyService,
        private router: Router,
        private categoryService: CategoryService,
        private SubcategoryService: SubCategoryService,
        private BlogCommentService: BlogCommentService,
        private PostsService: PostsService, public service: SystemService) {
        // this.badWordsFilter = new BadWordsFilter();
        this.route.params.subscribe(params => {
            this.postId = params['id'];
            console.log('ID:', this.postId);
            // Now you can use the 'id' as needed in your component logic
        });
    }

    ngOnInit() {
        this.CreatecommentForm();
        this.apiPath = this.service.Settings.Base_API_URL;
        this.loadPost(this.postId);
        this.isUser = true;
        this.commentBoxJS.commentBox();
        this.loadCategories()
        this.loadSideOrderPosts();
        //fetch comments
        this.fetchComments();
        this.refreshGridData();
    }
    private gridApi: any;
    loadSideOrderPosts() {
        this.filteredTypeCardlistposts = this.shuffleArray(this.postsService.getlocalAllPostCall()).slice(0, 5);
        console.log(this.filteredTypeCardlistposts);
    }
    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    getCategoryOnlyNames(category: string): string {
        // Convert category string to an array of category names
        const categoryIds = category.split(',').map(Number);
        const categoryNames = this.categories
            .filter(item => categoryIds.includes(item.categoryId))
            .map(item => item.categoryName);

        return categoryNames.join(', ');
    }

    loadSideOrderCategories() {
        this.filteredTypeCardlistCats = this.postsService.getlocalAllPostCall();
        console.log(this.filteredTypeCardlistCats);

        // Initialize a Map to store category name counts
        const categoryCountsMap = new Map<string, number>();

        for (let post of this.filteredTypeCardlistCats) {
            console.log(post.category);

            // Get category names for the current post
            const postCategoryNames: { name: string, count: number }[] = this.getCategoryNames(post.category);

            // Count occurrences of each category name
            postCategoryNames.forEach(({ name }) => {
                categoryCountsMap.set(name, (categoryCountsMap.get(name) || 0) + 1);
            });
        }

        // Now, categoryCountsMap contains the counts for each category name
        // You can convert it to an array of "Name, Count" pairs
        categoryCountsMap.forEach((count, name) => {
            this.categoryCountsArray.push({ name, count });
        });

        console.log(this.categoryCountsArray);
    }

    getCategoryNames(category: string): { name: string, count: number }[] {
        // Convert category string to an array of category names
        const categoryIds = category.split(',').map(Number);
        const categoryNames = this.categories
            .filter(item => categoryIds.includes(item.categoryId))
            .map(item => item.categoryName);

        const countedCategoryNames = {};

        categoryNames.forEach(name => {
            countedCategoryNames[name] = (countedCategoryNames[name] || 0) + 1;
        });

        // Convert the countedCategoryNames object to an array of "Name, Count" pairs
        const categoryCountsArray: { name: string, count: number }[] = [];

        for (const name in countedCategoryNames) {
            if (countedCategoryNames.hasOwnProperty(name)) {
                const count = countedCategoryNames[name];
                categoryCountsArray.push({ name, count });
            }
        }

        return categoryCountsArray;
    }

    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;
            console.log(JSON.stringify(this.categories));
            // Example usage
            const searchIds = [40, 42]; // Replace with the IDs you want to search
            //const resultCategoryNames = getCategoryNamesById(searchIds);
            //console.log(resultCategoryNames);
            this.loadSideOrderCategories();
        });
    }

    loadPost(Id: any) {
        this.isLoading = true;
        this.PostsService.getPost(Id).then(postdetails => {
            console.log(postdetails,"postdetails1");
            this.postdetails = postdetails;
            //console.log(JSON.stringify(this.postdetails));
            const Categorybind = postdetails.category.split(',').map(Number);
            console.log(Categorybind);
            const SubCategorybind = postdetails.subcategoty.split(',').map(Number);
            console.log(SubCategorybind);
            //**************************************PatchValue */
            this.category = Categorybind;
            this.subcategoty = SubCategorybind;
            console.log(postdetails,"postdetails2");
            //this.addBlogForm.patchValue({ tags: postdetails.tags });
            //this.addBlogForm.patchValue({ description: postdetails.description });
            //this.addBlogForm.patchValue({ title: postdetails.title });
            this.imageUrl = postdetails.bannerImage;
            this.loadprofile(postdetails.userid)
            //this.addBlogForm.patchValue({ bannerImage: postdetails.bannerImage });
            //**************************************PatchValue */
            //this.addBlogForm.patchValue(postdetails);
            this.isLoading = false;
        });
        this.isLoading = false;
    }

    async loadprofile(id: string)
    {
        try {
                let profileData = await this.ProfileService.getProfileByUserId(id);
                console.log(JSON.stringify(profileData));
                this.Profiles = profileData;
                console.log(JSON.stringify(profileData));
                this.imageUrl = profileData.ProfilePhoto;
        }
        catch {
        }
    }

    edit() {
        this.isEdit = true;
    }

    cancelUpdate() {
        this.isEdit = false;
    }

    updatePost(id: number) {
        // this.postsService.updatePost(id, this.newTitle, this.newDescription).subscribe(
        //   data => {
        //     this.isEdit = false;
        //     this.fetchpost();
        //   }
        // );

    }

    deletePost(id: number) {
        this.postsService.deletePost(id).subscribe(
            data => {
                this.addCommentService.deleteAllComment(id).subscribe(
                    comment_data => {
                        this.alertify.success("You have deleted the post");
                        this.router.navigate(['/home']);
                    }
                );
            }
        );
    }


    CreatecommentForm() {
        this.commentForm = this.fb.group({
            content: ['', Validators.required]
        });
    }

    onSubmit() {
        this.userSubmitted = true;
        let obj = this.commentForm.getRawValue();
        if (this.badWordsFilter.isProfane(obj.content)) {
            this.alertify.error("'Warning: Inappropriate content detected.'");
        } else {
            console.log(obj);
            let res = this.addCommentService.addComment(this.commentData());
            console.log(res);
            this.fetchComments();
            this.commentForm.reset();
            this.userSubmitted = false;
            this.alertify.success("'Thank you for the Comment.'");
            // Process and submit the comment


        }
    }
    refreshGridData(): void {
        this.binddata();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
     }

    deleteRow(rowData: any) {
        const confirmation = window.confirm('Are you sure you want to delete?');
        console.log(rowData,'rowData');

        if (confirmation) {
            console.log(rowData,'rowData');
            this.BlogCommentService.deleteBlogComments(rowData).then(res => {
                console.log(res,'resdelete');
                if (res) {
                    // this.binddata();
                    this.refreshGridData();
                    this.alertify.success("Deleted Successfully");
                    // this.router.navigate(['/blogcomments']);
                } else {
                    this.alertify.error("Try again! or Contact admin!");
                    this.router.navigate(['/blogcomments']);
                }
                console.log(JSON.stringify(res));
            }).catch(error => {
                console.error("Error deleting questionnaire:", error);
                this.alertify.error("An error occurred while deleting the data.");
            });
        } else {
            // User canceled the deletion
            console.log('Deletion canceled by user.');
        }
    }
    binddata() {
        this.service.App.ShowLoader = true;
        this.BlogCommentService.getAllBlogComments().then(data => {
            this.rowData = data;
            // this.alertify.success("Data Loaded Successfully");
        });
        this.service.App.ShowLoader = false;
    }
    showReplyFormValue(comment_id: string) {
        console.log("RRR" + comment_id)
        this.showReplyFormId = comment_id;
        // Reset reply form on toggle
        this.commentForm.reset();
      }
    onSubmitReply(commentId: number)
    {
        this.userSubmitted = true;
        let obj = this.commentForm.getRawValue();
        if (this.badWordsFilter.isProfane(obj.content)) {
            this.alertify.error("'Warning: Inappropriate content detected.'");
        } else {
            console.log("RRR!" + JSON.stringify(this.commentDataReplay(commentId)));
            let res = this.addCommentService.addComment(this.commentDataReplay(commentId));
            console.log(res);
            this.fetchComments();
            this.commentForm.reset();
            this.userSubmitted = false;
            this.alertify.success("'Thank you for the Replay.'");
            // Process and submit the comment
        }
    }
    commentDataReplay(commentId: number): any {
        console.log(this.commentForm.valid);
        let obj = this.commentForm.getRawValue();
        console.log(obj);
        console.log("RRR --" + this.showReplyFormId)
        const comments: any = {
            parentCommentId: commentId,
            postId: this.postId,
            content: obj.content,
            UserId: localStorage.getItem('id') || '',
            userName: localStorage.getItem('username') || '',
            commentedOn: new Date().toString(),
            created_at: new Date().toString(),
        };
        console.log("RRR --" + JSON.stringify(this.comments))
        return comments;
    }

    commentData(): any {
        console.log(this.commentForm.valid);
        let obj = this.commentForm.getRawValue();
        console.log(obj);
        const comment: any = {
            postId: this.postId,
            content: obj.content,
            UserId: localStorage.getItem('id') || '',
            userName: localStorage.getItem('username') || '',
            commentedOn: new Date().toString(),
            created_at: new Date().toString(),
        };

        return comment;
    }

    fetchComments() {
        this.addCommentService.getComments(this.postId).then(comment_data => {
            this.commentdata = comment_data;
            console.log(JSON.stringify(this.commentdata));
        });
        //let comment_data = this.addCommentService.getComments(this.postId);
        //console.log(comment_data);
    }

    loggedin() {
        this.loggedinUser = localStorage.getItem('token');
        this.commentBoxJS.commentBox();
        return this.loggedinUser;
    }

    isAdmin() {
        this.admin = localStorage.getItem('admin');
        return this.admin;
    }


    @Input() comment2: any;
    newComment: string;
    commentId: number;

    updateComment(id: any) {
        this.commentId = Number(id);
        this.addCommentService.updateComment(this.commentId, this.newComment).subscribe(
            data => {
                this.isEdit = false;
                this.newComment = "";
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate([this.router.url]);
            });
    }

    deleteComment(id: number) {
        this.addCommentService.deleteComment(id).subscribe(
            data => {
                this.alertify.success("Comment deleted");
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate([this.router.url]);
            }
        )
    }



}
