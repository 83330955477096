import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Blog } from '../../model/blog';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { NewBlogService } from '../../services/newBlog.service';
import { HttpClient } from '@angular/common/http';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { CategoryService } from '../../services/category.service';
import { SubCategoryService } from '../../services/subcategory.service';
import { SystemService } from '../../services/system.service';
import { PostsService } from '../../services/posts.service';

@Component({
    selector: 'app-blog-details-add',
    templateUrl: './blog-details-add.component.html',
    styleUrls: ['./blog-details-add.component.scss'],
    providers: [DatePipe],
})
export class BlogDetailsAddComponent implements OnInit {
    addBlogForm: FormGroup;
    blog: Blog;    public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    category: any;
    subcategoty: any;
    tags: any;
    Title: any;
    Description: any;
    categoryList: any;
    subcategotyList: any;
    @ViewChild('editor') editor;
    categories: any;
    subcategories: any;
    fileName: any;

    constructor(private fb: FormBuilder,
        private newBlogService: NewBlogService,
        private alertify: AlertifyService,
        private router: Router,
        private http: HttpClient, private categoryService: CategoryService, private SubcategoryService: SubCategoryService, private PostsService: PostsService, public service: SystemService) { }



    ngOnInit() {
        this.CreateAddBlogForm();
        this.loadCategories();
        this.loadSubcategory();
    }

    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;
            console.log(JSON.stringify(this.categories));
        });
    }

    loadSubcategory() {
        this.SubcategoryService.getAllSubCategories().then(subcategories => {
            this.subcategories = subcategories;
            console.log(JSON.stringify(this.subcategories));
        });

    }


    onSubmit() {
        this.userSubmitted = true;
        let obj = this.addBlogForm.getRawValue();
        console.log(obj, "TWO");
        if (this.addBlogForm.valid) {
            let obj = this.addBlogForm.getRawValue();
            const blog: Blog = this.addBlogForm.value;
            console.log(obj);
            // Format the date as "dd mm yyyy"
            const currentDate = new Date();
            const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
            obj.postedOn = currentDate.toLocaleDateString('en-US', options);
            obj.author = obj.author;
            obj.category =obj.category .map(String).join(',');
            obj.subcategoty =obj.subcategoty.map(String).join(',');
            obj.bannerImage = this.imageUrl ? this.imageUrl : obj.bannerImage;
            obj.Extra1 = this.fileName;
            if (blog.Id) {
                obj.isActive=true;
                obj.status=true;
                //Update existing category
                let res = this.PostsService.updatePost(blog);
                if (res) {
                    this.loadCategories(); // Refresh the category list
                }
            }
            else {
                obj.isActive=true;
                obj.status=true;
                let res = this.PostsService.addPost(obj);
                if (res) {
                    console.log(JSON.stringify(res));
                    this.loadCategories(); // Refresh the category list
                    this.loadSubcategory();
                    this.addBlogForm.reset();
                    this.router.navigate(['/blog-user']);
                }
            }

            this.userSubmitted = false;
        } else {
            this.alertify.error("Kindly provide the required fields");
        }
    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }


    CreateAddBlogForm() {
        this.addBlogForm = this.fb.group({
            bannerImage: [null, Validators.required],
            title: [null, Validators.required],
            description: [null, Validators.required],
            author: [],
            postedOn: [],
            category: [null, Validators.required],
            subcategoty: [null, Validators.required],
            count: "0",
            tags: [null, Validators.required],
            extra1: "",
            extra2: "",
            extra3: "",
            extra4: "",
            extra5: "",
            extra6: "",
            extra7: "",
            extra8: "",
            extra9: "",
            extra10: "",
            isActive: "1",
            status: "1",
        })
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }

    // onFileSelect(event) {
    //     this.file = event.target.files[0];
    //     this.fileExtension = (".").concat(this.file.name.split('.').pop());
    //     const reader = new FileReader();
    //     reader.readAsDataURL(this.file);
    //     reader.onload = event => {
    //         this.imageUrl = reader.result;
    //     };
    // }


    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    cancel()
    {
        this.addBlogForm.reset();
        this.router.navigate(['/blog-user']);
    }
}

