<app-navbar-style-admin></app-navbar-style-admin>

<div class="inner-banner inner-bg4">
    <div class="container">

        <div class="inner-banner-title text-center">
            <h3>{{postdetails.title}}</h3>
            <!-- <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p> -->
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                        <!-- <li><i class='bx bx-chevron-right'></i></li>
                        <li>Pages</li> -->
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Blog Details</li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{postdetails.title}}</li>
                    </ul>
                </div>

                <!-- <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-article">
                    <h2 class="postCardTitle text-capitalize">{{postdetails?.title}}</h2>
                    <p><br></p>
                    <div class="card-text postCardText" [innerHTML]="postdetails.description"></div>
                    <div *ngIf="isLoading" class="overlay-inner">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>

                    <div class="article-comment-area">
                        <div *ngIf="imageUrl">
                            <img [src]="imageUrl" alt="Images">
                        </div>
                    </div>

                    <div class="article-comment-area">
                        <ul class="article-comment">
                            <li>
                                <div class="image">
                                    <img src="assets/img/blog/blog-author.png" alt="Images">
                                </div>

                                <div class="content">
                                    <h3>By {{postdetails.author}}</h3>
                                    <span>on {{postdetails.postedOn | date:'longDate' }}</span>
                                </div>
                            </li>

                            <li>
                                <div class="content-list">
                                    <h3>Views</h3>
                                    <span>{{postdetails.count}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="article-comment-area">
                        <ul class="article-comment">
                            <li>
                                <div class="image">
                                    <img src="assets/img/blog/blog-author.png" alt="Images">
                                </div>

                                <div class="content">
                                    <h3>Tags</h3>
                                    <span>on {{postdetails.tags}}</span>
                                </div>
                            </li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>


            <div class="col-lg-4">
                <div class="blog-widget-left">
                    <div class="blog-widget ">
                        <h3 class="title">Search</h3>

                        <div class="search-widget">
                            <form class="search-form">
                                <input type="search" class="form-control" placeholder="Search...">
                                <button type="submit"><i class="bx bx-search"></i></button>
                            </form>
                        </div>
                    </div>



                    <div class="blog-widget">
                        <h3 class="title">Recent Posts</h3>

                        <div class="widget-popular-post">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg1" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-01-12">Jnauary 12, 2020</time>
                                    <h4 class="title-text"><a routerLink="/blog-details">Real Estate Is Being</a></h4>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg2" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">June 30, 2020</time>
                                    <h4 class="title-text"><a routerLink="/blog-details">Place Of Expectation</a></h4>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg3" role="img"></span></a>
                                <div class="info">
                                    <span>April 09, 2020</span>
                                    <h4 class="title-text"><a routerLink="/blog-details">Came In The Place </a> </h4>
                                    <p>Jhon hack from an iitial feaity study</p>
                                </div>
                            </article>
                        </div>
                    </div>



                    <div class="blog-widget">
                        <h3 class="title">Categories</h3>

                        <div class="widget_categories">
                            <ul *ngFor="let post of categoryCountsArray">
                                <li><a routerLink="/blog" >{{ post.name }} <span>({{ post.count }})</span></a></li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
