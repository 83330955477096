import { Component, OnInit } from '@angular/core';
import { SystemService } from '../../services/system.service';
import { Account_Model } from '../../model/common_model';

@Component({
    selector: 'app-adminPanel',
    templateUrl: './adminPanel.component.html',
    styleUrls: ['./adminPanel.component.css']
})
export class AdminPanelComponent implements OnInit {
    Account: Account_Model;

    constructor(public service: SystemService) { }

    ngOnInit() {
        this.service.HasAccountData.then(() => {
            this.Account = this.service.getUserAccountData();
            console.log(JSON.stringify(this.Account),"this.Account");
          });
        if (!this.service.App.getCookie("Bearer")) {
            console.log(this.service.App.getCookie("Bearer"),"this.Bearer");
            localStorage.clear();
            this.service.resetPromise();
            this.service.App.setCookie("Bearer", "", 0, "");
            localStorage.removeItem('isAdmin');
            this.Account = <Account_Model>{ UserID: 0, UserName: "" };
            console.log("redirectToLogin");
            this.service.redirectToLogin();
        }

        console.log(JSON.stringify(this.Account));

    }

}
