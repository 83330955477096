

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SubMenu } from '../../model/submenu';


@Component({
  selector: 'app-sub-menu-grid',
  templateUrl: './sub-menu-grid.html',
  styleUrls: ['./sub-menu-grid.css'],
})
export class SubMenuGridComponent implements OnInit {
  @Input() submenu: SubMenu[] = [];
  @Output() editSubMenu = new EventEmitter<SubMenu>();
  @Output() deleteSubMenu = new EventEmitter<number>();
isFirst: any;
menu2: any;


  ngOnInit() {console.log(this.submenu);}

  onEdit(submenus: SubMenu) {
    console.log(submenus);
    this.editSubMenu.emit(submenus);
  }

  onDelete(menuId: number) {
    this.deleteSubMenu.emit(menuId);
  }
}
