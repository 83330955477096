import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { VideoGallery } from '../../model/videogallery';
import { GalleryService } from '../../services/gallery.service';
import { VideoGalleryService } from '../../services/videoGallery.service';
@Component({
    selector: 'app-videogallery-edit',
    templateUrl: './videogallery-edit.component.html',
    styleUrls: ['./videogallery-edit.component.scss'],
    providers: [DatePipe],
})
export class VideoGalleryEditComponent implements OnInit {
    videogalleryform: FormGroup;
    gallery: VideoGallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    id: any;
    documentTypeId: any;
    typeId: any;
    galleryrecord: any;
    isLoading = false;
    videoGalleryId: any;

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private galleryService: GalleryService,
        private videogalleryService: VideoGalleryService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService, private route: ActivatedRoute) {

        
    }



    // ngOnInit() {
    //     this.Creategalleryform();
    //     this.loadGalleryId();
    // }
    ngOnInit() {
        this.Creategalleryform();
        this.route.params.subscribe(params => {
            this.id = +params['id']; 
            this.loadGalleryId();
        });
        this.loadDocumentTypeService();
    }

    loadGalleryId() {
        console.log(this.id,"this.videoGalleryIdedit")
        this.isLoading = true;
        this.videogalleryService.getGallery(this.id).then(Gallerydata => {
            this.videogalleryform.patchValue(Gallerydata);
            this.isLoading = false;
        });

    }
    loadDocumentTypeService() {
        this.documentTypeService.getAlldocument().then(documentTypelist => {
            this.documentTypelist = documentTypelist;

        });
    }


    onSubmit() {
        this.userSubmitted = true;
        let obj = this.videogalleryform.getRawValue();
        console.log(JSON.stringify(this.gallery));
        console.log("Update");
        console.log(obj,"objbeforeupdateapicall");
        console.log(obj.videoGalleryId,"objidbeforeupdateapicall");
        if (obj.videoGalleryId) {
            console.log("Update");
            let res = this.videogalleryService.updateGallery(this.convertKeysToCamelCase(obj));
            if (res) {
                this.loadDocumentTypeService(); 
                this.videogalleryform.reset();
                this.router.navigate(['/Videogallerylist']);
                this.alertify.success("Data Updated Successfully");
            }
        }

        this.userSubmitted = false;

    }


    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }


    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Creategalleryform() {
        this.videogalleryform = this.fb.group({
            videoGalleryId: 0,
            videoCategory: ['', Validators.required],
            videoLink: ['', Validators.required],
            videoDescp: ['', Validators.required],
            createdDate: [''],
            extra1: [''],
            extra2: [''],
            extra3: [''],
            extra4: [''],
            extra5: [''],
            extra6: [''],
            extra7: [''],
            extra8: [''],
            extra9: [''],
            extra10: [''],
            isActive: [true],
            status: [true],
            typeDetailId: [''],
            blogDetailId: [''],
            newsDetailId: [''],
            documentTypeId:['', Validators.required],
        });
    }

    cancel() {
        this.videogalleryform.reset();
        this.router.navigate(['/Videogallerylist']);
    }
}

