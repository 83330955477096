import { Component, ElementRef, Renderer2, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';
//import { MenuService } from '../../services/menu.service';
import { SubMenuService } from '../../services/submenu.service';
import { Menu } from '../../model/menu';
import { SubMenu } from '../../model/submenu';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';


@Component({
    selector: 'app-sub-menu-form',
    templateUrl: './sub-menu.html',
    styleUrls: ['./sub-menu.css'],
})
export class SubMenuFormComponent implements OnInit {
    submenuForm: FormGroup;
    // menu: any;
    apiPath: any;
    imageUrl: string;
    //imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg";
    gallery: SubMenu; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    // @Output() CategoryDeleted = new EventEmitter<void>();
    menuParentIds: any;
    MenuList: any
    @ViewChild('blogContainer2') blogContainer: ElementRef; // Use 'blogContainer2' instead of 'blogContainer'
    rowData: any;
    gridApi: any;
    selectedMenuId: number;
    selectedSubMenu: SubMenu | null = null;
    submenu: any;
    selectedMenparentuId: number;
    Dropdown: any;
    fileName: any;



    constructor(
        private fb: FormBuilder,
        private renderer: Renderer2,
        public service: SystemService,

        private alertify: AlertifyService,
        private submenuService: SubMenuService,
    ) {

        // this.loadMainMenu();
        this.loadsubmenugrid();
        this.loaddropdown();
        this.apiPath = this.service.Settings.Base_API_URL;
    }

    ngOnInit() {
        console.log('blogContainer:', this.blogContainer); // Add this line to check the value
        this.submenuForm = this.fb.group({
            menuId: 0,
            menuName: ['', Validators.required],
            menuParentId: 0,
            menuUrl: '',
            menuIcon: '',
            menuContent: '',
            extra1: '',
            extra2: '',
            extra3: '',
            extra4: '',
            extra5: '',
            active: true
        });
        //this.loadMainMenu();
        //this.loaddropdown();
    }
    refreshGridData(): void {
        this.loadsubmenugrid();
        // this.loadMainMenu();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }
    onMaterialGroupChange(event) {
        this.menuParentIds = event;
    }
  

    async onSubmit() {
        let obj = this.submenuForm.getRawValue();
        obj.extra1 = this.imageUrl ? this.imageUrl : obj.extra1;
        const submenu: SubMenu = this.submenuForm.value;
        submenu.menuId = submenu.menuId == null ? 0 : submenu.menuId;
        if (this.submenuForm.valid) {
            this.service.App.ShowLoader = true;
            if (obj.menuId) {
                console.log("Update existing Content");
                try {
                    obj.active = true;
                    let res = await this.submenuService.updateMenus(obj);
                    console.log(JSON.stringify(res));
                    this.alertify.success("Updated Successfully");
                    this.refreshGridData();
                    this.submenuForm.reset();
                } catch (error) {
                    console.error('Error updating subcategory:', error);
                }
            } else {
                console.log("Add new subcategory");
                try {
                    obj.active = true;
                    // obj.submenuForm = 0;
                    let res = await this.submenuService.addMenus(obj);
                    this.alertify.success("Created Successfully");
                    this.refreshGridData();
                    this.submenuForm.reset();
                    this.submenuForm.reset();
                    console.log(JSON.stringify(res));
                    this.refreshGridData();

                } catch (error) {
                    console.error('Error adding subcategory:', error);
                }
            }
            this.submenuForm.reset();
            this.selectedSubMenu = null;
        }
    }



    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }


    loaddropdown() {
        this.submenuService.getDropdown().then(dropdown => {
            console.log(JSON.stringify(dropdown));
            this.Dropdown = dropdown;
        });
    }


    loadsubmenugrid() {
        this.service.App.ShowLoader = true;
        this.submenuService.getAllMenus().then(submenu => {
            this.submenu = submenu;
            this.rowData = submenu;
            console.log(JSON.stringify(this.submenu));
            this.service.App.ShowLoader = false;
        }).catch(error => {
            console.error('Error fetching submenus:', error);
            this.service.App.ShowLoader = false; // Ensure loader is hidden even in case of an error.
        });
    }


    // onEdit(submenu: SubMenu) {
    //     this.selectedSubMenu = submenu;
    //     this.imageUrl = this.apiPath + "/Documents/appImages/" + submenu.extra1;
    //     submenu.extra1 = this.imageUrl ? this.imageUrl : submenu.extra1;
    //     //this.selectedMenuId = submenu.menuParentId;
    //     this.submenuForm.patchValue(submenu);
    //     console.log('Form data after patching:', this.submenuForm.value);
    //     console.log(this.selectedMenuId);
    //     this.scrollIntoViewEdit();
    // }

    onEdit(submenu: SubMenu) {
        this.selectedSubMenu = submenu;
        this.imageUrl = this.apiPath + "/Documents/appImages/" + submenu.extra1;
        submenu.extra1 = this.imageUrl ? this.imageUrl : submenu.extra1;
        // Patch the form values
        this.submenuForm.patchValue({
            menuId: submenu.menuId,
            menuName: submenu.menuName,
            menuParentId: submenu.menuParentId,
            menuUrl: submenu.menuUrl,
            menuIcon: submenu.menuIcon,
            menuContent: submenu.menuContent,
            extra1: submenu.extra1, // Patch imageUrl as extra1
            extra2: submenu.extra2,
            extra3: submenu.extra3,
            extra4: submenu.extra4,
            extra5: submenu.extra5,
            active: submenu.active
        });
    
        console.log('Form data after patching:', this.submenuForm.value);
        this.scrollIntoViewEdit();
    }
    
    scrollIntoViewEdit(): void {
        const gridElement = document.querySelector('.blog-details-area') as HTMLElement;
        if (gridElement) {
            gridElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    onDelete(menuId: number) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            this.submenuService.deleteMenus(menuId).then(() => {
                this.alertify.success("Deleted Successfully");
                this.loadsubmenugrid();  // Refresh the grid after deletion
            }).catch(error => {
                console.error('Error deleting submenu:', error);
                this.alertify.error("Error deleting data");
            }).finally(() => {
                this.service.App.ShowLoader = false;
            });
        } else {
            console.log('Deletion canceled.');
        }

    }
}
export { SubMenu };
