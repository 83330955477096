import { Component, OnInit } from '@angular/core';
import { Blog } from '../../model/blog';
import { AddCommentService } from '../../services/addComment.service';
import { PostsService } from '../../services/posts.service';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { Account_Model } from '../../model/common_model';
import { LogoService } from '../../services/logo.service';
@Component({
  selector: 'app-navbar-style-admin',
  templateUrl: './navbar-style-admin.component.html',
  styleUrls: ['./navbar-style-admin.component.scss']
})
export class NavbarStyleAdminComponent implements OnInit {

  commentCount: any;
  userName = localStorage.getItem('username');
  userEmail = localStorage.getItem('email');
  posts: Blog[];
  postCount: number;
  userID: string;
  Account: any;
  logoData: any;
  imageUrl: string;
  apiPath: any;
  imageUrlcheck: any;
  constructor(private LogoService: LogoService, public service: SystemService, private commentService: AddCommentService, private postService: PostsService, private router: Router, private services: SystemService) {
    this.apiPath = this.service.Settings.Base_API_URL;
    this.loadlogo();

  }

  ngOnInit(): void {


    console.log(this.apiPath, " this.apiPath")
    this.service.HasAccountData.then((data) => {
      this.userID = this.service.Account.userId;
      console.log("Header", JSON.stringify(this.userID));
      if (this.userID) {
        // Do nothing, or you can add specific actions if needed
        console.log("UserID is defined");
      } else {
        console.log("logOut");
        this.service.logOut();
      }
    }, () => { });
  }
  async loadlogo() {
    if (this.imageUrlcheck > 0) {
      this.imageUrl = this.imageUrlcheck;
    }
    else {

      await this.LogoService.GetLatestCompanyDetailslatest().then(logoData => {
        this.logoData = logoData;
        console.log(this.logoData, "logoDataGetLatestCompanyDetailslatest");
        // this.imageUrl = this.apiPath + "/Documents/appImages/" + logoData.logoPath;
        // this.imageUrl =  "https://api.hrmvisas.com/Documents/appImages/638452411180522390G.jpeg";
        // console.log(this.imageUrl,"this.imageUrlheader")
        this.imageUrl = this.apiPath + "/Documents/appImages/" + this.logoData.logoPath;
        this.imageUrlcheck = this.apiPath + "/Documents/appImages/" + this.logoData.logoPath;

        console.log(this.imageUrl, "this.imageUrlheader")
      });
    }
  }
  logout() {
    this.services.logOut();
    localStorage.setItem('token', "");
    localStorage.setItem('email', "");
    localStorage.setItem('admin', "");
    localStorage.setItem('id', "");
    localStorage.clear();
    //console.log(this.service.App.getCookie("Bearer"));
    localStorage.clear();
    this.service.resetPromise();
    this.service.App.setCookie("Bearer", "", 0, "");
    localStorage.removeItem('isAdmin');
    this.Account = <Account_Model>{ UserID: 0, UserName: "" };
    console.log("redirectToLogin");
    this.service.redirectToLogin();
  }
}
