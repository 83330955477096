import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
//import { filterHeaderSearchModel } from '../model/filterHeaderSearch';
import { filterHeaderValueModel } from '../model/filterHeaderValue';
//import { FilterHeaderModel } from '../model/filterHeader';
import { TypeModel } from '../model/type';
import { MenuPageContentModel } from '../model/MenuPageContent';

// MenuPageContentModel

@Injectable({
    providedIn: 'root',
})
export class MenuPageContentService {
    apiUrl: any;
    BearerToken: any;
    FilterHeader: MenuPageContentModel[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }

    async getAllGridData(): Promise<MenuPageContentModel[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<MenuPageContentModel[]>("MenuPageContent");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching FilterHeader:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    async getAllFilterHeaderName(): Promise<MenuPageContentModel[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<MenuPageContentModel[]>("FilterHeaderName");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching FilterHeader:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    async getAllFilterHeaderType(): Promise<TypeModel[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<TypeModel[]>("TypeDetail");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching Typelist:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }
    async getAllFilterHeaderAllData(): Promise<MenuPageContentModel[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<MenuPageContentModel[]>("FilterHeaderSearch");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching FilterHeader:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }
    async getmenupagecontentById(id: number): Promise<MenuPageContentModel> {
        try {
            return await this.service.Data.ExecuteAPI_Get<any>("MenuPageContent/{id}?menupagecontentid=" + id);
        } catch (error) {
            console.error('Error fetching categories:', error);
            return null;
        }

    }

    async addmenupagecontent(menucontentpage: MenuPageContentModel) {
        let res = await this.service.Data.ExecuteAPI_Post("MenuPageContent", menucontentpage);
        return res;
    }


    async updatemenupagecontent(MenuPageContent: any) {
        console.log(MenuPageContent, "MenuPageContentapi response");
        let res = await this.service.Data.ExecuteAPI_Put("MenuPageContent", `${MenuPageContent.menuPageContentId}?menupagecontentid=${MenuPageContent.menuPageContentId}`, MenuPageContent);
        return res;
    }





    async deletemenupagecontent(id: number) {
        let res = await this.service.Data.ExecuteAPI_Delete("MenuPageContent", `${id}?menupagecontentid=${id}`);
        return res;
    }
}
