import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeModel } from '../../model/type';
import { TypeService } from '../../services/type.services';
import { AlertifyService } from '../../services/alertify.service';
import { Router } from '@angular/router';
import { CategoryService } from '../../services/category.service';
import { SubCategoryService } from '../../services/subcategory.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { SystemService } from '../../services/system.service';
import { DatePipe } from '@angular/common';



@Component({
    selector: 'app-type-add',
    templateUrl: './type-add.component.html',
    styleUrls: ['./type-add.component.scss'],
    providers: [DatePipe],
})
export class TypeAddComponent implements OnInit {
    typeForm: FormGroup;
    typelist: any[] = [];
    selectedtype: TypeModel | null = null;
    categories: any;
    subcategories: any;
    category: any;
    subcategoty: any;

    constructor(private fb: FormBuilder, private typeservices: TypeService,
        private categoryService: CategoryService,
        private SubcategoryService: SubCategoryService,
        private alertify: AlertifyService,
        private router: Router,
        public service: SystemService) { }

    ngOnInit() {
        this.initializeForm();
        this.loadCategories();
        this.loadSubcategory();
        this.loadtypes();
    }

    initializeForm() {
        this.typeForm = this.fb.group({
            TypeId: [0],
            TypeTitle: ['', Validators.required],
            TypeDescription: ['', Validators.required],
            CategoryId: [null, Validators.required],
            SubcategoryId: [null, Validators.required],
            Location: ['', Validators.required],
            Phone: ['', Validators.required],
            Email: ['', [Validators.required, Validators.email]],
            Address: ['', Validators.required],
            Street: [''],
            City: [''],
            State: [''],
            Country: [''],
            Tags: [''],
            MetaKeys: [''],
            StartedYear: [null],
            isVerified: [false],
            Createdby: [''],
            CreatedDate: [''],
            Updatedby: [''],
            UpdatedDate: [''],
            Description: [''],
            Extra1: [''],
            Extra2: [''],
            Extra3: [''],
            Extra4: [''],
            Extra5: [''],
            Extra6: [''],
            Extra7: [''],
            Extra8: [''],
            Extra9: [''],
            Extra10: [''],
            isActive: [true],
            Status: [''],
            TypeofWork: [''],
            TypeofMode: [''],
            CompanySize: [''],
            Salary: [''],
            Currency: [''],
            isHome: [''],
            HeadFeatures: [''],
            MapLink: [''],
            Rating: [''],
            OpenClose: [''],
            Website: [''],
            Map: [''],
            ListingFeatures: [''],
            Price: [''],

        });
    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }


    loadCategories() {
        this.categoryService.getAllCategories().then(categories => {
            this.categories = categories;

        });
    }

    loadSubcategory() {
        this.SubcategoryService.getAllSubCategories().then(subcategories => {
            this.subcategories = subcategories;

        });

    }

    loadtypes() {
        this.typeservices.getAllTypelist().then(typelist => {
            this.typelist = typelist;
            console.log(JSON.stringify(this.typelist));
        });
    }

    async onSubmit() {
        const type: TypeModel = this.typeForm.value;

        if (type.TypeId) {
            let obj = this.typeForm.getRawValue();
            const type: TypeModel = this.typeForm.value;
            console.log(obj);
            obj.Createdby = this.service.Account.UserID;
            obj.CategoryId = obj.CategoryId.map(String).join(',');
            obj.SubcategoryId = obj.SubcategoryId.map(String).join(',');
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
            obj.UpdatedDate = formattedDate;
            obj.isActive = true;
            obj.Status = true;
            let res = this.typeservices.updateType(obj);
            if (res) {
                this.loadtypes(); // Refresh the type list
                this.router.navigate(['/typelist']);
                this.alertify.success("Data Updated Successfully");

            }

        } else {
            let obj = this.typeForm.getRawValue();
            const type: TypeModel = this.typeForm.value;
            console.log(obj);
            obj.Createdby = this.service.Account.UserID;
            obj.CategoryId = obj.CategoryId.map(String).join(',');
            obj.SubcategoryId = obj.SubcategoryId.map(String).join(',');
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
            obj.CreatedDate = formattedDate;
            obj.UpdatedDate = formattedDate;
            obj.isActive = true;
            obj.Status = true;
            let res = this.typeservices.addType(obj);
            if (res) {
                console.log(JSON.stringify(res));
                this.router.navigate(['/typelist']);
                this.alertify.success("Data Saved Successfully");
                this.loadtypes(); // Refresh the type list
            }
        }

        // Reset the form
        this.typeForm.reset();
        this.selectedtype = null;
    }

    onEdit(type: TypeModel) {
        // Load the selected type into the form for editing
        console.log(JSON.stringify(JSON.stringify(type)));
        this.selectedtype = type;
        //**************************************PatchValue */
        type = this.convertKeysToCamelCase(type);
        this.typeForm.patchValue(type);
        const Categorybind = type.CategoryId.split(',').map(Number);
        const SubCategorybind = type.SubcategoryId.split(',').map(Number);
        console.log(Categorybind);
        console.log(SubCategorybind);
        this.category = Categorybind;
        this.subcategoty = SubCategorybind;
        console.log(JSON.stringify(this.typeForm.value));
    }

    onDelete(typeId: number) {
        let res = this.typeservices.deleteType(typeId);
        if (res) {
            this.loadtypes(); // Refresh the type list
        }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }
}


