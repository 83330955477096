<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header headerbar" style="border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Profile</h4>
                        </header>
                        <article class="card-body">
                            <form [formGroup]="ProfileForm" (ngSubmit)="onSubmit()">
                                <div class="card-body">
                                    <div class="col-12">
                                        <div class="row" style="display: flex;justify-content: center;">
                                            <div
                                                class="col-12 col-sm-4 col-md-3 mb-3 text-center border pt-2 pb-2 dv-profile-left">
                                                <div class="m-card-profile__pic-wrapper">
                                                    <img *ngIf="imageUrl" class="img-fluid" [src]="imageUrl"
                                                        style="width:160px;" />
                                                </div>
                                                <!-- <h4>{{displayName.value}}</h4> -->
                                                <!-- <p class="font-13"><a href="mailto:{{email.value}}">{{email.value}}</a>
                                            </p> -->

                                                <button type="button" class="btn btn-info btn-sm mt-2"
                                                    (click)="flProfilepic.click();">ChooseProfilePicture</button>
                                                <input type="hidden" formControlName="ProfilePhoto" />
                                                <input #flProfilepic type="file" class="hide"
                                                    (change)="fileChange($event)" accept="image/*">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="Email">Email:</label>
                                    <input type="text" class="form-control" formControlName="Email">
                                    <div *ngIf="ProfileForm.get('Email')?.hasError('required') && ProfileForm.get('Email')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="PhoneNo">PhoneNo:</label>
                                    <input type="text" class="form-control" formControlName="PhoneNo">
                                    <div *ngIf="ProfileForm.get('PhoneNo')?.hasError('required') && ProfileForm.get('PhoneNo')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="City">Gender:</label>
                                    <input type="text" class="form-control" formControlName="Gender">
                                    <div *ngIf="ProfileForm.get('Gender')?.hasError('required') && ProfileForm.get('Gender')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="City">DOB:</label>
                                    <!-- <input type="text" class="form-control" formControlName="DOB"> -->
                                    <input #focus type="date" class="form-control" formControlName="DOB"
                                    min="2000-01-01" max="3000-01-01" />
                                    <div *ngIf="ProfileForm.get('DOB')?.hasError('required') && ProfileForm.get('DOB')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="City">Aboutyou:</label>
                                    <input type="text" class="form-control" formControlName="Aboutyou">
                                    <div *ngIf="ProfileForm.get('Aboutyou')?.hasError('required') && ProfileForm.get('Aboutyou')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Address">Address:</label>
                                    <input type="text" class="form-control" formControlName="Address">
                                    <div *ngIf="ProfileForm.get('Address')?.hasError('required') && ProfileForm.get('Address')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Street">Street:</label>
                                    <input type="text" class="form-control" formControlName="Street">
                                    <div *ngIf="ProfileForm.get('Street')?.hasError('required') && ProfileForm.get('Street')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="City">City:</label>
                                    <input type="text" class="form-control" formControlName="City">
                                    <div *ngIf="ProfileForm.get('City')?.hasError('required') && ProfileForm.get('City')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="State">State:</label>
                                    <input type="text" class="form-control" formControlName="State">
                                    <div *ngIf="ProfileForm.get('State')?.hasError('required') && ProfileForm.get('State')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Country">Country:</label>
                                    <input type="text" class="form-control" formControlName="Country">
                                    <div *ngIf="ProfileForm.get('Country')?.hasError('required') && ProfileForm.get('Country')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Nationality">Nationality:</label>
                                    <input type="text" class="form-control" formControlName="Nationality">
                                    <div *ngIf="ProfileForm.get('Nationality')?.hasError('required') && ProfileForm.get('Nationality')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block" [disabled]="!ProfileForm.valid">{{ type }}</button>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
