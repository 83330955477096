import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Banner } from '../../model/banner';
import { Documenttype } from '../documenttype/documenttype';
import { BannerService } from '../../services/banner.service';

@Component({
    selector: 'app-banner-list',
    templateUrl: './banner-list.component.html',
    styleUrls: ['./banner-list.component.scss'],
    providers: [DatePipe],
})
export class BannerListComponent implements OnInit {
    bannerform: FormGroup;
    banner: Banner; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    bannerItems: any;
    isLoading = false; apiPath: any;
    @Input() documenttype: Documenttype[] = [];
    @Output() editDocumenttype = new EventEmitter<Documenttype>();
    @Output() deleteDocumenttype = new EventEmitter<number>();

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private bannerService: BannerService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService) { }

    ngOnInit() {
        this.loadbanner();
        this.apiPath = this.service.Settings.Base_API_URL;
        //this.refreshGridData();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'bannerId', sortable: true, filter: true, width: 20 },
        { headerName: 'Type', field: 'typeTitle', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'Name', field: 'documentName', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Photo', field: 'bannerPhoto', cellRenderer: this.imageCellRenderer.bind(this), editable: false, width: 120, cellClass: 'zoomable-photo-cell' },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }
    refreshGridData(): void {
        this.loadbanner();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }
    imageCellRenderer(params: any) {
        return `<img src="${this.apiPath + "/Documents/appImages/" + params.value}" alt="Image" style="max-width: 50px; max-height: 50px;">`;
    }
    actionsCellRenderer(params: any) {
        // const addButton = document.createElement('button');
        // addButton.innerHTML = 'Add';
        // addButton.classList.add('Actions-btn'); // Apply the CSS class
        // addButton.addEventListener('click', () => this.addRow());

        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        //wrapper.appendChild(addButton);
        wrapper.appendChild(deleteButton);

        return wrapper;
    }

    addRow() {
        this.router.navigate(['/addbanner']);
    }
    editRow(rowData: any) {
        // Implement your edit logic here
        console.log('Edit row:', rowData);
        let id = rowData.bannerId;
        this.router.navigate(['/editsbanner', id]);

    }
    deleteRow(rowData: any) {
        console.log(rowData);
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.bannerService.deleteBanner(rowData.bannerId);
            console.log(JSON.stringify(res));
            this.refreshGridData();
            this.loadbanner();
            this.alertify.success("Deleted Successfully");
        } else {
            console.log('Deletion canceled.');
        }
    }


    addBanner() {
        this.router.navigate(['/addbanner']);
    }


    loadbanner() {
        this.service.App.ShowLoader = true;
        this.bannerService.getAllBanners().then(bannerlist => {
            this.bannerItems = bannerlist;
            this.rowData = bannerlist;
           // this.refreshGridData();
           this.refreshGridData();
          // this.loadbanner();
            console.log(JSON.stringify(this.bannerItems));
        });
        this.service.App.ShowLoader = false;
    }
}

