// category.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MenuContent } from '../model/MenuContent';
import { SystemService } from './system.service';
import { SubMenu } from '../model/submenu';


@Injectable({
    providedIn: 'root',
})
export class MenuContentService {
    apiUrl: any;
    BearerToken: any;
    menus: MenuContent[] = [];
    // menus: SubMenu[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllMenuContent(): Promise<MenuContent[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<MenuContent[]>("MenuContent");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching categories:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }
    async getDropdown(): Promise<SubMenu[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<SubMenu[]>("MenuMaster/GetAllMenuMasterslist");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching categories:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }


    getMenuByIds(id: number): Observable<MenuContent> {
        return this.http.get<MenuContent>(`${this.apiUrl}/api/MenuMaster/${id}`);
    }

    async AddMenuContent(menucontent: MenuContent) {
        let res = await this.service.Data.ExecuteAPI_Post("MenuContent", menucontent);
        return res;
    }




    async UpdateMenuContent(menucontent: MenuContent): Promise<Observable<MenuContent>> {
        let res = await this.service.Data.ExecuteAPI_Put("MenuContent", `${menucontent.contentId}?contentid=${menucontent.contentId}`, menucontent);
        return res;
    }

    async DeleteMenuContent(id: number) {
        let res = await this.service.Data.ExecuteAPI_Delete("MenuContent", `${id}?contentid=${id}`);
        return res;
    }
}
