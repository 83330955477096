<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor); border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Update Filter Header</h4>
                        </header>
                        <article class="card-body">
                            <form [formGroup]="filterHeaderValueForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label>Select Header:</label>
                                    <ng-select formControlName="filterName" #myselect [(ngModel)]="filterNameId"
                                        (change)="onMaterialGroupChange($event)">
                                        <ng-option *ngFor="let obj of FilterHeader" [value]="obj.filterId"
                                            class="form-control">{{obj.filterHeaderName1}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="filterHeaderValueForm.get('filterName')?.hasError('required') && filterHeaderValueForm.get('filterName')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="filterValue">Filter Value Description:</label>
                                    <input type="text" id="filterValue" class="form-control"
                                        formControlName="filterValue">
                                </div>
                                <div *ngIf="filterHeaderValueForm.get('filterValue')?.hasError('required') && filterHeaderValueForm.get('filterValue')?.touched"
                                    class="error-block">
                                    This field is required.
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block"
                                        [disabled]="!filterHeaderValueForm.valid"> Update </button>
                                </div>
                            </form>
                            <button class="btn btn-secondary btn-block"
                                [routerLink]="['/filterHeaderlistValue']">Back</button>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>





<app-footer-style-two></app-footer-style-two>