

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Menu } from './menu';


@Component({
  selector: 'app-menu-grid',
  templateUrl: './menu-grid.html',
  styleUrls: ['./menu-grid.css'],
})
export class MenuGridComponent implements OnInit {
  @Input() menu: Menu[] = [];
  @Output() editMenu = new EventEmitter<Menu>();
  @Output() deleteMenu = new EventEmitter<number>();

  ngOnInit() {console.log(this.menu);}

  onEdit(menu: Menu) {
    console.log(menu);
    this.editMenu.emit(menu);
  }

  onDelete(menuId: number) {
    this.deleteMenu.emit(menuId);
  }
}
