
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { Response, ResponseFull } from '../model/response';






@Injectable({
    providedIn: 'root'
})
export class responseService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }




    //Get All ResponseModels
    async getAllResponse(): Promise<ResponseFull[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<ResponseFull[]>("Response/fewGetAllResponses");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Response:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

}
