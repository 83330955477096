<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create Questionnarire type</h4>
                        </header>
                        <article class="card-body">
                            <p *ngIf="QuestionnarireName" class="card-header mb-5 " style="color:white;font-weight: bold;background: var(--mainColor);border-radius:100px;">Questionnarire Type:  {{ QuestionnarireName }}</p>
                            <form [formGroup]="QuestiontypeForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label tooltip="Select Select Type here">Select Type:</label>
                                    <ng-select  formControlName="TypeId" #myselect [(ngModel)]="TypeId"
                                        class="form-control">
                                        <ng-option *ngFor="let obj of typelist" [value]="obj.typeId"
                                            class="form-control">{{obj.typeTitle}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="QuestiontypeForm.get('TypeId')?.hasError('required') && QuestiontypeForm.get('TypeId')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Selected">Selected:</label>
                                    <input type="checkbox" formControlName="Active">
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block">Save</button>
                                    <button (click)="cancel()" class="btn btn-secondary btn-block"> Cancel </button>
                                </div>
                            </form>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <!-- <header class="card-header" style="background: var(--mainColor);">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create choice</h4>
                        </header> -->
                        <article class="card-body">
                            <!-- Grid section -->
                            <table class="table table-striped table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">ApplyId</th>
                                        <th scope="col">Identifier</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">TypeTitle</th>
                                        <th scope="col">TypeDescription</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of questionnariestype">
                                        <td>{{ item.ApplyId }}</td>
                                        <td>{{ item.Identifier }}</td>
                                        <td>{{ item.Name }}</td>
                                        <td>{{ item.Description }}</td>
                                        <td>{{ item.TypeTitle }}</td>
                                        <td>{{ item.TypeDescription }}</td>
                                        <td>
                                            <button class="btn btn-warning btn-sm" (click)="onEdit(item)">Edit</button>
                                            <button class="btn btn-danger btn-sm" (click)="onDelete(item.ApplyId)">Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
