import { Component, ElementRef, Renderer2, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { Category } from '../../model/category';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';

@Component({
    selector: 'app-category-form',
    templateUrl: './category.html',
    styleUrls: ['./category.css'],
})
export class CategoryFormComponent implements OnInit {
    categoryForm: FormGroup;
    category: any;
    selectedCategory: Category | null = null;
    // @Output() CategoryDeleted = new EventEmitter<void>();


    @ViewChild('blogContainer2') blogContainer: ElementRef; // Use 'blogContainer2' instead of 'blogContainer'
    rowData: any;
    gridApi: any;


    constructor(
        private fb: FormBuilder,
        private renderer: Renderer2,
        public service: SystemService,
        private categoryService: CategoryService,
        private alertify: AlertifyService,
    ) {
        this.loadCategories();
    }

    ngOnInit() {
        console.log('blogContainer:', this.blogContainer); // Add this line to check the value
        this.categoryForm = this.fb.group({
            categoryId: 0,
            categoryName: ['', Validators.required],
            categoryDescp: ['', Validators.required],
        });
        this.loadCategories();
    }
    refreshGridData(): void {
        //this.loadCategories();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }

    async onSubmit() {
        this.scrollIntoViewAdd(this.blogContainer.nativeElement);
        const category: Category = this.categoryForm.value;

        try {
            if (this.categoryForm.valid) {
                this.service.App.ShowLoader = true;
                if (category.categoryId) {
                    category.categoryId = this.selectedCategory.categoryId;
                    await this.categoryService.updateCategory(category);
                    this.alertify.success("Data Updated Successfully");
                } else {
                    category.categoryId = 0;
                    await this.categoryService.addCategory(category);
                }
                this.alertify.success("Data Saved Successfully");
                this.loadCategories();

                this.categoryForm.reset();
                this.selectedCategory = null;

                console.log('Scrolling to view:', this.blogContainer.nativeElement);
            }
        } finally {
            this.service.App.ShowLoader = false;
        }
    }
    scrollIntoViewAdd(element: any): void {
        const gridElement = document.querySelector('.blog-details-area'); // Assuming the grid is under the class 'blog-details-area'

        if (gridElement) {
            const totalHeight = gridElement.scrollHeight;
            const scrollTopValue = totalHeight - element.offsetTop;
            this.renderer.setProperty(document.documentElement, 'scrollTop', scrollTopValue);
        }
    }



    onEdit(category: Category) {
        console.log(category);
        this.selectedCategory = category;
        this.categoryForm.patchValue(category);

        // Scroll to the top of the grid
        this.scrollIntoViewEdit();
    }

    scrollIntoViewEdit(): void {
        const gridElement = document.querySelector('.blog-details-area') as HTMLElement;

        if (gridElement) {
            gridElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    onDelete(categoryId: number) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            this.categoryService.deleteCategory(categoryId).then(() => {
                this.alertify.success("Deleted Successfully");
                this.loadCategories();  // Refresh the grid after deletion
            }).catch(error => {
                console.error('Error deleting submenu:', error);
                this.alertify.error("Error deleting data");
            }).finally(() => {
                this.service.App.ShowLoader = false;
            });
        } else {
            console.log('Deletion canceled.');
        }

    }

    loadCategories() {
        this.service.App.ShowLoader = true;
        this.categoryService.getAllCategories().then(categories => {
            this.category = categories;
            this.rowData = categories;
            this.refreshGridData();
            console.log(JSON.stringify(this.category));
        });
        this.service.App.ShowLoader = false;
    }


}

export { Category };
