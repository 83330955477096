import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Documenttype } from '../documenttype/documenttype';
import { CategoryService } from '../../services/category.service';
import { SubCategoryService } from '../../services/subcategory.service';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { QuestionnaireModel } from '../../model/question';

@Component({
    selector: 'app-questionnaire-edit',
    templateUrl: './questionnaire-edit.component.html',
    styleUrls: ['./questionnaire-edit.component.scss'],
    providers: [DatePipe],
})
export class QuestionnaireEditComponent implements OnInit {
    questionnaireForm: FormGroup;
    typelist: any[] = [];
    selectedtype: QuestionnaireModel | null = null;
    categories: any;
    subcategories: any;
    category: any;
    subcategoty: any;
    id: string;
    isLoading: boolean = false;
    languageOptions = [
        { code: '1', name: 'English' },
        { code: '2', name: 'Spanish' },
        { code: '3', name: 'French' },

    ];
    LanguageId: any;
    constructor(private alertify: AlertifyService, private fb: FormBuilder, private questionnaireService: QuestionnaireService, private typeservices: TypeService, private router: Router, private route: ActivatedRoute,
        private categoryService: CategoryService, private SubcategoryService: SubCategoryService, public service: SystemService) {
        this.id = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.initializeForm();
        this.loadCategories();
        this.loadSubcategory();
        this.bindQuestionnairesById();
    }

    initializeForm() {
        this.questionnaireForm = this.fb.group({
            Id: [null], // You may want to add validation as needed
            Identifier: [null, Validators.required],
            Name: [null, Validators.required],
            Description: [null],
            CategoryNameHyphenated: [null, Validators.required],
            CategoryId: [null, Validators.required],
            SubCategoryId: [null, Validators.required],
            LanguageId: [null, Validators.required],
        });
    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }

    loadCategories() {
        try {
            this.categoryService.getAllCategories().then(categories => {
                this.categories = categories;
            });
        } catch (error) {
            console.error("Error loading categories:", error);
            // Handle the error, e.g., show a user-friendly message
        }
    }

    loadSubcategory() {
        try {
            this.SubcategoryService.getAllSubCategories().then(subcategories => {
                this.subcategories = subcategories;

            });
        } catch (error) {
            console.error("Error loading subcategories:", error);
            // Handle the error, e.g., show a user-friendly message
        }
    }

    bindQuestionnairesById() {
        try {
            console.log(this.id);
            this.isLoading = true;
            this.questionnaireService.getQuestionnairesById(Number(this.id)).then(data => {
                console.log(data,'DATA')
                data = this.convertKeysToCamelCase(data);
                console.log(data);
                this.questionnaireForm.patchValue({ SubCategoryId: data.SubCategoryId });
                this.questionnaireForm.patchValue(data);
                const Categorybind = data.CategoryId;
                const SubCategorybind = data.SubCategoryId.split(',').map(Number);
                const LanguageId = data.LanguageId;
                console.log(Categorybind);
                console.log(SubCategorybind);
                this.category = Categorybind;
                this.subcategoty = SubCategorybind;
                this.LanguageId = LanguageId;
                console.log(JSON.stringify(data));
                this.isLoading = false;
                // this.alertify.success("Deleted Successfully");
            });
        } catch (error) {
            // Handle errors here
            console.error("Error submitting questionnaire:", error);
            this.alertify.error("An error occurred while saving data.");
        }
    }



    async onSubmit() {
        try {
            const questionnaire: QuestionnaireModel = this.questionnaireForm.value;

            if (questionnaire.Id) {
                let obj = this.questionnaireForm.getRawValue();
                console.log(obj);
                obj.Createdby = localStorage.getItem('id');
                obj.CategoryId = obj.CategoryId;
                obj.SubCategoryId = obj.SubCategoryId.map(String).join(',');
                obj.Active = true;
                this.router.navigate(['/questionnairelist']);
                this.alertify.success("Data Updated Successfully");
                // Assuming updateQuestionnaires returns a promise
                let res = await this.questionnaireService.updateQuestionnaires(obj);

                if (res) {
                    console.log(res);
                    this.router.navigate(['/questionnairelist']);
                    this.alertify.success("Data Updated Successfully");
                    // this.router.navigate(['/questionnairelist']);
                }
            }
            // Reset the form
            this.questionnaireForm.reset();
            this.selectedtype = null;
        } catch (error) {
            console.error("Error updating questionnaire:", error);
            this.alertify.error("An error occurred while updating the data.");
        }
    }


    onEdit(questionnaire: QuestionnaireModel) {
        try {
            // Load the selected type into the form for editing
            console.log(JSON.stringify(JSON.stringify(questionnaire)));
            this.selectedtype = questionnaire;

            //**************************************PatchValue **************************************//
            questionnaire = this.convertKeysToCamelCase(questionnaire);
            this.questionnaireForm.patchValue(questionnaire);
            const Categorybind = questionnaire.CategoryId;
            const SubCategorybind = questionnaire.SubCategoryId.split(',').map(Number);
            console.log(Categorybind);
            console.log(SubCategorybind);
            this.category = Categorybind;
            this.subcategoty = SubCategorybind;
            console.log(JSON.stringify(this.questionnaireForm.value));
        } catch (error) {
            console.error("Error in onEdit:", error);
            // Handle the error, e.g., show a user-friendly message
        }
    }




    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        //console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        //console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        //console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        //console.log('blur', $event)
        this.focused = false
        this.blured = true
    }
    cancel() {
        this.questionnaireForm.reset();
        this.router.navigate(['/questionnairelist']);
    }
}


