<app-navbar-style-admin></app-navbar-style-admin>
<div class="inner-banner inner-bg4">
    <div class="container">

    </div>
</div>
<div class="blog-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">

                    <div class="card loginCard">
                        <header class="card-header headerbar" style="border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create Your Menu Page Content
                            </h4>
                        </header>
                        <div *ngIf="isLoading" class="overlay-inner">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                                </div>
                            </div>
                        </div>
                        <article class="card-body">
                            <form [formGroup]="MenuPageContentform" (ngSubmit)="MenuPageContentform.valid && onSubmit()"
                                #f="ngForm" novalidate>
                                <div class="form-box row">


                                    <div class="form-group">
                                        <label>Select Menus:</label>
                                        <ng-select formControlName="menuId" #myselect
                                            (change)="onMaterialGroupChange($event)" [(ngModel)]="selectedMenuId"
                                            required>
                                            <ng-option *ngFor="let obj of Dropdown" [value]="obj.menuId"
                                                class="form-control">{{obj.menuName}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="MenuPageContentform.get('menuId')?.hasError('required') && MenuPageContentform.get('menuId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>



                                    <div class="form-group">
                                        <label for="contentIcon">Content Icon:</label>
                                        <input type="text" id="contentIcon" class="form-control"
                                            formControlName="contentIcon" required>
                                        <div *ngIf="MenuPageContentform.get('contentIcon')?.hasError('required') && MenuPageContentform.get('contentIcon')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <label for="contentType">Content Type:</label>
                                        <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)"
                                            formControlName="contentType" (onEditorChanged)="changedEditor($event)"
                                            (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                                        <div *ngIf="MenuPageContentform.get('contentType')?.hasError('required') && MenuPageContentform.get('contentType')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <label for="contentFileName">Content File Name:</label>
                                        <input type="text" id="contentFileName" class="form-control"
                                            formControlName="contentFileName" required>
                                        <div *ngIf="MenuPageContentform.get('contentFileName')?.hasError('required') && MenuPageContentform.get('contentFileName')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>



                                    <div class="form-group">
                                        <label>Upload Image:</label>
                                        <input type="file" (change)="onFileSelect($event)"
                                            class="btn btn-info btn-sm mt-2" formControlName="extra1">
                                        <img *ngIf="imageUrl" class="imageUpload" [src]="imageUrl" />
                                        <div *ngIf="MenuPageContentform.get('extra1')?.hasError('extra1') && MenuPageContentform.get('extra1')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button [disabled]="MenuPageContentform.invalid" type="submit"
                                            class="btn btn-success btn-block"> Save </button>
                                    </div>
                                    <div class="form-group">
                                        <button (click)="cancel()" class="btn btn-secondary btn-block"> Cancel </button>
                                    </div>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>