<table class="table table-striped table-bordered">
  <thead class="thead-dark">
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Menu Name</th>
      <th scope="col">SubMenu Name</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let menu of submenu">
      <tr *ngFor="let submenu of menu.subMenus">
        <td>{{ submenu.menuId }}</td>
        <td>{{ menu.menuName }}</td>
        <td>{{ submenu.menuName }}</td>
        <td>
          <button class="btn btn-warning btn-sm" (click)="onEdit(submenu)">Edit</button>
          <button class="btn btn-danger btn-sm" (click)="onDelete(submenu.menuId)">Delete</button>
        </td>
      </tr>
      <ng-container *ngFor="let menu of menu.subMenus">
        <tr *ngFor="let submenu of menu.subMenus">
          <td>{{ submenu.menuId }}</td>
          <td>{{ menu.menuName }}</td>
          <td>{{ submenu.menuName }}</td>
          <td>
            <button class="btn btn-warning btn-sm" (click)="onEdit(submenu)">Edit</button>
            <button class="btn btn-danger btn-sm" (click)="onDelete(submenu.menuId)">Delete</button>
          </td>
        </tr>
        <!-- <ng-container *ngFor="let menu of menu.subMenus">
          <tr *ngFor="let submenu of menu.subMenus">
            <td>{{ submenu.menuId }}</td>
            <td>{{ menu.menuName }}</td>
            <td>{{ submenu.menuName }}</td>
            <td>
              <button class="btn btn-warning btn-sm" (click)="onEdit(submenu)">Edit</button>
              <button class="btn btn-danger btn-sm" (click)="onDelete(submenu.menuId)">Delete</button>
            </td>
          </tr>
        </ng-container> -->
      </ng-container>
    </ng-container>
  </tbody>
</table>