import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Documenttype } from '../documenttype/documenttype';
import { TypeModel } from '../../model/type';

@Component({
    selector: 'app-type-list',
    templateUrl: './type-list.component.html',
    styleUrls: ['./type-list.component.scss'],
    providers: [DatePipe],
})
export class TypeListComponent implements OnInit {
    typeform: FormGroup;
    type: TypeModel; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    typeItems: any;
    isLoading = false;
    @Input() documenttype: Documenttype[] = [];
    @Output() editDocumenttype = new EventEmitter<Documenttype>();
    @Output() deleteDocumenttype = new EventEmitter<number>();

    constructor(private fb: FormBuilder,
        private router: Router,
        private typeService: TypeService,
        private alertify: AlertifyService,
        public service: SystemService) { }

    ngOnInit() {
        this.loadtype();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'typeId', sortable: true, filter: true, width: 20 },
        { headerName: 'Type', field: 'typeTitle', sortable: true, filter: true, editable: true, width: 150 },
        // { headerName: 'Description', field: 'typeDescription', sortable: true, filter: true, editable: true, width: 200 },
        // { headerName: 'Category', field: 'categoryId', sortable: true, filter: true, editable: true, width: 100 },
        // { headerName: 'Subcategory', field: 'subcategoryId', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Location', field: 'location', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Phone', field: 'phone', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Email', field: 'email', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'Address', field: 'address', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Street', field: 'street', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'City', field: 'city', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'State', field: 'state', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Country', field: 'country', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Tags', field: 'tags', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'MetaKeys', field: 'metaKeys', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Started Year', field: 'startedYear', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Verified', field: 'isVerified', sortable: true, filter: true, editable: true, width: 100 },
        // Add other fields as needed
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },
    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;
  

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    imageCellRenderer(params: any) {
        return `<img src="${params.value}" alt="Image" style="max-width: 50px; max-height: 50px;">`;
    }
    actionsCellRenderer(params: any) {
        const addButton = document.createElement('button');
        addButton.innerHTML = 'Add';
        addButton.classList.add('Actions-btn'); // Apply the CSS class
        addButton.addEventListener('click', () => this.addRow());

        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        wrapper.appendChild(addButton);
        wrapper.appendChild(deleteButton);

        return wrapper;
    }
    refreshGridData(): void {
        this.loadtype();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }
    addRow() {
        this.router.navigate(['/typeadd']);
    }
    editRow(rowData: any) {
        // Implement your edit logic here
        console.log('Edit row:', rowData);
        let id = rowData.typeId;
        this.router.navigate(['/typeedit', id]);

    }
    // deleteRow(rowData: any) {
    //     console.log(JSON.stringify(rowData));
    //     let res = this.typeService.deleteType(rowData.typeId);
    //     console.log(JSON.stringify(res));

    // }
    // deleteRow(rowData: any) {
    //     const userConfirmed = confirm('Are you sure you want to delete?');
    //     if (userConfirmed) {
            
    //         let res = this.filterHeaderservices.deleteFilterHeader(rowData.filterId);
    //         console.log(JSON.stringify(res));
    //         this.refreshGridData();
    //         this.loadfilterHeader();
    //         this.alertify.success("Deleted Successfully");
    //     } else {
    //         console.log('Deletion canceled.');
    //     }

    // }

    deleteRow(rowData: any) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.typeService.deleteType(rowData.typeId);
            console.log(JSON.stringify(res));
            this.refreshGridData();
            this.loadtype();
            //this.loadMenu();


            this.alertify.success("Deleted Successfully");

        } else {
            console.log('Deletion canceled.');
        }
        // this.scrollIntoViewDelete();
    }

    loadtype() {
        this.service.App.ShowLoader = true;
        this.typeService.getAllTypelist().then(typelist => {
            this.typeItems = typelist;
            this.rowData = typelist;
            console.log(JSON.stringify(this.typeItems));
        });
        this.service.App.ShowLoader = false;

    }




}

