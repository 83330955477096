
<app-navbar-style-admin></app-navbar-style-admin>

<div class="blog-details-area pt-50 pb-0" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
              <h4 class="card-title mt-2 text-center" style="color:white">Create SubMenu</h4>
            </header>
            <article class="card-body">
              <form [formGroup]="submenuForm" (ngSubmit)="onSubmit()">

                <div class="form-group">
                  <label>Select Main Menu:</label>
                  <ng-select formControlName="menuParentId" #myselect (change)="onMaterialGroupChange($event)"
                    [(ngModel)]="selectedMenuId" required>
                    <ng-option *ngFor="let obj of Dropdown" [value]="obj.menuId"
                      class="form-control">{{obj.menuName}}</ng-option>
                  </ng-select>
                  <div
                    *ngIf="submenuForm.get('menuParentId')?.hasError('required') && submenuForm.get('menuParentId')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>


                <div class="form-group">
                  <label for="menuName">Sub Menu Name:</label>
                  <input type="text" id="menuName" class="form-control" formControlName="menuName" required>
                  <div *ngIf="submenuForm.get('menuName')?.hasError('required') && submenuForm.get('menuName')?.touched"
                    class="error-block">
                    This field is required.
                  </div>

                </div>





                <div class="form-group">
                  <label for="menuUrl">Menu Url:</label>
                  <input type="text" id="menuUrl" class="form-control" formControlName="menuUrl" required>
                  <div
                    *ngIf="submenuForm.get('menuUrl')?.hasError('required') && submenuForm.get('menuUrl')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>

                <div class="form-group">
                  <label for="menuIcon">Menu Icon:</label>
                  <input type="text" id="menuIcon" class="form-control" formControlName="menuIcon" required>
                  <div
                    *ngIf="submenuForm.get('menuIcon')?.hasError('required') && submenuForm.get('menuIcon')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>

                <div class="form-group">
                  <label for="menuContent">Menu Content:</label>
                  <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)"
                      formControlName="menuContent" (onEditorChanged)="changedEditor($event)"
                      (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                  <div *ngIf="submenuForm.get('menuContent')?.hasError('required') && submenuForm.get('menuContent')?.touched"
                      class="error-block">
                      This field is required.
                  </div>
              </div>

  
              <div class="form-group">
                <label>Upload Image:</label>
                <input type="file" (change)="onFileSelect($event)"
                    class="btn btn-info btn-sm mt-2" formControlName="extra1">
                <img *ngIf="imageUrl" class="imageUpload" [src]="imageUrl" />
                <div *ngIf="submenuForm.get('extra1')?.hasError('extra1') && submenuForm.get('extra1')?.touched"
                    class="error-block">
                    This field is required.
                </div>
            </div>


                <div class="form-group">
                  <button type="submit" class="btn btn-success btn-block" [disabled]="!submenuForm.valid">Save</button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="blog-details-area pt-50 pb-70" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <article class="card-body">
              <!-- Grid section -->
              <app-sub-menu-grid [submenu]="submenu" (editSubMenu)="onEdit($event)"
                (deleteSubMenu)="onDelete($event)"></app-sub-menu-grid>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-style-two></app-footer-style-two>