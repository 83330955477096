import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Gallery } from '../../model/gallery';
import { GalleryService } from '../../services/gallery.service';

@Component({
    selector: 'app-gallery-add',
    templateUrl: './gallery-add.component.html',
    styleUrls: ['./gallery-add.component.scss'],
    providers: [DatePipe],
})
export class GalleryAddComponent implements OnInit {
    galleryform: FormGroup;
    gallery: Gallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    isLoading: boolean = false;

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private galleryService: GalleryService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService) { }



    ngOnInit() {
        this.Creategalleryform();
        this.loadDocumentTypeService();
        this.loadTypeService();
    }

    loadDocumentTypeService() {
        this.documentTypeService.getAlldocument().then(documentTypelist => {
            this.documentTypelist = documentTypelist;

            console.log(JSON.stringify(this.documentTypelist));
        });
    }

    loadTypeService() {
        this.typeService.getAllTypelist().then(typelist => {
            this.typelist = typelist;
            console.log(JSON.stringify(this.typelist));
        });

    }


    onSubmit() {
        this.userSubmitted = true;
        this.isLoading = true;
        let obj = this.galleryform.getRawValue();
        obj.GalleryPhoto = this.imageUrl ? this.imageUrl : obj.GalleryPhoto;
        console.log(JSON.stringify(obj));
        const gallery: Gallery = this.galleryform.value;
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
        obj.Extra1 = this.fileName;
        if (gallery.GalleryId) {
            obj.UpdatedDate =formattedDate;
            obj.Updatedby = this.service.Account.UserID;
            obj.UpdatedDate = formattedDate;
            obj.isActive = true;
            obj.status = true;
            console.log("Update");
            let res = this.galleryService.updateGallery(obj);
            if (res) {
                this.loadDocumentTypeService(); // Refresh the category list
                this.loadTypeService();// Refresh the category list
            }
        }
        else {
            obj.CreatedDate =formattedDate;
            obj.Createdby = this.service.Account.UserID;
            obj.isActive = true;
            obj.status = true;
            console.log("Add");
            console.log(JSON.stringify(obj));
            let res = this.galleryService.addGallery(obj);
            if (res) {
                this.isLoading =false;
                console.log(JSON.stringify(res));
                this.loadDocumentTypeService(); // Refresh the category list
                this.loadTypeService();
                this.galleryform.reset();
                this.router.navigate(['/gallerylist']);
                //this.router.navigate(['/filterHeaderlist']);
                this.alertify.success("Data Saved Successfully");
            }
        }
        this.userSubmitted = false;

    }
    // onSubmit() {
    //     this.userSubmitted = true;
    //     this.isLoading = true;
    //     let obj = this.imagegalleryform.getRawValue();
    //     obj.galleryPhoto = this.imageUrl ? this.imageUrl : obj.galleryPhoto;
    //     console.log(JSON.stringify(obj));
    //     const gallery: ImageGallery = this.imagegalleryform.value;
    //     const currentDate = new Date();
    //     const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
    //     // obj.createdDate=this.date.toISOString();
    //     obj.Extra1 = this.fileName;
    //     if (gallery.imageGalleryId) {
    //         obj.updatedDate =formattedDate;
    //         obj.updatedby = this.service.Account.UserID;
    //         obj.isActive = true;
    //         obj.status = true;
    //         console.log("Update");
    //         let res = this.imageGalleryService.updateGallery(obj);
    //         if (res) {
    //             this.loadDocumentTypeService(); // Refresh the category list
    //             this.loadTypeService();// Refresh the category list
    //             this.alertify.success("Data Updated Successfully");
    //         }
    //     }
    //     else {
    //         obj.updatedDate =formattedDate;
    //         obj.createdDate =formattedDate;
    //         obj.createdby = this.service.Account.UserID;
    //         obj.updatedby = this.service.Account.UserID;
    //         obj.isActive = true;
    //         obj.status = true;
    //         console.log("Add");
    //         console.log(JSON.stringify(obj));
    //         let res = this.imageGalleryService.addGallery(obj);
    //         if (res) {
    //             this.isLoading =false;
    //             console.log(JSON.stringify(res));
    //             this.loadDocumentTypeService(); // Refresh the category list
    //             this.imagegalleryform.reset();
    //             this.alertify.success("Data Saved Successfully");
    //             this.router.navigate(['/ImageGalleryList']);
    //         }
    //     }
    //     this.userSubmitted = false;

    // }
    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Creategalleryform() {
        this.galleryform = this.fb.group({
            GalleryId: [null],
            TypeId: [null, Validators.required],
            DocumentTypeId: [null, Validators.required],
            GalleryPhoto: [null, Validators.required],
            GalleryBuget: [null],
            Createdby: [null],
            CreatedDate: [null],
            Updatedby: [null],
            UpdatedDate: [null],
            Description: [null],
            Extra1: [null],
            Extra2: [null],
            Extra3: [null],
            Extra4: [null],
            Extra5: [null],
            Extra6: [null],
            Extra7: [null],
            Extra8: [null],
            Extra9: [null],
            Extra10: [null],
            isActive: [true],
            Status: [null],
        });
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    cancel() {
        this.galleryform.reset();
        this.router.navigate(['/gallerylist']);
    }
}

