import { Component, ElementRef, Renderer2, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { CategoryService } from '../../services/category.service';
import { MenuService } from '../../services/menu.service';
import { Menu } from '../../model/menu';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';

@Component({
    selector: 'app-menu-form',
    templateUrl: './menu.html',
    styleUrls: ['./menu.css'],
})
export class MenuFormComponent implements OnInit {
    menuForm: FormGroup;
    menu: any;
    selectedMenu: Menu | null = null;
    // @Output() CategoryDeleted = new EventEmitter<void>();
    apiPath: any;
    imageUrl: string;
    gallery: Menu; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    @ViewChild('blogContainer2') blogContainer: ElementRef; // Use 'blogContainer2' instead of 'blogContainer'
    rowData: any;
    gridApi: any;
    fileName: any;

    constructor(
        private fb: FormBuilder,
        private renderer: Renderer2,
        public service: SystemService,
        private menuService: MenuService,
        private alertify: AlertifyService) {
        this.loadMenu();
        this.apiPath = this.service.Settings.Base_API_URL;
    }

    ngOnInit() {
        console.log('blogContainer:', this.blogContainer); // Add this line to check the value
        this.loadMenu();
        this.menuForm = this.fb.group({
            menuId: 0,
            menuName: ['', Validators.required],
            menuParentId: [null],
            menuUrl: '',
            menuIcon: '',
            menuContent: '',
            extra1: '',
            extra2: '',
            extra3: '',
            extra4: '',
            extra5: '',
            active: true
        });
    }

    refreshGridData(): void {
        //this.loadMenu();
        this.gridApi.setRowData(this.rowData);
    }
    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    async onSubmit() {
        this.scrollIntoViewAdd(this.blogContainer.nativeElement);
        let obj = this.menuForm.getRawValue();
        obj.extra1 = this.imageUrl ? this.imageUrl : obj.extra1;
        console.log(JSON.stringify(obj));
        const menu: Menu = this.menuForm.value;
        try {
            if (this.menuForm.valid) {
                this.service.App.ShowLoader = true;
                if (obj.menuId) {
                    obj.menuId = this.selectedMenu.menuId;
                    obj.menuParentId = null;
                    await this.menuService.updateMenu(obj);
                    this.loadMenu();
                    this.alertify.success("Data Updated Successfully");
                } else {
                    obj.menuId = 0;
                    obj.menuParentId = null;
                    let res = await this.menuService.addMenu(obj);
                    if (res) {
                        this.loadMenu();
                        this.alertify.success("Data Saved Successfully");
                    }
                }
                this.menuForm.reset();
                this.selectedMenu = null;
                this.imageUrl = null;
                console.log('Scrolling to view:', this.blogContainer.nativeElement);
            }
        } finally {
            this.service.App.ShowLoader = false;
        }
    }

    scrollIntoViewAdd(element: any): void {
        const gridElement = document.querySelector('.blog-details-area'); // Assuming the grid is under the class 'blog-details-area'

        if (gridElement) {
            const totalHeight = gridElement.scrollHeight;
            const scrollTopValue = totalHeight - element.offsetTop;
            this.renderer.setProperty(document.documentElement, 'scrollTop', scrollTopValue);
        }
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }

    onEdit(menu: Menu) {
        console.log(menu);
        this.selectedMenu = menu;
        this.imageUrl = this.apiPath + "/Documents/appImages/" + menu.extra1;
        menu.extra1 = this.imageUrl ? this.imageUrl : menu.extra1;
        this.menuForm.patchValue(menu);
        // Scroll to the top of the grid
        this.scrollIntoViewEdit();
    }


    scrollIntoViewEdit(): void {
        const gridElement = document.querySelector('.blog-details-area') as HTMLElement;

        if (gridElement) {
            gridElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    onDelete(menuId: number) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            this.menuService.deleteMenu(menuId).then(() => {
                this.alertify.success("Deleted Successfully");
                this.loadMenu();  // Refresh the grid after deletion
            }).catch(error => {
                console.error('Error deleting submenu:', error);
                this.alertify.error("Error deleting data");
            }).finally(() => {
                this.service.App.ShowLoader = false;
            });
        } else {
            console.log('Deletion canceled.');
        }

    }

    loadMenu() {
        this.service.App.ShowLoader = true;
        this.menuService.getAllMenu().then(menus => {
            this.menu = menus;
            this.rowData = menus;
            this.refreshGridData();
            this.alertify.success('Data Updated Successfully')
            console.log(JSON.stringify(this.menu));
        });
        this.service.App.ShowLoader = false;
    }


}

export { Menu };
