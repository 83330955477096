import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { timeout } from 'rxjs/operators';
import { Account_Model, Settings_Model } from '../model/common_model';



@Injectable()
export class SystemService {
    public App: AppHelper;
    public CL: string = "";
    public Data: DataHelper;
    public Account: Account_Model;
    public Settings: Settings_Model;

    public Storage_Key: typeof Storage_Key = Storage_Key;
    public StatusType: typeof StatusType = StatusType;

    public _dataPromise: Deferred<boolean> = new Deferred<boolean>();
    //public get HasAccountData(): Promise<boolean> {
    //     console.log(JSON.stringify(this._dataPromise.promise));
    //     return this._dataPromise.promise;
    //}

    public get HasAccountData(): Promise<boolean> {
        try {
            const result = this._dataPromise.promise;
            return result;
        } catch (error) {
            console.log("logOut");
            this.logOut();
            this.router.navigate(['/']);
            throw error; // Rethrow the error if needed
        }
    }



    //   private OrderAssetDetails = new Subject<any>();
    //   sendOrderAssetDetailsClickEvent(){
    //     this.OrderAssetDetails.next();
    //   }
    //   getOrderAssetDetailsClickEvent():Observable<any>{
    //      return this.OrderAssetDetails.asObservable();
    //   }



    constructor(public route: ActivatedRoute, public router: Router, public http: HttpClient) {

        this.Settings = <Settings_Model>{
            Base_API_URL: environment.Base_API_URL, Site_URL: environment.Site_URL, Expiration_Time: environment.Expiration_Time
        };
        this.Settings.API_URL = this.Settings.Base_API_URL + '/api';
        this.Account = <Account_Model>{};
        this.App = new AppHelper(this);
        this.Data = new DataHelper(http, this.App, this);
        //Translator.init().then(() => { this.CL = "en"; });
        this.loadAccountDetail();

    }

    setUserAccountData(data: any): void {
        this.Account = data;
    }

    getUserAccountData(): any {
        return this.Account;
    }


    async loadAccountDetail(): Promise<boolean> {
        if (this.App.getCookie("Bearer")) {
            try {
                const data = localStorage.getItem('authResponse');
                if (data) {
                    const authResponse = JSON.parse(data);
                    localStorage.setItem('token', this.App.getCookie("Bearer"));
                    localStorage.setItem('email', authResponse.userEmail);
                    localStorage.setItem('username', authResponse.username);
                    localStorage.setItem('id', authResponse.userId);
                    this.Account.userId = authResponse.userId;
                    this.Account.Email = authResponse.userEmail;
                    this.Account.UserName = authResponse.username;
                    let isAdmin = this.Account.Is_Admin; // Remember agent is in the agent portal or client portal, so get the old value
                    this.Account = authResponse;

                    this.setUserAccountData(authResponse);

                    console.log(JSON.stringify(this.Account));

                    if (isAdmin !== undefined && isAdmin !== null) {
                        this.Account.Is_Admin = isAdmin; // Remember agent is in the agent portal or client portal, so get the old value
                    }
                }
                if (this.Account.userId.length > 0) { this._dataPromise.resolve(true); }
                else { this._dataPromise.resolve(false); console.log("redirectToLogin"); this.redirectToLogin(); } //alert("Redirect To Login--Details");
            } catch (e) {
                console.error("Error loading account details:", e);
                console.log("redirectToLogin");
                this._dataPromise.resolve(false); this.redirectToLogin();
            }
        } else {
            this._dataPromise.resolve(false);
        }
        return this._dataPromise.promise;
    }


    redirectToLogin(returnUrl?: string) {
        //debugger;
        this.Account = <Account_Model>{ UserID: 0, UserName: "" };
        // let isLogin = localStorage.getItem("isLogin");
        // alert(isLogin);
        // alert(returnUrl);
        // if(!isLogin)
        // {
        //     alert(isLogin);
        //     this.Account = null;
        //     this.router.navigate(['/']);

        // }
        // else if(isLogin)
        // {

        // }
        // else if (returnUrl && returnUrl != '/')
        //     this.router.navigate(['login'], { queryParams: { returnUrl: returnUrl }, replaceUrl: true });
        // else
        //     this.Account = null;
        //     this.router.navigate(['login']);
        console.log(this.Account);
        console.log(returnUrl);

        // if (returnUrl && returnUrl != '/')
        //     this.router.navigate(['/'], { queryParams: { returnUrl: returnUrl }, replaceUrl: true });
        //  else
        //     this.router.navigate(['/']);
        if (returnUrl && returnUrl !== '/') {
            // Navigating to home route with returnUrl as a query parameter
            this.router.navigate(['/login-register'], { queryParams: { returnUrl }, replaceUrl: true });
        } else {
            // Navigating to the home route
            this.router.navigate(['/login-register']);
        }

    }


    ClearToken() {
        this.App.setCookie("Bearer", "", 0, "");
        this.Account = <Account_Model>{ UserID: 0, UserName: "" };
    }
    logOut() {
        //this.Data.startConnection();
        console.log("logOut");
        localStorage.clear();
        this.resetPromise();
        this.App.setCookie("Bearer", "", 0, "");
        localStorage.removeItem('isAdmin');
        this.Account = <Account_Model>{ UserID: 0, UserName: "" };
        console.log("redirectToLogin");
        this.redirectToLogin();
        console.log("redirectToLogin");
    }
    resetPromise() {
        this._dataPromise = new Deferred<boolean>();
    }



    Is_RTL() {
        return localStorage.getItem("lang") == 'AE';
    }
    Set_RTL() {
        if (this.Is_RTL()) {
            document.querySelector('html')!.classList.add('rtl');
        }
        else {
            document.querySelector('html')!.classList.remove('rtl');
        }
    }



    async Get_Languages() {
        let lst: any = [
            { Key: 'Arabic', Value: 'AE' },
            { Key: 'Chinese', Value: 'TW' },
            { Key: 'Danish', Value: 'DK' },
            { Key: 'Dutch', Value: 'NL' },
            { Key: 'English', Value: 'EN' },
            { Key: 'French', Value: 'FR' },
            { Key: 'German', Value: 'DE' },
            { Key: 'Greek', Value: 'GR' },
            { Key: 'Hungarian', Value: 'HU' },
            { Key: 'Italian', Value: 'IT' },
            { Key: 'Japanese', Value: 'JP' },
            { Key: 'Polish', Value: 'PL' },
            { Key: 'Portuguese', Value: 'PT' },
            { Key: 'Russian', Value: 'RU' },
            { Key: 'Slovak', Value: 'SK' },
            { Key: 'Spanish', Value: 'ES' },
            { Key: 'Swedish', Value: 'SE' },
            { Key: 'Turkish', Value: 'TR' }
        ];
        return lst;
    }


    //Get Default Profile Image
    Default_Profile_Pic() { return "/assets/images/profile.png"; }


}

class DataHelper {
    public objEvents: any = {};
    setStatus: boolean;


    constructor(public http: HttpClient, public App: AppHelper, public service: SystemService) {
        this.SetHttpOptions();

    }



    public BearerToken = this.App.getCookie("Bearer");
    public httpOptions = { headers: new HttpHeaders() };

    public httpOptions_empty = { headers: new HttpHeaders() };
    SetHttpOptions() {
        this.BearerToken = this.App.getCookie("Bearer");
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': '*', 'Access-Control-Allow-Headers': '*',
                'Authorization': 'Bearer ' + this.BearerToken
            })
        };
        this.httpOptions_empty = {
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.BearerToken })
        };

    }


    httpOptions1 = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' }),
        responseType: 'text' as 'json'
    };

    async ExecuteAPI_Post_Local<T>(action: string, params: any = {}): Promise<any> {
        const url = this.service.Settings.API_URL + action;
        console.log(url);
        const response = await this.http.post(url, params, this.httpOptions1).toPromise();
        return response as T; // Adjust the return type as per your needs.
    }


    //API Common Method
    ExecuteAPI_Get<T>(action: string, params: any = {}): Promise<T> {
        action = this.service.Settings.API_URL + '/' + action;
        const url = `${this.service.Settings.API_URL}/${action}`;
        console.log(action);
        this.BearerToken = this.App.getCookie('Bearer');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${this.BearerToken}`
            })
        };
        return this.http.get<T>(action, { params: params, headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.BearerToken }) }).toPromise().catch((err) => {
            throw err;
        });
        // return this.http.get<T>(url, { params: params, headers: httpOptions.headers }).toPromise().catch((err) => {
        //     throw err;
        // });
    }

    ExecuteAPI_Edit<T>(action: string): Promise<T> {
        action = this.service.Settings.API_URL + '/' + action;
        const url = `${this.service.Settings.API_URL}/${action}`;
        console.log(action);
        this.BearerToken = this.App.getCookie('Bearer');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${this.BearerToken}`
            })
        };
        return this.http.get<T>(action, { headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.BearerToken }) }).toPromise().catch((err) => {
            throw err;
        });;
    }

    ExecuteAPI_Get_Auth(action: string, params: any = {}): Promise<any> {
        action = this.service.Settings.API_URL + '/' + action;
        console.log(action);
        return this.http.get(action, { params: params, headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.BearerToken }) }).toPromise().catch((err) => {
            throw err;
        });
    }


    async ExecuteAPI_PostJson(action: string, params: any = {}): Promise<any> {
        // Construct the full URL by combining the API base URL and the specified action.
        let url = this.service.Settings.Base_API_URL + '/' + action;
        console.log(url);
        // Set headers to indicate that you are sending JSON data.
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        //const response = await this.http.post(this.service.Settings.Base_API_URL + '/api/login', params).toPromise();
        // Use HttpClient to make an HTTP POST request.
        return this.http.post(url, JSON.stringify(params), { headers: headers })
            .toPromise()
            .then((response) => {
                // Handle the success case if needed
                console.log('Request successful:', response);
                return response;
            })
            .catch((error) => {
                // Handle errors
                console.error('Error in API request:', error);
                throw error; // Re-throw the error to be caught by the caller
            });
    }

    async ExecuteAPI_Post(action: string, params: any = {}): Promise<any> {
        const url = `${this.service.Settings.API_URL}/${action}`;
        this.BearerToken = this.App.getCookie('Bearer');
        console.log(url);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${this.BearerToken}`
            })
        };

        try {
            const response = await this.http.post(url, JSON.stringify(params), httpOptions)
                .pipe(timeout(300000))
                .toPromise();

            // Assuming the response contains information you need
            return response;
        } catch (error) {
            // Handle errors
            throw error;
        }
    }

    async ExecuteAPI_Delete(action: string, value: string): Promise<any> {
        const url = `${this.service.Settings.API_URL}/${action}/${value}`;
        this.BearerToken = this.App.getCookie('Bearer');
        console.log(url);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${this.BearerToken}`
            })
        };

        try {
            const response = await this.http.delete(url, httpOptions)
                .pipe(timeout(300000))
                .toPromise();

            // Assuming the response contains information you need
            return response;
        } catch (error) {
            // Handle errors
            throw error;
        }
    }



    async ExecuteAPI_Put(action: string, value: string, params: any = {}): Promise<any> {
        const url = `${this.service.Settings.API_URL}/${action}/${value}`;
        this.BearerToken = this.App.getCookie('Bearer');
        console.log(url);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${this.BearerToken}`
            })
        };

        try {
            const response = await this.http.put(url, JSON.stringify(params), httpOptions)
                .pipe(timeout(300000))
                .toPromise();
            console.log(response, "response");
            // Assuming the response contains information about affected records
            return response;
        } catch (error) {
            // Handle errors
            throw error;
        }
    }


    public ExecuteAPI_Post_Auth<T>(action: string, params: any = {}): Promise<any> {
        let url = this.service.Settings.API_URL + '/' + action;
        console.log(this.httpOptions);
        console.log(this.httpOptions1);
        return this.http.post<T>(url, params, this.httpOptions).pipe(timeout(300000)).toPromise().catch((err) => {
            throw err;
        });
    }

    ExecuteAPI_Post_Loader(action: string, params: any = {}): Promise<any> {
        this.App.ShowLoader = true;
        let promise = this.ExecuteAPI_Post(action, params);
        promise.then(() => { this.App.ShowLoader = false; }).catch((err) => { this.App.ShowLoader = false; throw err; });
        return promise;
    }
}

export class Deferred<T> {
    promise: Promise<T>;
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

class AppHelper {
    constructor(public service: SystemService) { }
    public ShowLoader: boolean = false; public AppLoader: boolean = true;
    public RefreshData: EventEmitter<any> = new EventEmitter();

    public searchFilter: EventEmitter<any> = new EventEmitter();
    public refreshGrid: EventEmitter<any> = new EventEmitter();
    public showhideColumnFilter: EventEmitter<any> = new EventEmitter();
    public clearAllCheckbox: EventEmitter<any> = new EventEmitter();

    //####### ANIMATION
    //public ShowLoader: boolean; public ShowFullLoader: boolean;
    //public RefreshData: EventEmitter<any> = new EventEmitter();
    public changeAnimation: EventEmitter<any> = new EventEmitter();
    public sortChange: EventEmitter<any> = new EventEmitter();
    //public searchFilter: EventEmitter<any> = new EventEmitter();
    //public refreshGrid: EventEmitter<any> = new EventEmitter();
    public normalRefreshGrid: EventEmitter<any> = new EventEmitter();
    public clearandRefreshGrid: EventEmitter<any> = new EventEmitter();
    //public showhideColumnFilter: EventEmitter<any> = new EventEmitter();
    //public clearAllCheckbox: EventEmitter<any> = new EventEmitter();
    //####### ANIMATION


    public _appData: any = {};
    GetData<T>(key: string): T {
        return (<T>this._appData[key]);
    }
    SetData(key: string, data: any) {
        this._appData[key] = data;
    }
    setCookie(cname, cvalue, date: Date | number, rvalue) {
        let d = new Date();
        if (date instanceof Date) {
            d = <Date>date;
        } else {
            d.setTime(d.getTime() + (<number>date * 24 * 60 * 60 * 1000));
        }
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";" + rvalue + ";path=/";
    }
    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    checkSetting() {
        this.setCookie("Bearer", "", 0, "");
    }
    Clear_Local_Storage() {
        window.localStorage.clear();
    }
    set_localstorage(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    get_localstorage(key) {
        let value = localStorage.getItem(key);
        return JSON.parse(value!);
    }
    //   get_cached_column(key, gridfilter: Array<GridFilter>) {
    //     let cols = this.get_localstorage(key);
    //     if (cols) {
    //       gridfilter.map(d => {
    //         let item = cols.find(x => x.col == d.ColumnName);
    //         if (item) { d.Is_Visible = item.show; }
    //       });
    //     }
    //   }

    setLocalStorage(key, value) {
        localStorage.setItem(key, value);
    }
    getLocalStorage(key) {
        return localStorage.getItem(key);
    }

}

enum StatusType {
    Open = "Open",
    Closed = "Closed",
    OnHold = "OnHold"
}
enum Storage_Key {
    DB_Announcement = "DB_Announcement"
}
