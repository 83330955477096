<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor); border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Update Filter Search</h4>
                        </header>
                        <article class="card-body">
                            <div *ngIf="isLoading" class="overlay-inner">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>
                            <form [formGroup]="filterHeaderSearchForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label>Select Name:</label>
                                    <ng-select formControlName="filterNameId" #myselect
                                        (change)="onMaterialGroupChange($event)" [(ngModel)]="selectedfilterName">
                                        <ng-option *ngFor="let obj of allfilterheaderNames" [value]="obj.filterId"
                                            class="form-control">{{obj.filterHeaderName1}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="filterHeaderSearchForm.get('filterNameId')?.hasError('required') && filterHeaderSearchForm.get('filterNameId')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label>Select Value:</label>
                                    <ng-select formControlName="filterValueId" #myselect
                                        (change)="onMaterialGroupChange($event)" [(ngModel)]="selectedfilterValue">
                                        <ng-option *ngFor="let obj of allfilterheaderValues"
                                            [value]="obj.filterValueId"
                                            class="form-control">{{obj.filterValue}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="filterHeaderSearchForm.get('filterValueId')?.hasError('required') && filterHeaderSearchForm.get('filterValueId')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label>Select Type:</label>
                                    <ng-select formControlName="typeId" #myselect
                                        (change)="onMaterialGroupChange($event)" [(ngModel)]="selectedfilterType">
                                        <ng-option *ngFor="let obj of allfilterheaderTypes" [value]="obj.typeId"
                                            class="form-control">{{obj.typeTitle}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="filterHeaderSearchForm.get('typeId')?.hasError('required') && filterHeaderSearchForm.get('typeId')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label for="FilterHeaderName1">FilterHeader Name:</label>
                                    <input type="text" id="FilterHeaderName1" class="form-control"
                                        formControlName="FilterHeaderName1">
                                </div> -->
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block"
                                        [disabled]="!filterHeaderSearchForm.valid">Update</button>
                                    <button class="btn btn-warning  btn-block "
                                        routerLink="/filterHeaderlistSearch">Back</button>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
