import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { FAQ } from '../../model/faq';
import { FAQService } from '../../services/faq.service';

@Component({
    selector: 'app-faq-edit',
    templateUrl: './faq-edit.component.html',
    styleUrls: ['./faq-edit.component.scss'],
    providers: [DatePipe],
})
export class faqEditComponent implements OnInit {
    faqform: FormGroup;
    faq: FAQ; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    id: any;
    documentTypeId: any;
    typeId: any;
    galleryrecord: any;
    isLoading = false;
    galleryBuget: any;
    description: any;
    date: any = new Date();
    apiPath: any;
    imagePhotoName: any;

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private fAQService: FAQService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService, private route: ActivatedRoute) {

        this.id = this.route.snapshot.paramMap.get('id');
        this.apiPath = this.service.Settings.Base_API_URL;

    }



    ngOnInit() {
        this.Createfaqform();
        this.loadfaqId();
    }

    loadfaqId() {
        console.log(this.id);
        this.isLoading = true;
        this.fAQService.getfaq(this.id).then(Gallerydata => {
            console.log(Gallerydata, "Gallerydatagetdataoffaq");
            this.faqform.patchValue({ faqId: Gallerydata.faqId });
            this.faqform.patchValue({ faqQuestions: Gallerydata.faqQuestions });
            this.faqform.patchValue({ faqAnswer: Gallerydata.faqAnswer });
            this.faqform.patchValue({ faqLanguage: Gallerydata.faqLanguage });
            this.isLoading = false;
        });

    }

    onSubmit() {
        let obj = this.faqform.getRawValue();
        console.log(obj, "objgetraw")
       
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
        obj.createdDate = formattedDate;
        obj.active = true;
        console.log("Update");
        let res = this.fAQService.updatefaq(obj);
        if (res) {
            this.faqform.reset();
            this.alertify.success("Data Updated Successfully");
            this.router.navigate(['/faqList']);
        }

        this.userSubmitted = false;

    }


    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }


    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Createfaqform() {
        this.faqform = this.fb.group({
            faqId: 0,
            faqQuestions: ['', Validators.required],
            faqAnswer: ['', Validators.required],
            faqLanguage: ['', Validators.required],
            createdDate: [''],
            extra1: [''],
            extra2: [''],
            extra3: [''],
            active: [true],
        });
    }


    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl, "imageurlimagecom");
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }



    cancel() {
        this.faqform.reset();
        this.router.navigate(['/faqList']);
    }
}

