import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QChoiceService } from '../../services/qchoice.service';
import { ChoiceModel } from '../../model/question';
import { AlertifyService } from '../../services/alertify.service';
import { SystemService } from '../../services/system.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionsService } from '../../services/questions.service';

@Component({
    selector: 'app-questionchoice-form',
    templateUrl: './questionchoice.html',
    styleUrls: ['./questionchoice.css'],
})
export class ChoiceFormComponent implements OnInit {
    choiceForm: FormGroup;
    choices: any;
    selectedChoice: ChoiceModel | null = null;
    id: any; QuestionId: any;
    choicesdata: any;
    EditChoise: ChoiceModel;
    private gridApi: any;
    private gridColumnApi: any;
    rowData: any;

    constructor(private fb: FormBuilder, private qChoiceService: QChoiceService, private route: ActivatedRoute,
        private router: Router, private questionService: QuestionsService, private alertify: AlertifyService,
        public service: SystemService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.EditChoise = null;
        this.loadchoices();
        this.bindQuestionsById();
    }


    bindQuestionsById() {
        console.log(this.id, "idinchoice");
        this.questionService.getQuestionById(Number(this.id)).then(data => {
            data = this.convertKeysToCamelCase(data);
            this.choicesdata = data;
            console.log(this.choicesdata);
            //this.QuestionsForm.patchValue(data);
            const QuestionId = data.Id;
            this.QuestionId = QuestionId;
            console.log(JSON.stringify(data));
        });
    }

    ngOnInit() {
        this.choiceForm = this.fb.group({
            Id: [null],
            QuestionId: [null],
            Label: ['', Validators.required],
            Value: ['', Validators.required],
            Selected: [false, Validators.required]
        });
        this.refreshGridData();
    }

    loadchoices() {
        this.qChoiceService.getChoiceQuestionById(this.id).then(choices => {
            this.choices = choices;
            console.log(this.choices, "this.choices");
            console.log(JSON.stringify(this.choices));
        });
    }
    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }
    refreshGridData(): void {
        this.getAllChoice();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
     }

    async onSubmit() {
        if (this.choiceForm.valid) {
            const choice: ChoiceModel = this.choiceForm.value;
            if (choice.Id) {
                //Update
                console.log("Update: " + JSON.stringify(this.EditChoise));
                choice.Id = this.EditChoise.Id;
                choice.QuestionId = this.EditChoise.QuestionId;
                console.log("Update: " + JSON.stringify(choice));
                let res = this.qChoiceService.updateChoice(choice);
                if (res) {
                    this.alertify.success("Data Saved Successfully");
                    this.refreshGridData();
                    this.loadchoices(); // Refresh the category list
                    this.choiceForm.reset();
                }

            } else {
                console.log("Add: " + JSON.stringify(this.EditChoise));
                choice.Id = 0;
                choice.QuestionId = this.id;
                console.log("Add: " + JSON.stringify(choice));
                let res = this.qChoiceService.addChoice(choice);
                if (res) {
                    this.alertify.success("Data Saved Successfully");
                    console.log(JSON.stringify(res));
                    this.refreshGridData();
                    this.loadchoices(); // Refresh the category list
                    this.choiceForm.reset();
                }
            }

            // Reset the form
            this.choiceForm.reset();
            this.EditChoise = null;
        }
    }
    async getAllChoice() {
        try {
            this.service.App.ShowLoader = true;
            const choices = await this.qChoiceService.getAllChoice();
            console.log(choices, "getallchoices");
            this.choices = choices;
            this.alertify.success("Data Loaded Successfully");
        } catch (error) {
            console.error("Error loading questions:", error);
            // Handle the error, e.g., show a user-friendly message
            this.alertify.error("An error occurred while loading data.");
        } finally {
            this.service.App.ShowLoader = false;
        }
    }

    onEdit(Choice: ChoiceModel) {
        console.log(Choice);
        this.EditChoise = Choice;
        console.log(Choice);
        this.EditChoise = this.convertKeysToCamelCase(Choice);
        // Manually set values for each control
        this.choiceForm.get('Id').setValue(this.EditChoise.Id);
        this.choiceForm.get('QuestionId').setValue(this.EditChoise.QuestionId);
        this.choiceForm.get('Label').setValue(this.EditChoise.Label);
        this.choiceForm.get('Value').setValue(this.EditChoise.Value);
        this.choiceForm.get('Selected').setValue(this.EditChoise.Selected);
        console.log('After manual setting:', this.choiceForm.value);
    }

    onDelete(Id: number) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.qChoiceService.deleteChoice(Id);
            if (res) {
                this.alertify.success("Deleted Successfully");
                this.refreshGridData();
                // this.loadchoices(); // Refresh the category list
            }
        }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
    cancel() {
        this.choiceForm.reset()
        this.router.navigate(['/questionslistSub', this.choicesdata.QuestionnaireId]);
    }
}




