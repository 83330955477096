
<app-navbar-style-admin></app-navbar-style-admin>

<div class="blog-details-area pt-50 pb-0" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
              <h4 class="card-title mt-2 text-center" style="color:white">Create Menu</h4>
            </header>
            <article class="card-body">
              <form [formGroup]="menuForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label for="menuName">Menu Name:</label>
                  <input type="text" id="menuName" class="form-control" formControlName="menuName" required>
                  <div
                    *ngIf="menuForm.get('menuName')?.hasError('required') && menuForm.get('menuName')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>

                <div class="form-group">
                  <label for="menuUrl">Menu Url:</label>
                  <input type="text" id="menuUrl" class="form-control" formControlName="menuUrl" required>
                  <div
                    *ngIf="menuForm.get('menuUrl')?.hasError('required') && menuForm.get('menuUrl')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>

                <div class="form-group">
                  <label for="menuIcon">Menu Icon</label>
                  <input type="text" id="menuIcon" class="form-control" formControlName="menuIcon" required>
                  <div
                    *ngIf="menuForm.get('menuIcon')?.hasError('required') && menuForm.get('menuIcon')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>

                <div class="form-group">
                  <label for="menuContent">Menu Content:</label>
                  <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)"
                      formControlName="menuContent" (onEditorChanged)="changedEditor($event)"
                      (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                  <div *ngIf="menuForm.get('menuContent')?.hasError('required') && menuForm.get('menuContent')?.touched"
                      class="error-block">
                      This field is required.
                  </div>
              </div>

             
              <div class="form-group">
                  <label>Upload Image:</label>
                  <input type="file" (change)="onFileSelect($event)"
                      class="btn btn-info btn-sm mt-2" formControlName="extra1">
                  <img *ngIf="imageUrl" class="imageUpload" [src]="imageUrl" />
                  <div *ngIf="menuForm.get('extra1')?.hasError('extra1') && menuForm.get('extra1')?.touched"
                      class="error-block">
                      This field is required.
                  </div>
              </div>


                <div class="form-group">
                  <button type="submit" class="btn btn-success btn-block" [disabled]="!menuForm.valid">Save</button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="blog-details-area pt-50 pb-70" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <article class="card-body">
              <!-- Grid section -->
              <app-menu-grid [menu]="menu" (editMenu)="onEdit($event)"
                (deleteMenu)="onDelete($event)"></app-menu-grid>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-style-two></app-footer-style-two>
