// category-grid.component.ts

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Category } from './category';


@Component({
  selector: 'app-category-grid',
  templateUrl: './category-grid.html',
  styleUrls: ['./category-grid.css'],
})
export class CategoryGridComponent implements OnInit {
  @Input() category: Category[] = [];
  @Output() editCategory = new EventEmitter<Category>();
  @Output() deleteCategory = new EventEmitter<number>();

  ngOnInit() {console.log(this.category);}

  onEdit(category: Category) {
    console.log(category);
    this.editCategory.emit(category);
  }

  onDelete(categoryId: number) {
    this.deleteCategory.emit(categoryId);
  }
}
