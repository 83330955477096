<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header headerbar" style="border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Logo</h4>
                        </header>
                        <article class="card-body">
                            <form [formGroup]="LogoForm" (ngSubmit)="onSubmit()">
                                <!-- <div class="card-body">
                                    <div class="col-12">
                                        
                                        <div class="row" style="display: flex;justify-content: center;">
                                            <div
                                                class="col-12 col-sm-4 col-md-3 mb-3 text-center border pt-2 pb-2 dv-profile-left">
                                                <div class="m-card-profile__pic-wrapper">
                                                    <img *ngIf="imageUrl" class="img-fluid" [src]="imageUrl"
                                                        style="width:160px;" />
                                                </div>
                                                <button type="button" class="btn btn-info btn-sm mt-2"
                                                    (click)="flProfilepic.click();">ChooseApplicationLogo</button>
                                                <input type="hidden" formControlName="logoPath">
                                                <input #flProfilepic type="file" class="hide"
                                                    (change)="fileChange($event)" accept="image/*">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label>Upload Logo:</label>
                                    <input type="file" (change)="onFileSelect($event)"
                                        class="btn btn-info btn-sm mt-2" formControlName="logoPath">
                                    <img *ngIf="imageUrl" class="img-fluid" [src]="imageUrl" style="width:160px;"/>
                                    <div *ngIf="LogoForm.get('logoPath')?.hasError('logoPath') && LogoForm.get('logoPath')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">Company Name:</label>
                                    <input type="text" class="form-control" formControlName="companyName">
                                    <div *ngIf="LogoForm.get('companyName')?.hasError('required') && LogoForm.get('companyName')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">Company Title:</label>
                                    <input type="text" class="form-control" formControlName="companyTitle">
                                    <div *ngIf="LogoForm.get('companyTitle')?.hasError('required') && LogoForm.get('companyTitle')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">Email:</label>
                                    <input type="text" class="form-control" formControlName="email">
                                    <div *ngIf="LogoForm.get('email')?.hasError('required') && LogoForm.get('email')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">Description:</label>
                                    <input type="text" class="form-control" formControlName="description">
                                    <div *ngIf="LogoForm.get('description')?.hasError('required') && LogoForm.get('description')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">Phone Number:</label>
                                    <input type="text" class="form-control" formControlName="phoneNo">
                                    <div *ngIf="LogoForm.get('phoneNo')?.hasError('required') && LogoForm.get('phoneNo')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">Address:</label>
                                    <input type="text" class="form-control" formControlName="address">
                                    <div *ngIf="LogoForm.get('address')?.hasError('required') && LogoForm.get('address')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Title">Webiste:</label>
                                    <input type="text" class="form-control" formControlName="webiste">
                                    <div *ngIf="LogoForm.get('webiste')?.hasError('required') && LogoForm.get('webiste')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block">{{ type }}</button>
                                    <button class="btn btn-warning  btn-block "
                                        (click)="Back()">Back</button>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
<!-- routerLink="/adminPanel" -->