import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';



import { MenuPageContentService } from '../../services/menupagecontent.service';
import { MenuPageContentModel } from '../../model/MenuPageContent';
@Component({
    selector: 'app-menupage-listcontent-list',
    templateUrl: './menupage-listcontent.html',
    styleUrls: ['./menupage-listcontent.css'],
})
export class MenuPageListContentformComponent implements OnInit {
    MenuPageContentform: FormGroup;
    gallery: MenuPageContentModel; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];

    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    galleryItems: any;
    isLoading = false;
    apiPath: any;
    menupagecontent: any;
    menupagecontents: any;
    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private MenuPageContentService: MenuPageContentService,
        public service: SystemService) { }

    ngOnInit() {
        this.apiPath = this.service.Settings.Base_API_URL;
       //  this.refreshGridData();
        this.loadmenupagecontentgrid();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'menuPageContentId', sortable: true, filter: true, width: 20 },
        { headerName: 'Content Icon', field: 'contentIcon', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'content FileName', field: 'contentFileName', sortable: true, filter: true, editable: true, width: 150 },
        // { headerName: 'Content Type', field: 'contentType', sortable: true, filter: true, editable: true, width: 200 },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;


    refreshGridData(): void {
        this.loadmenupagecontentgrid();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }
    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    imageCellRenderer(params: any) {
        return `<img src="${this.apiPath + '/Documents/appImages/' + params.value}" alt="Image" style="max-width: 50px; max-height: 50px;">`;
    }
    actionsCellRenderer(params: any) {
        // const addButton = document.createElement('button');
        // addButton.innerHTML = 'Add';
        // addButton.classList.add('Actions-btn'); // Apply the CSS class
        // addButton.addEventListener('click', () => this.addRow());

        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        //wrapper.appendChild(addButton);
        wrapper.appendChild(deleteButton);

        return wrapper;
    }

    addRow() {
        this.router.navigate(['/AddMenuPageContent']);
    }
    editRow(rowData: any) {
        console.log('Edit row:', rowData);
        let id = rowData.menuPageContentId;
        console.log('idpassingtoedit', id);
        this.router.navigate(['/EditMenuPageContent', id]);

    }
    deleteRow(rowData: any) {
        console.log(rowData);
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.MenuPageContentService.deletemenupagecontent(rowData.menuPageContentId);
            if (res) {
                console.log(JSON.stringify(res));
                this.loadmenupagecontentgrid();
                this.refreshGridData();
                this.loadmenupagecontentgrid();
                this.alertify.success("Deleted Successfully");
            }
        } else {
            console.log('Deletion canceled.');
        }

    }

    loadmenupagecontentgrid() {
        this.service.App.ShowLoader = true;
        this.MenuPageContentService.getAllGridData().then(menupagecontent => {
            this.menupagecontents = menupagecontent;
            this.rowData = menupagecontent;
           // this.refreshGridData();
            console.log(JSON.stringify(this.menupagecontent));
            this.service.App.ShowLoader = false;
        }).catch(error => {
            console.error('Error fetching submenus:', error);
            this.service.App.ShowLoader = false; // Ensure loader is hidden even in case of an error.
        });
    }



    AddMenuPageContent() {
        this.router.navigate(['/AddMenuPageContent']);
    }


}

