import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { SubMenuService } from '../../services/submenu.service';
import { MenuPageContentService } from '../../services/menupagecontent.service';
import { MenuPageContentModel } from '../../model/MenuPageContent';


@Component({
    selector: 'app-menupage-editcontent-edit',
    templateUrl: './menupage-editcontent.html',
    styleUrls: ['./menupage-editcontent.css'],
    providers: [DatePipe],
})
export class MenuPageEditContentformComponent implements OnInit {
    MenuPageContentform: FormGroup;
    gallery: MenuPageContentModel; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];

    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;

    MenuContentPage: any;
    id: any;
    MenuContent: any;
    isLoading = false;
    selectedMenuId: number;

    description: any;
    date: any = new Date();
    Dropdown: any;
    apiPath: any;
    menucontentrecord: any;
    extra1: any;
    imagePhotoName: any;
    userSubmitted: boolean;
    galleryrecord: any;

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private submenuService: SubMenuService,
        private menucontentservice: MenuPageContentService,
        public service: SystemService, private route: ActivatedRoute) {

        this.id = this.route.snapshot.paramMap.get('id');
        this.apiPath = this.service.Settings.Base_API_URL;
    }
    ngOnInit() {
        this.Createmenupagecotentform();
        this.menudropdown();
        this.loadMenucontentId();
    }

    loadMenucontentId() {
        console.log(this.id);
        this.isLoading = true;
        this.menucontentservice.getmenupagecontentById(this.id).then(MenuContent => {
            this.imageUrl = this.apiPath + "/Documents/appImages/" + MenuContent.extra1;
            this.MenuContentPage = MenuContent;
            this.MenuPageContentform.patchValue(MenuContent);
            console.log(MenuContent, 'PATCH')
            this.isLoading = false;
        });

    }


    onSubmit() {
        this.userSubmitted = true;
        let obj = this.MenuPageContentform.getRawValue();
        console.log(obj, 'OBJ');
        console.log(JSON.stringify(this.gallery));
        console.log(this.MenuContentPage, "this.MenuContentPage");
        obj.extra1 = this.imageUrl ? this.imageUrl : obj.extra1;
        console.log(JSON.stringify(obj));
        if (obj.menuPageContentId) {
            obj.active = true;
            this.menucontentservice.updatemenupagecontent(obj);
            this.MenuPageContentform.reset();
            this.alertify.success("Data Updated Successfully");
            this.router.navigate(['/MenuPageContent']);
        }
        this.userSubmitted = false;

    }
    // async onSubmit() {
    //     this.userSubmitted = true;
    //     let obj = this.MenuPageContentform.getRawValue();
    //     console.log(obj, 'OBJ');
    //     console.log(JSON.stringify(this.gallery));
    //     console.log(this.MenuContentPage, "this.MenuContentPage");
    //     obj.extra1 = this.imageUrl ? this.imageUrl : obj.extra1;
    //     console.log(JSON.stringify(obj));
    //     console.log(obj.menuPageContentId, "obj.menuPageContentId");
    //     try {
    //         let res = await this.menucontentservice.updatemenupagecontent(obj);

    //         if (res) {
    //             this.MenuPageContentform.reset();
    //             this.alertify.success("Data Updated Successfully");
    //             this.router.navigate(['/MenuPageContent']);
    //         }
    //     } catch (error) {
    //         console.error("Error updating menu page content:", error);
    //         // Handle the error as needed
    //     } finally {
    //         this.userSubmitted = false; // Move this line inside the try block to ensure it's executed regardless of success or failure
    //     }
    // }

    menudropdown() {
        this.submenuService.getDropdown().then(dropdown => {
            console.log(JSON.stringify(dropdown));
            this.Dropdown = dropdown;
        });
    }
    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }


    onMaterialGroupChange(event) {
        //console.log(event);
    }

    blured = false
    focused = false

    created(event) {

        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {

        console.log('editor-change', event)
    }

    focus($event) {

        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {

        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }


    Createmenupagecotentform() {
        this.MenuPageContentform = this.fb.group({
            menuPageContentId: 0,
            menuId: 0,
            contentIcon: '',
            contentType: '',
            contentFileName: '',
            createdDate: '',
            extra1: '',
            extra2: '',
            extra3: '',
            extra4: '',
            extra5: '',
            active: true,
        });
    }


    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl, "imageurlimagecom");
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }



    cancel() {
        this.MenuPageContentform.reset();
        this.router.navigate(['/MenuPageContent']);
    }
}

export { MenuPageContentModel }