import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { FAQ } from '../../model/faq';
import { FAQService } from '../../services/faq.service';

@Component({
    selector: 'app-faq-add',
    templateUrl: './faq-add.component.html',
    styleUrls: ['./faq-add.component.scss'],
    providers: [DatePipe],
})
export class faqAddComponent implements OnInit {
    faqform: FormGroup;
    faq: FAQ; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    isLoading: boolean = false;
    date: any = new Date();

    constructor(private fb: FormBuilder,

        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private fAQService: FAQService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService) { }



    ngOnInit() {
        this.Createfaqform();
    }

    onSubmit() {
        this.userSubmitted = true;
        this.isLoading = true;
        let obj = this.faqform.getRawValue();
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
        obj.createdDate = formattedDate;
        obj.active = true;
        console.log("Add");
        console.log(JSON.stringify(obj));
        let res = this.fAQService.addfaq(obj);
        if (res) {
            this.isLoading = false;
            console.log(JSON.stringify(res));
            this.faqform.reset();
            this.alertify.success("Data Saved Successfully");
            this.router.navigate(['/faqList']);
        }
        this.userSubmitted = false;

    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Createfaqform() {
        this.faqform = this.fb.group({
            faqId: 0,
            faqQuestions: ['', Validators.required],
            faqAnswer: ['', Validators.required],
            faqLanguage: ['', Validators.required],
            createdDate: [''],
            extra1: [''],
            extra2: [''],
            extra3: [''],
            active: [true],
        });
    }

   

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    cancel() {
        this.faqform.reset();
        this.router.navigate(['/faqList']);
    }
}

