import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { VideoGallery } from '../../model/videogallery';
import { GalleryService } from '../../services/gallery.service';
import { VideoGalleryService } from '../../services/videoGallery.service';
@Component({
    selector: 'app-videogallery-add',
    templateUrl: './videogallery-add.component.html',
    styleUrls: ['./videogallery-add.component.scss'],
    providers: [DatePipe],
})
export class VideoGalleryAddComponent implements OnInit {
    videogalleryform: FormGroup;
    gallery: VideoGallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    date: any = new Date();
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    isLoading: boolean = false;
    

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private galleryService: GalleryService,
        private videogalleryService: VideoGalleryService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService) { }



    ngOnInit() {
        this.Creategalleryform();
        this.loadDocumentTypeService();
    }
    loadDocumentTypeService() {
        this.documentTypeService.getAlldocument().then(documentTypelist => {
            this.documentTypelist = documentTypelist;

            console.log(JSON.stringify(this.documentTypelist));
        });
    }

    onSubmit() {
        this.userSubmitted = true;
        this.isLoading = true;
        let obj = this.videogalleryform.getRawValue();
        obj.createdDate=this.date.toISOString();
        const gallery: VideoGallery = this.videogalleryform.value;
        if (gallery.videoGalleryId) {
            console.log("Update");
            let res = this.videogalleryService.updateGallery(obj);
            if (res) {
                this.loadDocumentTypeService(); 
                this.videogalleryform.reset();
                this.router.navigate(['/Videogallerylist']);
                this.alertify.success("Data Updated Successfully");
            }
        }
        else {
            console.log("Add");
            console.log(JSON.stringify(obj));
            let res = this.videogalleryService.addGallery(obj);
            if (res) {
                this.isLoading =false;
                console.log(JSON.stringify(res));
                this.loadDocumentTypeService(); 
                this.alertify.success("Data Saved Successfully");
                this.videogalleryform.reset();
                this.router.navigate(['/Videogallerylist']);
            }
        }
        this.userSubmitted = false;

    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Creategalleryform() {
        this.videogalleryform = this.fb.group({
            videoGalleryId: 0,
            videoCategory: ['', Validators.required],
            videoLink: ['', Validators.required],
            videoDescp: ['', Validators.required],
            createdDate: [''],
            extra1: [''],
            extra2: [''],
            extra3: [''],
            extra4: [''],
            extra5: [''],
            extra6: [''],
            extra7: [''],
            extra8: [''],
            extra9: [''],
            extra10: [''],
            isActive: [true],
            status: [true],
            typeDetailId: [''],
            blogDetailId: [''],
            newsDetailId: [''],
            documentTypeId:['', Validators.required],
        });
    }
   

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    cancel() {
        this.videogalleryform.reset();
        this.router.navigate(['/Videogallerylist']);
    }
}

