import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';

import { ImageGallery } from '../../model/imagegallery';
import { SubMenuService } from '../../services/submenu.service';
import { MenuPageContentService } from '../../services/menupagecontent.service';
import { MenuPageContentModel } from '../../model/MenuPageContent';

@Component({
    selector: 'app-menupage-addcontent-add',
    templateUrl: './menupage-addcontent.html',
    styleUrls: ['./menupage-addcontent.css'],
    providers: [DatePipe],
})
export class MenuPageAddContentformComponent implements OnInit {
    MenuPageContentform: FormGroup;
    gallery: ImageGallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    gridApi: any;

    isLoading: boolean = false;

    Dropdown: any;
    selectedMenuId: number;
    rowData: any;
    menupagecontent: any;

    constructor(private fb: FormBuilder,

        private alertify: AlertifyService,
        private router: Router,
        private menucontentservice: MenuPageContentService,
        private submenuService: SubMenuService,
        public service: SystemService) { }



    ngOnInit() {
        this.Createmenupagecotentform();
        this.loaddropdown();
        //this.loadmenupagecontentgrid();
    }
    refreshGridData(): void {
        this.gridApi.setRowData(this.rowData);
    }
    loaddropdown() {
        this.submenuService.getDropdown().then(dropdown => {
            console.log(JSON.stringify(dropdown));
            this.Dropdown = dropdown;
        });
    }

    onSubmit() {
        this.isLoading = true;
        const gallery: MenuPageContentModel = this.MenuPageContentform.value;
        let obj = this.MenuPageContentform.getRawValue();
        obj.extra1 = this.imageUrl ? this.imageUrl : obj.extra1;
        console.log(JSON.stringify(obj));
        if (obj.menuPageContentId) {
            obj.active = true;
            console.log("Update");
            let res = this.menucontentservice.updatemenupagecontent(obj);
            if (res) {
                this.refreshGridData();
                this.alertify.success("Data Updated Successfully");
            }
        }
        else {
            obj.active = true;
            console.log("Add");
            console.log(JSON.stringify(obj));
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
            obj.createdDate = formattedDate;
            let res = this.menucontentservice.addmenupagecontent(obj);
            if (res) {
                this.isLoading = false;
                console.log(JSON.stringify(res));
                //this.loadDocumentTypeService(); // Refresh the category list
                this.MenuPageContentform.reset();
                this.alertify.success("Data Saved Successfully");
                this.router.navigate(['/MenuPageContent']);
                this.refreshGridData();
            }
        }


    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Createmenupagecotentform() {
        this.MenuPageContentform = this.fb.group({
            menuPageContentId: 0,
            menuId: 0,
            contentIcon: '',
            contentType: '',
            contentFileName: '',
            createdDate: '',
            extra1: '',
            extra2: '',
            extra3: '',
            extra4: '',
            extra5: '',
            active: true,
        });
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }

    blured = false
    focused = false

    created(event) {

        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {

        console.log('editor-change', event)
    }

    focus($event) {

        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {

        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    cancel() {
        this.MenuPageContentform.reset();
        this.router.navigate(['/MenuPageContent']);
    }
}

