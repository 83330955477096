<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0" #blogContainer2>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create subcategory</h4>
                        </header>
                        <article class="card-body">
                            <form [formGroup]="SubcategoryForm" (ngSubmit)="onSubmit()">

                                <div class="form-group">
                                    <label>Select Sub category:</label>
                    
                                    <ng-select formControlName="categoryId" #myselect
                                        (change)="onMaterialGroupChange($event)" [(ngModel)]="selectedCategoryId"
                                        required>
                                        <ng-option *ngFor="let obj of categotyList" [value]="obj.categoryId"
                                            class="form-control">{{obj.categoryName}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="SubcategoryForm.get('categoryId')?.hasError('required') && SubcategoryForm.get('categoryId')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="subcategoryName">subcategory Name:</label>
                                    <input type="text" id="subcategoryName" class="form-control"
                                        formControlName="subcategoryName" required>
                                    <div *ngIf="SubcategoryForm.get('subcategoryName').hasError('required') && SubcategoryForm.get('subcategoryName')?.touched"
                                        class="text-danger">
                                        This field is required.
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label for="subcategoryDescp">subcategory Description:</label>
                                    <input type="text" id="subcategoryDescp" class="form-control"
                                        formControlName="subcategoryDescp"  required>
                                        <div *ngIf="SubcategoryForm.get('subcategoryDescp').hasError('required') && SubcategoryForm.get('subcategoryDescp')?.touched" class="text-danger">
                                            Subcategory Descp Form is required.
                                        </div>
                                </div> -->
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block"
                                        [disabled]="!SubcategoryForm.valid">Save</button>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="blog-details-area pt-50 pb-70" #blogContainer2>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <!-- <header class="card-header" style="background: var(--mainColor);">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create subcategory</h4>
                        </header> -->
                        <article class="card-body">
                            <!-- Grid section -->
                            <app-subcategory-grid [subcategories]="subcategories" (editSubCategory)="onEdit($event)"
                                (deleteSubCategory)="onDelete($event)"></app-subcategory-grid>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>
