import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Gallery } from '../../model/gallery';
import { ImageGalleryService } from '../../services/imagegallery.service';

@Component({
    selector: 'app-imagegallery-edit',
    templateUrl: './imagegallery-edit.component.html',
    styleUrls: ['./imagegallery-edit.component.scss'],
    providers: [DatePipe],
})
export class ImageGalleryEditComponent implements OnInit {
    imagegalleryform: FormGroup;
    gallery: Gallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    id: any;
    documentTypeId: any;
    typeId: any;
    galleryrecord: any;
    isLoading = false;
    galleryBuget: any;
    description: any;
    date: any = new Date();
    apiPath: any;
    imagePhotoName: any;

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private imageGalleryService: ImageGalleryService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService, private route: ActivatedRoute) {

        this.id = this.route.snapshot.paramMap.get('id');
        this.apiPath = this.service.Settings.Base_API_URL;

    }



    ngOnInit() {
        this.Creategalleryform();
        this.loadDocumentTypeService();
        this.loadGalleryId();
    }

    loadGalleryId() {
        console.log(this.id);
        this.isLoading = true;
        this.imageGalleryService.getGallery(this.id).then(Gallerydata => {
            this.imageUrl = this.apiPath + "/Documents/appImages/" + Gallerydata.galleryPhoto;
            this.galleryrecord = Gallerydata;
            // this.imagePhotoName= this.apiPath +"Documents/appImages/"+ Gallerydata.galleryPhoto;
            // this.imageUrl = Gallerydata.galleryPhoto;
            this.imagegalleryform.patchValue({ description: Gallerydata.description });
            this.imagegalleryform.patchValue({ galleryBuget: Gallerydata.galleryBuget });
            this.imagegalleryform.patchValue({ documentTypeId: Gallerydata.documentTypeId });
            this.isLoading = false;
        });

    }

    loadDocumentTypeService() {
        this.documentTypeService.getAlldocument().then(documentTypelist => {
            this.documentTypelist = documentTypelist;

        });
    }
    onSubmit() {
        this.userSubmitted = true;
        let obj = this.imagegalleryform.getRawValue();
        console.log(obj.imageGalleryId, "obj.imageGalleryId");
        console.log(obj, "objedit");
        console.log(JSON.stringify(this.gallery));
        console.log(this.galleryrecord, "this.galleryrecord");
        obj.imageGalleryId = this.galleryrecord.imageGalleryId == null ? 0 : this.galleryrecord.imageGalleryId;
        obj.galleryPhoto = this.imageUrl ? this.imageUrl : obj.galleryPhoto;
        console.log(JSON.stringify(obj));
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
        // obj.createdDate=formattedDate;
        obj.Extra1 = this.fileName;
        console.log("Update");
        console.log(JSON.stringify(obj));
        console.log(obj.imageGalleryId, "obj.imageGalleryId");
        if (obj.imageGalleryId) {
            obj.updatedDate = formattedDate;
            obj.createdDate = formattedDate;
            obj.createdby = this.service.Account.UserID;
            obj.updatedby = this.service.Account.UserID;
            obj.isActive = true;
            obj.status = true;
            console.log("Update");
            let res = this.imageGalleryService.updateGallery(this.convertKeysToCamelCase(obj));
            if (res) {
                this.imagegalleryform.reset();
                this.alertify.success("Data Updated Successfully");
                this.router.navigate(['/ImageGalleryList']);
            }
        }

        this.userSubmitted = false;

    }
    // onSubmit() {
    //     this.userSubmitted = true;
    //     let obj = this.videogalleryform.getRawValue();
    //     console.log(JSON.stringify(this.gallery));
    //     console.log("Update");
    //     console.log(obj,"objbeforeupdateapicall");
    //     console.log(obj.videoGalleryId,"objidbeforeupdateapicall");
    //     if (obj.videoGalleryId) {
    //         console.log("Update");
    //         let res = this.videogalleryService.updateGallery(this.convertKeysToCamelCase(obj));
    //         if (res) {
    //             this.loadDocumentTypeService(); 
    //             this.videogalleryform.reset();
    //             this.router.navigate(['/Videogallerylist']);
    //             this.alertify.success("Data Updated Successfully");
    //         }
    //     }

    //     this.userSubmitted = false;

    // }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }


    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Creategalleryform() {
        this.imagegalleryform = this.fb.group({
            imageGalleryId: 0,
            galleryBuget: ['', Validators.required],
            documentTypeId: ['', Validators.required],
            galleryPhoto: ['', Validators.required],
            description: ['', Validators.required],
            createdby: [''],
            createdDate: [''],
            updatedby: [''],
            updatedDate: [''],
            extra1: [''],
            extra2: [''],
            extra3: [''],
            extra4: [''],
            extra5: [''],
            extra6: [''],
            extra7: [''],
            extra8: [''],
            extra9: [''],
            extra10: [''],
            isActive: [true],
            status: [true],
            typeDetailId: [''],
            blogDetailId: [''],
            newsDetailId: [''],
            eventDetailsId: [''],
        });
    }


    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl, "imageurlimagecom");
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }



    cancel() {
        this.imagegalleryform.reset();
        this.router.navigate(['/ImageGalleryList']);
    }
}

