export class Documenttype {
    DocumentTypeId: number;
    DocumentName: string;
    DocumentDescp: string;
    Createdby: string;
    CreatedDate: string;
    Updatedby: string;
    UpdatedDate: string;
    Description: string;
    Extra1: string;
    Extra2: string;
    Extra3: string;
    Extra4: string;
    Extra5: string;
    Extra6: string;
    Extra7: string;
    Extra8: string;
    Extra9: string;
    Extra10: string;
    isActive: boolean;
    Status: string;
}
