import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { Banner } from '../../model/banner';
import { BannerService } from '../../services/banner.service';


@Component({
    selector: 'app-banner-edits',
    templateUrl: './banner-edits.component.html',
    styleUrls: ['./banner-edits.component.scss'],
    providers: [DatePipe],
})
export class BannerEditsComponent implements OnInit {
    bannerform: FormGroup;
    banner: Banner; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;id:any;
    isLoading: boolean = false;
    documentTypeId: any;
    typeId: any;
    bannerrecord: any;
    typeIds: any;
    apiPath:any;
    documentTypeIds: any;
    bannerPhotoName: any;

    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private bannerService: BannerService,
        private documentTypeService: DocumentTypeService,private route: ActivatedRoute,
        public service: SystemService) {  this.id = this.route.snapshot.paramMap.get('id');       this.apiPath = this.service.Settings.Base_API_URL;}



    ngOnInit() {
        this.Createbannerform();
        this.loadDocumentTypeService();
        this.loadTypeService();
        this.loadbannerId();
    }

    loadbannerId() {
        this.isLoading =true;
        console.log(this.id);
        this.isLoading = true;
        this.bannerService.getBanner(Number(this.id)).then(bannerdata => {
            console.log(JSON.stringify(bannerdata));
            this.bannerrecord = bannerdata;
            this.bannerform.patchValue(bannerdata);
            this.typeIds = bannerdata.typeId;
            this.documentTypeIds = bannerdata.documentTypeId;
            //**************************************PatchValue */
            this.typeId = this.typeIds;
            this.documentTypeId = this.documentTypeIds;
            this.imageUrl = this.apiPath +"/Documents/appImages/"+ bannerdata.bannerPhoto;
            this.bannerPhotoName= this.apiPath +"/Documents/appImages/"+ bannerdata.bannerPhoto;
            this.isLoading = false;
        });
        this.isLoading =false;

    }
    loadDocumentTypeService() {
        this.documentTypeService.getAlldocument().then(documentTypelist => {
            this.documentTypelist = documentTypelist;

            console.log(JSON.stringify(this.documentTypelist));
        });
    }

    loadTypeService() {
        this.typeService.getAllTypelist().then(typelist => {
            this.typelist = typelist;
            console.log(JSON.stringify(this.typelist));
        });

    }


    onSubmit() {
        this.isLoading = true;
        this.userSubmitted = true;
        let obj = this.bannerform.getRawValue();
        console.log(JSON.stringify(this.banner));
        obj.bannerId = this.bannerrecord.bannerId == null ? 0 : this.bannerrecord.bannerId;
        obj.bannerPhoto = this.imageUrl ? this.imageUrl : obj.bannerPhoto;
        console.log(JSON.stringify(obj));
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
        obj.Extra1 = this.fileName;
        console.log("Update");
        console.log(JSON.stringify(obj));
        if (obj.bannerId) {
            obj.UpdatedDate =formattedDate;
            obj.Updatedby = this.service.Account.UserID;
            obj.UpdatedDate = formattedDate;
            obj.isActive = true;
            obj.status = true;
            console.log("Update");
            let res = this.bannerService.updateBanner(this.convertKeysToCamelCase(obj));
            if (res) {
                this.isLoading =false;
                this.bannerform.reset();
                this.alertify.success("Data Updated Successfully");
                this.router.navigate(['/bannerlist']);
            }
        }
        this.isLoading =false;
        this.userSubmitted = false;

    }

    onMaterialGroupChange(event) {
        //console.log(event);
    }


    Createbannerform() {
        this.bannerform = this.fb.group({
            BannerId: [null],
            TypeId: [null, Validators.required],
            DocumentTypeId: [null, Validators.required],
            BannerPhoto: [null, Validators.required],
            BannerBuget: [null],
            Createdby: [null],
            CreatedDate: [null],
            Updatedby: [null],
            UpdatedDate: [null],
            Description: [null],
            Extra1: [null],
            Extra2: [null],
            Extra3: [null],
            Extra4: [null],
            Extra5: [null],
            Extra6: [null],
            Extra7: [null],
            Extra8: [null],
            Extra9: [null],
            Extra10: [null],
            isActive: [true],
            Status: [null],
        });
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }

    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    cancel() {
        this.bannerform.reset();
        this.router.navigate(['/bannerlist']);
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

}

