

<table class="table table-striped table-bordered">
  <thead class="thead-dark">
    <tr>
 
      <th scope="col">Content Icon</th>
      <th scope="col">Content Type</th>
      <th scope="col">Content File Name</th>
      <th scope="col">Acion</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let menucontent of menucontent">
     
      <td>{{ menucontent.contentIcon }}</td>
      <td>{{ menucontent.contentType }}</td>
      <td>{{ menucontent.contentFileName }}</td>
      <td>
        <button class="btn btn-warning btn-sm" (click)="onEdit(menucontent)">Edit</button>
        <button class="btn btn-danger btn-sm" (click)="onDelete(menucontent.contentId)">Delete</button>
      </td>
    </tr>
  </tbody>
</table>