import { Component, ElementRef, Renderer2, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';
//import { MenuService } from '../../services/menu.service';
import { MenuContentService } from '../../services/menucontent.service';
import { MenuContent } from '../../model/MenuContent';
//import { SubMenu } from '../../model/submenu';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { SubMenuService } from '../../services/submenu.service';

@Component({
    selector: 'app-menu-content-form',
    templateUrl: './menu-content.html',
    styleUrls: ['./menu-content.css'],
})
export class MenuContentFormComponent implements OnInit {
    MenuContentForm: FormGroup;
    // menu: any;

    imageUrl: string;
    gallery: MenuContent; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    // @Output() CategoryDeleted = new EventEmitter<void>();
    menuParentIds: any;
    MenuList: any
    @ViewChild('blogContainer2') blogContainer: ElementRef; // Use 'blogContainer2' instead of 'blogContainer'
    rowData: any;
    gridApi: any;
    selectedMenuId: number;
    selectedMenuContent: MenuContent | null = null;
    //submenu: any;
    menucontent: any;
    selectedMenparentuId: number;
    Dropdown: any;
    fileName: any;
    isLoading: boolean = false;
    userSubmitted: boolean;
    apiPath: any;


    constructor(
        private fb: FormBuilder,
        private renderer: Renderer2,
        public service: SystemService,

        private alertify: AlertifyService,
        private menucontentService: MenuContentService,
        private submenuService: SubMenuService,
    ) {

        // this.loadMainMenu();
        this.loadsubmenugrid();
        this.loaddropdown();
        this.apiPath = this.service.Settings.Base_API_URL;
    }

    ngOnInit() {
        console.log('blogContainer:', this.blogContainer); // Add this line to check the value
        this.MenuContentForm = this.fb.group({
            contentId: 0,
            menuId: 0,
            contentIcon: '',
            contentType: '',
            contentFileName: '',
            createdDate: '',
            extra1: '',
            extra2: '',
            extra3: '',
            extra4: '',
            extra5: '',
            active: true

        });
        //this.loadMainMenu();
        //this.loaddropdown();
    }
    refreshGridData(): void {
        this.loadsubmenugrid();
        // this.loadMainMenu();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }
    onMaterialGroupChange(event) {
        this.menuParentIds = event;
    }



    async onSubmit() {
        if (this.MenuContentForm.valid) {
            const menucontent: MenuContent = this.MenuContentForm.value;
            menucontent.menuId = this.selectedMenuId;
            let obj = this.MenuContentForm.getRawValue();
            obj.extra1 = this.imageUrl ? this.imageUrl : obj.extra1;
            if (obj.contentId) {
                console.log("Update existing Content");
                try {
                    obj.active = true;
                    let res = await this.menucontentService.UpdateMenuContent(obj);
                    console.log(JSON.stringify(res));
                    this.alertify.success("Updated Successfully");
                    this.refreshGridData();
                    this.MenuContentForm.reset();
                } catch (error) {
                    console.error('Error updating subcategory:', error);
                }
            } else {
                console.log("Add new subcategory");
                try {
                    obj.active = true;
                    obj.contentId = 0;
                    const currentDate = new Date();
                    const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
                    obj.createdDate = formattedDate;
                    let res = await this.menucontentService.AddMenuContent(obj);
                    this.alertify.success("Created Successfully");
                    this.MenuContentForm.reset();
                    console.log(JSON.stringify(res));
                    this.refreshGridData();

                } catch (error) {
                    console.error('Error adding subcategory:', error);
                }
            }
            this.MenuContentForm.reset();
            this.selectedMenuContent = null;
            this, this.imageUrl = null;
        }
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }


    loaddropdown() {
        this.submenuService.getDropdown().then(dropdown => {
            console.log(JSON.stringify(dropdown));
            this.Dropdown = dropdown;
        });
    }


    loadsubmenugrid() {
        this.service.App.ShowLoader = true;
        this.menucontentService.getAllMenuContent().then(menucontent => {
            this.menucontent = menucontent;
            this.rowData = menucontent;
            console.log(JSON.stringify(this.menucontent));
            this.service.App.ShowLoader = false;
        }).catch(error => {
            console.error('Error fetching submenus:', error);
            this.service.App.ShowLoader = false; // Ensure loader is hidden even in case of an error.
        });
    }




    onEdit(menucontent: MenuContent) {
        this.imageUrl = this.apiPath + "/Documents/appImages/" + menucontent.extra1;
        this.MenuContentForm.patchValue({ extra2: menucontent.extra2 });
        this.MenuContentForm.patchValue(menucontent);
        this.scrollIntoViewEdit();
    }
    
    scrollIntoViewEdit(): void {
        const gridElement = document.querySelector('.blog-details-area') as HTMLElement;
        if (gridElement) {
            gridElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    blured = false
    focused = false

    created(event) {
        // tslint:disable-next-line:no-console
        console.log('editor-created', event)
    }

    changedEditor(event: EditorChangeContent | EditorChangeSelection) {
        // tslint:disable-next-line:no-console
        console.log('editor-change', event)
    }

    focus($event) {
        // tslint:disable-next-line:no-console
        console.log('focus', $event)
        this.focused = true
        this.blured = false
    }

    blur($event) {
        // tslint:disable-next-line:no-console
        console.log('blur', $event)
        this.focused = false
        this.blured = true
    }

    onDelete(contentId: number) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            this.menucontentService.DeleteMenuContent(contentId).then(() => {
                this.alertify.success("Deleted Successfully");
                this.loadsubmenugrid();  // Refresh the grid after deletion
            }).catch(error => {
                console.error('Error deleting submenu:', error);
                this.alertify.error("Error deleting data");
            }).finally(() => {
                this.service.App.ShowLoader = false;
            });
        } else {
            console.log('Deletion canceled.');
        }

    }
}
export { MenuContent };
