import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { SystemService } from '../../services/system.service';
import { Documenttype } from '../documenttype/documenttype';
import { Response, ResponseFull } from '../../model/response';
import { responseService } from '../../services/response.service';

@Component({
    selector: 'app-response-list',
    templateUrl: './response-list.component.html',
    styleUrls: ['./response-list.component.scss'],
    providers: [DatePipe],
})
export class responseListComponent implements OnInit {
    typeform: FormGroup;
    type: ResponseFull; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    typeItems: any;
    isLoading = false;

    constructor(private fb: FormBuilder,
        private router: Router, private alertify: AlertifyService,
        private responseService: responseService,
        public service: SystemService) { }

    ngOnInit() {
        this.binddata();
        this.refreshGridData();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'ResponseId', sortable: true, filter: true, width: 20, headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true},
        // { headerName: 'Id', field: 'id', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'ResponseLabel', field: 'ResponseLabel', sortable: true, filter: true, editable: true, width: 500 },
        { headerName: 'ResponseValue', field: 'ResponseValue', sortable: true, filter: true, editable: true, width: 500 },
        { headerName: 'QuestionnaireName', field: 'QuestionnaireName', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'UserEmail', field: 'UserEmail', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'Type', field: 'TypeDetailTypeTitle', sortable: true, filter: true, editable: true, width: 100 },
        { headerName: 'UserName', field: 'UserName', sortable: true, filter: true, editable: true, width: 100 },



        // Add other fields as needed
        // {
        //     headerName: 'Actions',
        //     cellRenderer: this.actionsCellRenderer.bind(this),
        //     sortable: false,
        //     filter: false,
        //     width: 120
        // },
    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    imageCellRenderer(params: any) {
        return `<img src="${params.value}" alt="Image" style="max-width: 50px; max-height: 50px;">`;
    }
    // actionsCellRenderer(params: any) {
    //     const addButton = document.createElement('button');
    //     addButton.innerHTML = 'Add';
    //     addButton.classList.add('Actions-btn'); // Apply the CSS class
    //     addButton.addEventListener('click', () => this.addRow());

    //     const editButton = document.createElement('button');
    //     editButton.innerHTML = 'Edit';
    //     editButton.classList.add('Actions-btn'); // Apply the CSS class
    //     editButton.addEventListener('click', () => this.editRow(params.data));

    //     const deleteButton = document.createElement('button');
    //     deleteButton.innerHTML = 'Delete';
    //     deleteButton.classList.add('Actions-btn'); // Apply the CSS class
    //     deleteButton.addEventListener('click', () => this.deleteRow(params.data));

    //     const AddQButton = document.createElement('button');
    //     AddQButton.innerHTML = 'Questions';
    //     AddQButton.classList.add('Actions-btn'); // Apply the CSS class
    //     AddQButton.addEventListener('click', () => this.questionsRow(params.data));


    //     const wrapper = document.createElement('div');
    //     wrapper.appendChild(AddQButton);
    //     wrapper.appendChild(editButton);
    //     wrapper.appendChild(addButton);
    //     wrapper.appendChild(deleteButton);

    //     return wrapper;
    // }
    refreshGridData(): void {
        this.binddata();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
     }


    questionsRow(rowData: any)
    {
        console.log('Edit row:', rowData);
        let id = rowData.id;
        this.router.navigate(['/questionslistSub', id]);
    }



    onSelectionChanged(event: any) {
        // Get the selected rows using the grid API
        const selectedRows = this.gridApi.getSelectedRows();
        if (selectedRows.length > 0) {
            // Row(s) are selected, you can access the data of the selected row(s)
            console.log('Row(s) selected:', selectedRows);
            // If you want to work with a single selected row, you can use selectedRows[0]
            const firstSelectedRow = selectedRows[0];
            console.log('First selected row:', firstSelectedRow);
            // Now, you can access properties of the selected row(s) as needed
            // For example, to navigate to a route with the ID of the first selected row
            this.router.navigate(['/blog-details-user-view', firstSelectedRow.postId]);
        } else {
            // No rows are selected
            console.log('No rows selected');
        }
    }

    binddata() {
        this.service.App.ShowLoader = true;
        this.responseService.getAllResponse().then(data => {
            this.rowData = data;
            // this.alertify.success("Data Loaded Successfully");
        });
        this.service.App.ShowLoader = false;
    }




}

