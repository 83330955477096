<app-navbar-style-admin></app-navbar-style-admin>
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create choice</h4>
                        </header>



                        <article class="card-body">
                            <p class="card-header mb-5 " style="color:white;font-weight: bold;background: var(--mainColor);border-radius:100px;"> Below add Choices for the question</p>
                            <form [formGroup]="choiceForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label for="Label" tooltip="Enter Label Name here">Label Name:</label>
                                    <input type="text" id="Label" class="form-control" formControlName="Label" required>
                                    <div *ngIf="choiceForm.get('Label')?.hasError('required') && choiceForm.get('Label')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Value" tooltip="Enter Value here">Value:</label>
                                    <input type="text" id="Value" class="form-control" formControlName="Value" required>
                                    <div *ngIf="choiceForm.get('Value')?.hasError('required') && choiceForm.get('Value')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Selected">Selected:</label>
                                    <input type="checkbox" formControlName="selected">
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success btn-block">Save</button>
                                    <button (click)="cancel()" class="btn btn-secondary btn-block"> Cancel </button>
                                </div>
                            </form>
                            <div class="row" *ngIf="choicesdata">
                                <div class="col-md-6">
                                    <article class="card-body">
                                        <div class="form-group">
                                            <p class="card-header  " style="color:white;font-weight: bold;background: var(--mainColor);border-radius:100px;">QuestionType: {{ choicesdata.QuestionType }}</p>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-md-6">
                                    <article class="card-body">
                                        <div class="form-group">

                                            <p class="card-header  " style="color:white;font-weight: bold;background: var(--mainColor);border-radius:100px;">Question: {{ choicesdata.Headline }}</p>

                                        </div>
                                    </article>
                                </div>
                            </div>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>

</div>


<div class="blog-details-area pt-50 pb-70">

    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <!-- <header class="card-header" style="background: var(--mainColor);">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create choice</h4>
                        </header> -->
                        <article class="card-body">
                            <!-- Grid section -->
                            <table class="table table-striped table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">Label</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Selected</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of choices">
                                        <td>{{ item.label }}</td>
                                        <td>{{ item.value }}</td>
                                        <td>{{ item.selected }}</td>
                                        <td>
                                            <button class="btn btn-warning btn-sm" (click)="onEdit(item)">Edit</button>
                                            <button class="btn btn-danger btn-sm"
                                                (click)="onDelete(item.id)">Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>