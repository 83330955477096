
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterHeaderModel } from '../../model/filterHeader';
import { FilterHeaderService } from '../../services/filterHeader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filterHeaderSearchModel } from './filterHeader_listSearch';
import { FilterHeaderSearchService } from '../../services/filterHeaderSearch.service';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';
@Component({
    selector: 'app-filterHeader_editSearch-form',
    templateUrl: './filterHeader_editSearch.html',
    styleUrls: ['./filterHeader_editSearch.css'],
})
export class FilterHeaderEditSearchformComponent implements OnInit {
    filterHeader: filterHeaderSearchModel;
    filterHeaderSearchForm: FormGroup;
    allfilterheaderAllData: any;
    allfilterheaderNames: any;
    allfilterheaderValues: any;
    allfilterheaderTypes: any;
    selectedfilterName: any;
    selectedfilterValue: any;
    selectedfilterType: any;
    id: string;
    isLoading: boolean = false;

    constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute,private alertify: AlertifyService,
        public service: SystemService, public filterHeaderSearchservices: FilterHeaderSearchService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.FilterHeaderSearchForm();
        this.loadfilterHeaderNames();
        this.loadfilterHeaderValues();
        this.loadfilterHeaderTypes();
    }

    ngOnInit() {

        this.loadFilterHeaderSearchById();
    }

    FilterHeaderSearchForm() {
        this.filterHeaderSearchForm = this.fb.group({
            searchId: 0,
            filterNameId: ['', Validators.required],
            filterValueId: ['', Validators.required],
            typeId: ['', Validators.required],
            // description: ['']
        });
    }

    onMaterialGroupChange(event: any) {

    }





    loadFilterHeaderSearchById() {
        this.isLoading = true;

        this.filterHeaderSearchservices.getFilterHeaderSearchById(Number(this.id)).then(allfilterheaderAllData => {
            this.allfilterheaderAllData = allfilterheaderAllData;
            this.filterHeaderSearchForm.patchValue(allfilterheaderAllData);
            this.selectedfilterValue = allfilterheaderAllData.filterValueId;
            this.selectedfilterType = allfilterheaderAllData.typeId;
            console.log(this.selectedfilterType);
            console.log("RRR---"+JSON.stringify(this.allfilterheaderAllData));
        });
        this.isLoading = false;
    }

    loadfilterHeaderNames() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderName().then(allfilterheaderNames => {
            this.allfilterheaderNames = allfilterheaderNames;
            console.log("Select Name:---"+JSON.stringify(this.allfilterheaderNames));
        });
        this.service.App.ShowLoader = false;
    }

    loadfilterHeaderValues() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderValue().then(allfilterheaderValues => {
            this.allfilterheaderValues = allfilterheaderValues;
            console.log("Select Value:---"+JSON.stringify(this.allfilterheaderValues));
            const allfilterheaderValue: UpdatedData[] = this.allfilterheaderValues.map(item => {
                return {
                  filterValueId: item.filterHeaderValueId,
                  firstName: item.firstName,
                  filterValue: item.filterValue
                };
              });
              this.allfilterheaderValues = allfilterheaderValue;
              console.log("Select Value:---"+JSON.stringify(this.allfilterheaderValues));
        });
        this.service.App.ShowLoader = false;
    }

    loadfilterHeaderTypes() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderType().then(allfilterheaderTypes => {
            this.allfilterheaderTypes = allfilterheaderTypes;
            console.log("Select Type:---"+JSON.stringify(this.allfilterheaderTypes));
        });
        this.service.App.ShowLoader = false;
    }


    async onSubmit() {

        if (this.filterHeaderSearchForm.valid) {
            let obj = this.filterHeaderSearchForm.getRawValue();
            console.log(obj,"obj edit submit");
            obj.filterNameId = obj.filterNameId;
            obj.filterValueId = obj.filterValueId;
            obj.typeId = obj.typeId;
            let res = this.filterHeaderSearchservices.updateFilterHeaderSearch(this.convertKeysToCamelCase(obj));
            console.log(res,"res after edit")
            if (res) {
                this.alertify.success("Data Updated Successfully");
                this.router.navigate(['/filterHeaderlistSearch']);
            }

            // Reset the form
            this.filterHeaderSearchForm.reset();
            //this.selectedCategoryId = null;
        }
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
}
interface UpdatedData {
    filterValueId: number;
    firstName: string;
    filterValue: string;
  }

export { filterHeaderSearchModel };
