// category.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubMenu } from '../model/submenu';
import { SystemService } from './system.service';


@Injectable({
    providedIn: 'root',
})
export class SubMenuService {
    apiUrl: any;
    BearerToken: any;
    menus: SubMenu[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllMenus(): Promise<SubMenu[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<SubMenu[]>("MenuMaster");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching categories:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }
    async getDropdown(): Promise<SubMenu[]> {
        try {
            return await this.service.Data.ExecuteAPI_Get<SubMenu[]>("MenuMaster/GetAllMenuMasterslist");
        } catch (error) {
            // Handle errors here
            console.error('Error fetching categories:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }


    getMenuByIds(id: number): Observable<SubMenu> {
        return this.http.get<SubMenu>(`${this.apiUrl}/api/MenuMaster/${id}`);
    }

    async addMenus(submenu: SubMenu) {
        let res = await this.service.Data.ExecuteAPI_Post("MenuMaster", submenu);
        return res;
    }

    


    async updateMenus(submenu: SubMenu): Promise<Observable<SubMenu>> {
        let res = await this.service.Data.ExecuteAPI_Put("MenuMaster", `${submenu.menuId}?menuid=${submenu.menuId}`, submenu);
        return res;
    }

    async deleteMenus(id: number) {
        let res = await this.service.Data.ExecuteAPI_Delete("MenuMaster", `${id}?menuid=${id}`);
        return res;
    }
}
