
import { Component, OnInit } from '@angular/core';
import { FilterHeaderService } from '../../services/filterHeader.service';
import { Router } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { filterHeaderSearchModel } from '../../model/filterHeaderSearch';
import { FilterHeaderSearchService } from '../../services/filterHeaderSearch.service';
import { AlertifyService } from '../../services/alertify.service';

@Component({
    selector: 'app-filterHeader_listSearch-form',
    templateUrl: './filterHeader_listSearch.html',
    styleUrls: ['./filterHeader_listSearch.css'],
})
export class FilterHeaderListSearchformComponent implements OnInit {

    filterHeader: filterHeaderSearchModel;
    isLoading = false;
    allfilterheaderAllData: any;
    allfilterheaderNames: any;
    allfilterheaderValues: any;
    allfilterheaderTypes: any;

    constructor(private router: Router,
        public service: SystemService, public filterHeaderSearchservices: FilterHeaderSearchService, private alertify: AlertifyService) { }


    ngOnInit() {
        this.refreshGridData();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'searchId', sortable: true, filter: true, width: 20 },
        { headerName: 'Filter Name', field: 'filterHeaderName', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'Filter Value', field: 'filterValue', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Type', field: 'typeTitle', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Date', field: 'CreatedDate', sortable: true, filter: true, editable: true, width: 200 },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    refreshGridData(): void {
        this.loadfilterHeaderAllData();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }
    actionsCellRenderer(params: any) {
        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        wrapper.appendChild(deleteButton);
        return wrapper;
    }

    addRow() {
        this.router.navigate(['/filterHeaderaddSearch']);
    }
    editRow(rowData: any) {
        // Implement your edit logic here
        console.log('Edit row:', rowData);
        let id = rowData.searchId;
        this.router.navigate(['/filterHeadereditSearch', id]);

    }
    deleteRow(rowData: any) {
        console.log(rowData);
        const userConfirmed = confirm('Are you sure you want to delete?');
        if (userConfirmed) {
            let res = this.filterHeaderSearchservices.deleteFilterHeaderSearch(rowData.searchId);
            console.log(JSON.stringify(res));
            this.loadfilterHeaderAllData();
            this.refreshGridData();
            this.alertify.success("Deleted Successfully");
        } else {

        }

    }

    loadfilterHeaderAllData() {
        this.service.App.ShowLoader = true;
        this.filterHeaderSearchservices.getAllFilterHeaderAllData().then(allfilterheaderAllData => {
            this.allfilterheaderAllData = allfilterheaderAllData;
            this.rowData = allfilterheaderAllData;
            console.log(JSON.stringify(this.allfilterheaderAllData));
        });
        this.service.App.ShowLoader = false;
    }


    addFilterHeader() {
        this.router.navigate(['/filterHeaderaddSearch']);
    }


}


export { filterHeaderSearchModel };

