<app-navbar-style-admin></app-navbar-style-admin>
<div class="inner-banner inner-bg4">
    <div class="container">
        <!-- <div class="inner-banner-title text-center">
            <h3>{{post.title}}</h3>
           <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p>
        </div> -->

        <!-- <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                         <li><i class='bx bx-chevron-right'></i></li>
                        <li>Pages</li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Blog Details</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="blog-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header headerbar" style="border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create Your FAQ</h4>
                        </header>
                        <div *ngIf="isLoading" class="overlay-inner">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                                </div>
                            </div>
                        </div>
                        <article class="card-body">
                            <form [formGroup]="faqform" (ngSubmit)="faqform.valid && onSubmit()" #f="ngForm"
                                novalidate>
                                <div class="form-box row">
                                    <div class="form-group">
                                        <label>FAQ Question</label>
                                        <input type="text" id="faqQuestions" class="form-control"
                                            formControlName="faqQuestions">
                                        <div *ngIf="faqform.get('faqQuestions')?.touched && faqform.get('faqQuestions') && faqform.get('faqQuestions').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>FAQ Answers</label>
                                        <input type="text" id="faqAnswer" class="form-control"
                                            formControlName="faqAnswer">
                                        <div *ngIf="faqform.get('faqAnswer')?.touched && faqform.get('faqAnswer') && faqform.get('faqAnswer').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>FAQ Language</label>
                                        <input type="text" id="faqLanguage" class="form-control"
                                            formControlName="faqLanguage">
                                        <div *ngIf="faqform.get('faqLanguage')?.touched && faqform.get('faqLanguage') && faqform.get('faqLanguage').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </div>
                                    </div>
                                    
                                    <div class="form-group">
                                        <button [disabled]="faqform.invalid" type="submit"
                                            class="btn btn-success btn-block"> Save </button>
                                    </div>
                                    <div class="form-group">
                                        <button (click)="cancel()" class="btn btn-secondary btn-block"> Cancel </button>
                                    </div>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
