<app-navbar-style-admin></app-navbar-style-admin>

<div class="blog-details-area pt-50 pb-0" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
              <h4 class="card-title mt-2 text-center" style="color:white">Create Menu Content</h4>
            </header>
            <article class="card-body">
              <form [formGroup]="MenuContentForm" (ngSubmit)="onSubmit()">

                <div class="form-group">
                  <label>Select Main Menu:</label>
                  <ng-select formControlName="menuId" #myselect (change)="onMaterialGroupChange($event)"
                    [(ngModel)]="selectedMenuId" required>
                    <ng-option *ngFor="let obj of Dropdown" [value]="obj.menuId"
                      class="form-control">{{obj.menuName}}</ng-option>
                  </ng-select>
                  <div
                    *ngIf="MenuContentForm.get('menuId')?.hasError('required') && MenuContentForm.get('menuId')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>



                <div class="form-group">
                  <label for="contentIcon">Content Icon:</label>
                  <input type="text" id="contentIcon" class="form-control" formControlName="contentIcon" required>
                  <div
                    *ngIf="MenuContentForm.get('contentIcon')?.hasError('required') && MenuContentForm.get('contentIcon')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>


                <div class="form-group">
                  <label for="contentType">Content Type :</label>
                  <input type="text" id="contentType" class="form-control" formControlName="contentType" required>
                  <div
                    *ngIf="MenuContentForm.get('contentType')?.hasError('required') && MenuContentForm.get('contentType')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>


                <div class="form-group">
                  <label for="contentFileName">Content File Name:</label>
                  <input type="text" id="contentFileName" class="form-control" formControlName="contentFileName" required>
                  <div
                    *ngIf="MenuContentForm.get('contentFileName')?.hasError('required') && MenuContentForm.get('contentFileName')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>

                <div class="form-group">
                  <label for="extra2">File Format:</label>
                  <input type="text" id="extra2" class="form-control" formControlName="extra2" required>
                  <div
                    *ngIf="MenuContentForm.get('extra2')?.hasError('required') && MenuContentForm.get('extra2')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>

                <div class="form-group">
                  <label>Upload Image:</label>
                  <input type="file" (change)="onFileSelect($event)" class="btn btn-info btn-sm mt-2"
                    formControlName="extra1">
                  <img *ngIf="imageUrl" class="imageUpload" [src]="imageUrl" />
                  <div
                    *ngIf="MenuContentForm.get('extra1')?.hasError('extra1') && MenuContentForm.get('extra1')?.touched"
                    class="error-block">
                    This field is required.
                  </div>
                </div>


               

                <div class="form-group">
                  <button type="submit" class="btn btn-success btn-block"
                    [disabled]="!MenuContentForm.valid">Save</button>
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="blog-details-area pt-50 pb-70" #blogContainer2>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="blog-article">
          <div class="card loginCard">
            <article class="card-body">
              <!-- Grid section -->
              <app-menu-content-grid [menucontent]="menucontent" (editMenuContent)="onEdit($event)"
                (deleteMenuContent)="onDelete($event)"></app-menu-content-grid>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-style-two></app-footer-style-two>