

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuContent } from '../../model/MenuContent';



@Component({
  selector: 'app-menu-content-grid',
  templateUrl: './menu-content-grid.html',
  styleUrls: ['./menu-content-grid.css'],
})
export class MenuContentGridComponent implements OnInit {
  @Input() menucontent: MenuContent[] = [];
  @Output() editMenuContent = new EventEmitter<MenuContent>();
  @Output() deleteMenuContent = new EventEmitter<number>();
  isFirst: any;


  ngOnInit() { console.log(this.menucontent); }

  onEdit(menucontent: MenuContent) {
    console.log(menucontent);
    this.editMenuContent.emit(menucontent);
  }

  onDelete(contentId: number) {
    this.deleteMenuContent.emit(contentId);
  }
}
