
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterHeaderModel } from '../../model/filterHeader';
import { FilterHeaderService } from '../../services/filterHeader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterHeaderValueService } from '../../services/filterHeaderValue.service'
import { AlertifyService } from '../../services/alertify.service';

@Component({
    selector: 'app-filterHeader_editValue-form',
    templateUrl: './filterHeader_editValue.html',
    styleUrls: ['./filterHeader_editValue.css'],
})
export class FilterHeaderEditValueformComponent implements OnInit {
    filterHeaderValueForm: FormGroup;
    FilterHeader: any;
    selectedCategoryId: FilterHeaderModel | null = null;
    selectedfilterName: any;
    filterNameId: any;
    id: string;

    constructor(private fb: FormBuilder, private router: Router, private FilterHeaderValueService: FilterHeaderValueService, private FilterHeaderModelService: FilterHeaderService, private route: ActivatedRoute, private alertify: AlertifyService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.loadFilterHeaderNames();
        this.loadFilterHeaderId();

    }

    ngOnInit() {
        this.filterHeaderValueForm = this.fb.group({
            filterHedaerValueId: 0,
            filterName: ['', Validators.required],
            filterValue: ['', Validators.required],

        });
    }

    Back() {
        this.router.navigate['/filterHeaderlistValue']
    }

    // loadFilterHeaderId() {
    //     this.FilterHeaderValueService.getFilterHeaderValueById(Number(this.id)).then(FilterHeader => {
    //         this.FilterHeader = FilterHeader;
    //         this.filterHeaderValueForm.patchValue(FilterHeader);
    //         this.filterNameId = FilterHeader.filterName;
    //         console.log(JSON.stringify(this.FilterHeader));
    //     });
    // }

    // loadFilterHeaderNames() {
    //     this.FilterHeaderModelService.getAllFilterHeader().then(FilterHeader => {
    //         this.FilterHeader = FilterHeader;
    //         console.log(JSON.stringify(this.FilterHeader));
    //     });
    // }
    loadFilterHeaderNames() {
        this.FilterHeaderModelService.getAllFilterHeader().then(FilterHeader => {
            this.FilterHeader = FilterHeader;
            console.log(this.FilterHeader, '.filterheeader0');
            console.log(JSON.stringify(this.FilterHeader));
        });
    }


    loadFilterHeaderId() {
        this.FilterHeaderValueService.getFilterHeaderValueById(Number(this.id)).then(FilterHeader => {
            this.filterHeaderValueForm.patchValue(FilterHeader);
            let filterId = this.FilterHeader.filter(value => value.filterId == FilterHeader.filterName);
            this.filterNameId = filterId[0].filterHeaderName1;
            console.log(JSON.stringify(this.FilterHeader));
        });
    }

    async onSubmit() {
        if (this.filterHeaderValueForm.valid) {
            let obj = this.filterHeaderValueForm.getRawValue();
         
            console.log(JSON.stringify(obj));
            obj.filterName = obj.filterName.toString();
          
            let res = this.FilterHeaderValueService.updateFilterHeaderValue(this.convertKeysToCamelCase(obj));
          
            if (res) {
                this.alertify.success("Updated Successfully");
                this.router.navigate(['/filterHeaderlistValue']);
            }
            // Reset the form
            this.filterHeaderValueForm.reset();
            this.selectedCategoryId = null;
        }
    }

    onEdit(FilterHeaderModel: FilterHeaderModel) {
        // Load the selected FilterHeaderModel into the form for editing
        this.selectedCategoryId = FilterHeaderModel;
        this.filterHeaderValueForm.patchValue(FilterHeaderModel);
    }

    onDelete(FilterId: number) {
        let res = this.FilterHeaderValueService.deleteFilterHeaderValue(FilterId);
        if (res) {
            this.loadFilterHeaderNames(); // Refresh the FilterHeaderModel list
        }
    }
    onMaterialGroupChange(event: any) {

    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }
}


export { FilterHeaderModel };

