import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Comments } from '../model/comments';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';

@Injectable({
    providedIn: 'root'
})
export class AddCommentService {
    private apiPath: string;
    constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }

    async addComment(comment: Comments) {
        // return this.http.post(this.apiPath + '/api/Comment', comment);
        comment.comment_id = 0;
        let res = await this.service.Data.ExecuteAPI_Post("Comment", comment);
        return res;
    }


    async getComments(postId: number): Promise<Comment[]> {
        try {
            console.log(postId);
            return await this.service.Data.ExecuteAPI_Get<Comment[]>(`Comment/postComment/{id}?postId=${postId}`);
        } catch (error) {
            console.error('Error fetching Typelist:', error);
            return []; // or throw the error, depending on your error-handling strategy
        }
    }

    getUserCommentCount(username: string) {
        return this.http.get(this.apiPath + '/api/Comment/userComment/' + username);
    }

    updateComment(id: number, newComment: string) {
        return this.http.put(this.apiPath + '/api/Comment/edit/' + id, { Id: id, newComment: newComment });
    }

    deleteComment(id: number) {
        return this.http.delete(this.apiPath + '/api/Comment/delete/' + id);
    }

    deleteAllComment(postId: number) {
        return this.http.delete(this.apiPath + '/api/Comment/deleteAll/' + postId);
    }
}
export { Comments };

