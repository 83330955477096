<app-navbar-style-admin></app-navbar-style-admin>
<div class="inner-banner inner-bg4">
    <div class="container">
        <!-- <div class="inner-banner-title text-center">
            <h3>{{post.title}}</h3>
           <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p>
        </div> -->

        <!-- <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                         <li><i class='bx bx-chevron-right'></i></li>
                        <li>Pages</li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Blog Details</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="blog-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header headerbar" style="border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create Edit Gallery</h4>
                        </header>
                        <div *ngIf="isLoading" class="overlay-inner">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                                </div>
                            </div>
                        </div>
                        <article class="card-body">

                            <form [formGroup]="galleryform" (ngSubmit)="onSubmit()" #f="ngForm"
                                novalidate>
                                <div class="form-box row">
                                    <div class="form-group">
                                        <label>Select Type:</label>
                                        <ng-select formControlName="TypeId" #myselect [(ngModel)]="typeId"
                                            (change)="onMaterialGroupChange($event)">
                                            <ng-option *ngFor="let obj of typelist" [value]="obj.typeId"
                                                class="form-control">{{obj.typeTitle}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="galleryform.get('TypeId')?.hasError('required') && galleryform.get('TypeId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Select Sub DocumentType:</label>
                                        <ng-select formControlName="DocumentTypeId" #myselect
                                            [(ngModel)]="documentTypeId" (change)="onMaterialGroupChange($event)">
                                            <ng-option *ngFor="let obj of documentTypelist" [value]="obj.documentTypeId"
                                                class="form-control">{{obj.documentName}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="galleryform.get('DocumentTypeId')?.hasError('required') && galleryform.get('DocumentTypeId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Upload Banner:</label>
                                        <input type="file" (change)="onFileSelect($event)"
                                            class="btn btn-info btn-sm mt-2" formControlName="GalleryPhoto">
                                        <img *ngIf="imageUrl" class="imageUpload" [src]="imageUrl" />
                                        <div *ngIf="galleryform.get('GalleryPhoto')?.hasError('GalleryPhoto') && galleryform.get('GalleryPhoto')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button  type="submit"
                                            class="btn btn-success btn-block"> Update </button>
                                    </div>
                                    <div class="form-group">
                                        <button (click)="cancel()" class="btn btn-secondary btn-block"> Cancel </button>
                                    </div>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
