<app-navbar-style-admin></app-navbar-style-admin>
<div class="inner-banner inner-bg4">
    <div class="container">
        <!-- <div class="inner-banner-title text-center">
            <h3>{{post.title}}</h3>
           <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p>
        </div> -->

        <!-- <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                         <li><i class='bx bx-chevron-right'></i></li>
                        <li>Pages</li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">Blog Details</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="blog-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header headerbar" style="border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Modify Your Blog</h4>
                        </header>
                        <article class="card-body">
                            <form [formGroup]="addBlogForm" (ngSubmit)="addBlogForm.valid && onSubmit()" #f="ngForm"
                                novalidate>
                                <div class="form-box row">
                                    <div class="form-group">
                                        <label>Select category:</label>
                                        <!-- <ng-select [multiple]="true" [items]="categories" formControlName="category"
                                            class="form-control" placeholder="Select"
                                            required (change)="onMaterialGroupChange($event)">
                                            <ng-option *ngFor="let obj of categories" [value]="obj.categoryId"
                                          class="form-control">{{obj.categoryName}}</ng-option>
                                      </ng-select> -->
                                      <ng-select [multiple]="true" formControlName="category" #myselect (change)="onMaterialGroupChange($event)" [(ngModel)]="category">
                                        <ng-option *ngFor="let obj of categories" [value]="obj.categoryId"
                                          class="form-control">{{obj.categoryName}}</ng-option>
                                      </ng-select>
                                        <div *ngIf="addBlogForm.get('category')?.hasError('required') && addBlogForm.get('category')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Select Sub category:</label>
                                        <!-- <ng-select [multiple]="true" [items]="subcategories"
                                            formControlName="subcategoty" class="form-control" bindValue="obj.subcategoryId" bindLabel="obj.subcategoryName"  placeholder="Select" required
                                            (change)="onMaterialGroupChange($event)">
                                        </ng-select> -->
                                        <ng-select [multiple]="true" formControlName="subcategoty" #myselect (change)="onMaterialGroupChange($event)" [(ngModel)]="subcategoty">
                                            <ng-option *ngFor="let obj of subcategories" [value]="obj.subcategoryId"
                                              class="form-control">{{obj.subcategoryName}}</ng-option>
                                          </ng-select>
                                        <div *ngIf="addBlogForm.get('subcategoty')?.hasError('required') && addBlogForm.get('subcategoty')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Enter Tags:</label>
                                        <input type="text" class="form-control edittags"
                                            placeholder="Give your blog a tags" formControlName="tags">
                                            <div *ngIf="addBlogForm.get('tags')?.hasError('required') && addBlogForm.get('tags')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Author:</label>
                                        <input type="text" class="form-control edittags"
                                            placeholder="Give your blog a Author" formControlName="author">
                                            <div *ngIf="addBlogForm.get('author')?.hasError('required') && addBlogForm.get('author')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Enter Title:</label>
                                        <input type="text" class="form-control editTitle" class="form-control"
                                            placeholder="Give your blog a title" formControlName="title">
                                            <div *ngIf="addBlogForm.get('title')?.hasError('required') && addBlogForm.get('title')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="col-12 form-group editor-height">
                                        <label>Enter Description:</label>
                                        <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)"
                                            formControlName="description" (onEditorChanged)="changedEditor($event)"
                                            (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                                            <div *ngIf="addBlogForm.get('description')?.hasError('required') && addBlogForm.get('description')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Upload Banner:</label>
                                        <input type="file" (change)="onFileSelect($event)" class="btn btn-info btn-sm mt-2"
                                             formControlName="bannerImage" >
                                        <img *ngIf="imageUrl" class="imageUpload" [src]="this.apiPath + '/Documents/appImages/' + imageUrl" />
                                        <div *ngIf="addBlogForm.get('bannerImage')?.hasError('bannerImage') && addBlogForm.get('bannerImage')?.touched"
                                        class="error-block">
                                        This field is required.
                                    </div>
                                    </div>
                                    <div class="form-group">
                                        <button [disabled]="addBlogForm.invalid" type="submit"
                                            class="btn btn-success btn-block"> Update </button>
                                    </div>
                                    <div class="form-group">
                                        <button (click)="cancel()" class="btn btn-secondary btn-block"> Cancel </button>
                                    </div>
                                </div>
                            </form>
                        </article>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>
