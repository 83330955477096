// category-grid.component.ts

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ChoiceModel } from '../../model/question';

@Component({
  selector: 'app-questionchoice-grid',
  templateUrl: './questionchoice-grid.html',
  styleUrls: ['./questionchoice-grid.css'],
})
export class QuestionChoiceGridComponent implements OnInit {
  @Input() Choice: ChoiceModel[] = [];
  @Output() editChoice = new EventEmitter<ChoiceModel>();
  @Output() deleteChoice = new EventEmitter<number>();

  ngOnInit() {}

  onEdit(Choice: ChoiceModel) {
    console.log(Choice);
    this.editChoice.emit(Choice);
  }

  onDelete(id: number) {
    this.deleteChoice.emit(id);
  }
}
