<app-navbar-style-admin></app-navbar-style-admin>
<!-- your-component.component.html -->
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create Type</h4>
                        </header>
                        <article class="card-body">
                            <div class="container">
                                <h2>Create Type</h2>
                                <form [formGroup]="typeForm" (ngSubmit)="onSubmit()">
                                    <!-- General properties -->
                                    <div class="form-group">
                                        <label>Select category:</label>
                                        <ng-select [multiple]="true" formControlName="CategoryId" #myselect
                                            (change)="onMaterialGroupChange($event)" [(ngModel)]="category">
                                            <ng-option *ngFor="let obj of categories" [value]="obj.categoryId"
                                                class="form-control">{{obj.categoryName}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="typeForm.get('CategoryId')?.hasError('required') && typeForm.get('CategoryId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Select Sub category:</label>
                                        <ng-select [multiple]="true" formControlName="SubcategoryId" #myselect
                                            (change)="onMaterialGroupChange($event)" [(ngModel)]="subcategoty">
                                            <ng-option *ngFor="let obj of subcategories" [value]="obj.subcategoryId"
                                                class="form-control">{{obj.subcategoryName}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="typeForm.get('SubcategoryId')?.hasError('required') && typeForm.get('SubcategoryId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="TypeTitle">Type Title:</label>
                                        <input type="text" class="form-control" formControlName="TypeTitle">
                                        <div *ngIf="typeForm.get('TypeTitle')?.hasError('required') && typeForm.get('TypeTitle')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="TypeDescription">Type Description:</label>
                                        <input type="text" class="form-control" formControlName="TypeDescription">
                                        <div *ngIf="typeForm.get('TypeDescription')?.hasError('required') && typeForm.get('TypeDescription')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <!-- Location properties -->
                                    <div class="form-group">
                                        <label for="Location">Location:</label>
                                        <input type="text" class="form-control" formControlName="Location">
                                        <div *ngIf="typeForm.get('Location')?.hasError('required') && typeForm.get('Location')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Phone">Phone:</label>
                                        <input type="text" class="form-control" formControlName="Phone">
                                        <div *ngIf="typeForm.get('Phone')?.hasError('required') && typeForm.get('Phone')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Email">Email:</label>
                                        <input type="email" class="form-control" formControlName="Email">
                                        <div *ngIf="typeForm.get('Email').hasError('required') && typeForm.get('Email').touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Address">Address:</label>
                                        <input type="text" class="form-control" formControlName="Address">
                                        <div *ngIf="typeForm.get('Address')?.hasError('required') && typeForm.get('Address')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Street">Street:</label>
                                        <input type="text" class="form-control" formControlName="Street">
                                        <div *ngIf="typeForm.get('Street')?.hasError('required') && typeForm.get('Street')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="City">City:</label>
                                        <input type="text" class="form-control" formControlName="City">
                                        <div *ngIf="typeForm.get('City')?.hasError('required') && typeForm.get('City')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="State">State:</label>
                                        <input type="text" class="form-control" formControlName="State">
                                        <div *ngIf="typeForm.get('State')?.hasError('required') && typeForm.get('State')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Country">Country:</label>
                                        <input type="text" class="form-control" formControlName="Country">
                                        <div *ngIf="typeForm.get('Country')?.hasError('required') && typeForm.get('Country')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <!-- Additional information properties -->
                                    <div class="form-group">
                                        <label for="Tags">Tags:</label>
                                        <input type="text" class="form-control" formControlName="Tags">
                                        <div *ngIf="typeForm.get('Tags')?.hasError('required') && typeForm.get('Tags')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="MetaKeys">Meta Keys:</label>
                                        <input type="text" class="form-control" formControlName="MetaKeys">
                                        <div *ngIf="typeForm.get('MetaKeys')?.hasError('required') && typeForm.get('MetaKeys')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <!-- Date-related properties -->
                                    <div class="form-group">
                                        <label for="StartedYear">Started Year:</label>
                                        <input type="string" class="form-control" formControlName="StartedYear">
                                        <div *ngIf="typeForm.get('StartedYear')?.hasError('required') && typeForm.get('StartedYear')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="isVerified">Is Verified:</label>
                                        <input type="checkbox" class="form-check-input" formControlName="isVerified">
                                        <div *ngIf="typeForm.get('isVerified')?.hasError('required') && typeForm.get('isVerified')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>



                                    <!-- Description properties -->
                                    <div class="form-group">
                                        <label for="Description">Description:</label>
                                        <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)"
                                            formControlName="Description" (onEditorChanged)="changedEditor($event)"
                                            (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                                        <div *ngIf="typeForm.get('Description')?.hasError('required') && typeForm.get('Description')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>



                                    <!-- Status and Activation properties -->
                                    <div class="form-group">
                                        <label for="isActive">Is Active:</label>
                                        <input type="checkbox" class="form-check-input" formControlName="isActive">
                                    </div>
                                    <div class="form-group">
                                        <label for="Status">Status:</label>
                                        <input type="text" class="form-control" formControlName="Status">
                                        <div *ngIf="typeForm.get('Status')?.hasError('required') && typeForm.get('Status')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">Rating:</label>
                                        <input type="text" class="form-control" formControlName="Rating">
                                        <div *ngIf="typeForm.get('Rating')?.hasError('required') && typeForm.get('Rating')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">Open/Close:</label>
                                        <input type="text" class="form-control" formControlName="OpenClose">
                                        <div *ngIf="typeForm.get('OpenClose')?.hasError('required') && typeForm.get('OpenClose')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">Website:</label>
                                        <input type="text" class="form-control" formControlName="Website">
                                        <div *ngIf="typeForm.get('Website')?.hasError('required') && typeForm.get('Website')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">Map:(Google Map Link)</label>
                                        <input type="text" class="form-control" formControlName="Map">
                                        <div *ngIf="typeForm.get('Map')?.hasError('required') && typeForm.get('Map')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">Listing Features:(3-4 Words with comma based Example:
                                            Name,Name,Name)</label>
                                        <input type="text" class="form-control" formControlName="ListingFeatures">
                                        <div *ngIf="typeForm.get('ListingFeatures')?.hasError('required') && typeForm.get('ListingFeatures')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">Price:</label>
                                        <input type="text" class="form-control" formControlName="Price">
                                        <div *ngIf="typeForm.get('Price')?.hasError('required') && typeForm.get('Price')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">MapLink:(Google Map IFrame Copy and paste here)</label>
                                        <input type="text" class="form-control" formControlName="MapLink">
                                        <div *ngIf="typeForm.get('MapLink')?.hasError('required') && typeForm.get('MapLink')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">HeadFeatures: (3-4 Words with comma based Example:
                                            Name,Name,Name)</label>
                                        <input type="text" class="form-control" formControlName="HeadFeatures">
                                        <div *ngIf="typeForm.get('HeadFeatures')?.hasError('required') && typeForm.get('HeadFeatures')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">isHome:</label>
                                        <input type="text" class="form-control" formControlName="isHome">
                                        <div *ngIf="typeForm.get('isHome')?.hasError('required') && typeForm.get('isHome')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">Currency:</label>
                                        <input type="text" class="form-control" formControlName="Currency">
                                        <div *ngIf="typeForm.get('Currency')?.hasError('required') && typeForm.get('Currency')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <label for="Status">Salary:</label>
                                        <input type="text" class="form-control" formControlName="Salary">
                                        <div *ngIf="typeForm.get('Salary')?.hasError('required') && typeForm.get('Salary')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">CompanySize:</label>
                                        <input type="text" class="form-control" formControlName="CompanySize">
                                        <div *ngIf="typeForm.get('CompanySize')?.hasError('required') && typeForm.get('CompanySize')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">TypeofMode:</label>
                                        <input type="text" class="form-control" formControlName="TypeofMode">
                                        <div *ngIf="typeForm.get('TypeofMode')?.hasError('required') && typeForm.get('TypeofMode')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="Status">TypeofWork:</label>
                                        <input type="text" class="form-control" formControlName="TypeofWork">
                                        <div *ngIf="typeForm.get('TypeofWork')?.hasError('required') && typeForm.get('TypeofWork')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-success btn-block"
                                            [disabled]="!typeForm.valid">Save</button>
                                    </div>
                                    <div class="form-group text-center">
                                        <button routerLink="/typelist" class="btn btn-primary btn-block">
                                            Back</button>
                                    </div>

                                    <!-- <div class="form-group">
                                        <button type="submit" class="btn btn-primary">Save</button>
                                    </div> -->
                                </form>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-footer-style-two></app-footer-style-two>