
<table class="table table-striped table-bordered">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Document Name</th>
        <th scope="col">Document Description</th>
        <!-- <th scope="col">Actions</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of documenttype">
        <td>{{ document.documentName }}</td>
        <td>{{ document.documentDescp }}</td>
        <!-- <td>
          <button class="btn btn-warning btn-sm" (click)="onEdit(document)">Edit</button>
          <button class="btn btn-danger btn-sm" (click)="onDelete(document.documentTypeId)">Delete</button>
        </td> -->
      </tr>
    </tbody>
  </table>
