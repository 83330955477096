import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { DatePipe } from '@angular/common';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { SystemService } from '../../services/system.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TypeService } from '../../services/type.services';
import { VideoGallery } from '../../model/videogallery';
import { GalleryService } from '../../services/gallery.service';
import { Documenttype } from '../documenttype/documenttype';
import { VideoGalleryService } from '../../services/videoGallery.service';
@Component({
    selector: 'app-videogallery-list',
    templateUrl: './videogallery-list.component.html',
    styleUrls: ['./videogallery-list.component.scss'],
    providers: [DatePipe],
})
export class VideoGalleryListComponent implements OnInit {
    galleryform: FormGroup;
    gallery: VideoGallery; public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    userSubmitted: boolean;
    file: any;
    fileExtension: any;
    filepostedOn = new Date().toString().split("+")[0].split(':').join('_');
    imageUrl: string | ArrayBuffer = "https://www.adc-awards.archi/images/joomlart/demo/default.jpg"
    @ViewChild('editor') editor;
    fileName: any;
    documentTypelist: any;
    typelist: any;
    galleryItems: any;
    isLoading = false;
    @Input() documenttype: Documenttype[] = [];
    @Output() editDocumenttype = new EventEmitter<Documenttype>();
    @Output() deleteDocumenttype = new EventEmitter<number>();
    apiPath: any;
    constructor(private fb: FormBuilder,
        private alertify: AlertifyService,
        private router: Router,
        private typeService: TypeService,
        private galleryService: GalleryService,
        private videogalleryService: VideoGalleryService,
        private documentTypeService: DocumentTypeService,
        public service: SystemService) { }

    ngOnInit() {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.loadVideoGallery();
        this.refreshGridData();
    }

    private gridApi: any;
    private gridColumnApi: any;

    columnDefs = [
        { headerName: 'Id', field: 'videoGalleryId', sortable: true, filter: true, width: 20 },
        { headerName: 'Category', field: 'videoCategory', sortable: true, filter: true, editable: true, width: 150 },
        { headerName: 'Link', field: 'videoLink', sortable: true, filter: true, editable: true, width: 200 },
        { headerName: 'Description', field: 'videoDescp', sortable: true, filter: true, editable: true, width: 200 },
        {
            headerName: 'Actions',
            cellRenderer: this.actionsCellRenderer.bind(this),
            sortable: false,
            filter: false,
            width: 120
        },

    ];

    defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true
    };

    rowData: any;

    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    imageCellRenderer(params: any) {
        return `<img src="${this.apiPath + '/Documents/appImages/' + params.value}" alt="Image" style="max-width: 50px; max-height: 50px;">`;
    }
    actionsCellRenderer(params: any) {
        // const addButton = document.createElement('button');
        // addButton.innerHTML = 'Add';
        // addButton.classList.add('Actions-btn'); // Apply the CSS class
        // addButton.addEventListener('click', () => this.addRow());

        const editButton = document.createElement('button');
        editButton.innerHTML = 'Edit';
        editButton.classList.add('Actions-btn'); // Apply the CSS class
        editButton.addEventListener('click', () => this.editRow(params.data));

        const deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'Delete';
        deleteButton.classList.add('Actions-btn'); // Apply the CSS class
        deleteButton.addEventListener('click', () => this.deleteRow(params.data));

        const wrapper = document.createElement('div');
        wrapper.appendChild(editButton);
        //wrapper.appendChild(addButton);
        wrapper.appendChild(deleteButton);

        return wrapper;
    }
    refreshGridData(): void {
        this.loadVideoGallery();
        this.gridApi.setRowData(this.rowData); // Refresh the grid with the updated data
    }

    addRow() {
        this.router.navigate(['/VideoGalleryAdd']);
    }
    editRow(rowData: any) {
        // Implement your edit logic here

        console.log('Edit row:', rowData);
        let id = rowData.videoGalleryId;
        console.log('id edit row:', id);
        this.router.navigate(['/VideoGalleryEdit', id]);

    }
    deleteRow(rowData: any) {
        const userConfirmed = confirm('Are you sure you want to delete?');
        console.log(rowData.videoGalleryId,"rowData.videoGalleryId")
        if (userConfirmed) {
            let res = this.videogalleryService.deleteGallery(rowData.videoGalleryId);
            console.log(res,"res delete gellery");
             this.refreshGridData();
            this.loadVideoGallery();
            this.alertify.success("Deleted Successfully");
        } else {
            console.log('Deletion canceled.');
        }
    }

    loadVideoGallery() {
        this.service.App.ShowLoader = true;
        this.videogalleryService.getAllGallerys().then(gallerylist => {
            this.galleryItems = gallerylist;
            this.rowData = gallerylist;
            console.log(JSON.stringify(this.galleryItems));
        });
        this.service.App.ShowLoader = false;

    }

    addGallery() {
        this.router.navigate(['/VideoGalleryAdd']);
    }


}

