// category.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Menu } from '../model/menu';
import { SystemService } from './system.service';


@Injectable({
    providedIn: 'root',
})
export class MenuService {
    apiUrl: any;
    BearerToken: any;
    menus: Menu[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    async getAllMenu(): Promise<Menu[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<Menu[]>("MenuMaster");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching categories:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }

    getMenuById(id: number): Observable<Menu> {
        return this.http.get<Menu>(`${this.apiUrl}/api/MenuMaster/${id}`);
    }

    async addMenu(menu: Menu) {
        menu.menuId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("MenuMaster", menu);
        return res;
    }

    async updateMenu(menu: Menu): Promise<Observable<Menu>> {
        let res = await this.service.Data.ExecuteAPI_Put("MenuMaster",`${menu.menuId}?menuid=${menu.menuId}`, menu);
        return res;
    }
 
    async deleteMenu(id: number)
    {
         let res = await this.service.Data.ExecuteAPI_Delete("MenuMaster", `${id}?menuid=${id}`);
         return res;
    }
}
