import { Documenttype } from './../../model/documenttype';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-Documenttype-grid',
  templateUrl: './documenttype-grid.html',
  styleUrls: ['./documenttype-grid.css'],
})
export class DocumenttypeGridComponent implements OnInit {
  @Input() documenttype: Documenttype[] = [];
  @Output() editDocumenttype = new EventEmitter<Documenttype>();
  @Output() deleteDocumenttype = new EventEmitter<number>();

  ngOnInit() {}

  onEdit(documenttype: Documenttype) {
    console.log(documenttype);
    this.editDocumenttype.emit(documenttype);
  }

  onDelete(DocumenttypeId: number) {
    const userConfirmed = confirm('Are you sure you want to delete?');
    if (userConfirmed) {
    this.deleteDocumenttype.emit(DocumenttypeId);
    }
  }
}
