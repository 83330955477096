<!-- category-grid.component.html -->

<table class="table table-striped table-bordered">
  <thead class="thead-dark">
    <tr>
      <th scope="col">Category Name</th>
      <th scope="col">SubCategory Name</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let subcategory of subcategories">
      <td>{{ subcategory.categoryName }}</td>
      <td>{{ subcategory.subcategoryName }}</td>
      <td>
        <button class="btn btn-warning btn-sm" (click)="onEdit(subcategory)">Edit</button>
        <button class="btn btn-danger btn-sm" (click)="onDelete(subcategory.subcategoryId)">Delete</button>
      </td>
    </tr>
  </tbody>
</table>