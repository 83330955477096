
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterHeaderModel } from '../../model/filterHeader';
import { FilterHeaderService } from '../../services/filterHeader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';

@Component({
    selector: 'app-filterHeader_edit-form',
    templateUrl: './filterHeader_edit.html',
    styleUrls: ['./filterHeader_edit.css'],
})
export class FilterHeaderEditformComponent implements OnInit {
    filterHeaderform: FormGroup;
    FilterHeader: any;
    selectedFilterHeaderModel: FilterHeaderModel | null = null;
    id: string;


    constructor(private fb: FormBuilder, private FilterHeaderModelService: FilterHeaderService,private router: Router,private route: ActivatedRoute,private alertify: AlertifyService) {
        this.id = this.route.snapshot.paramMap.get('id');
        this.loadFilterHeaderId();
    }

    ngOnInit() {
        this.filterHeaderform = this.fb.group({
            filterId: 0,
            filterHeaderName1: ['', Validators.required],
            filterHeaderDescp: ['', Validators.required],

        });
    }

    Back() {
        this.router.navigate['/filterHeaderlist']
    }

    loadFilterHeaderId() {
        this.FilterHeaderModelService.getFilterHeaderById(Number(this.id)).then(FilterHeader => {
            this.FilterHeader = FilterHeader;
            this.filterHeaderform.patchValue(FilterHeader);
            console.log(JSON.stringify(this.FilterHeader));
        });
    }

    async onSubmit() {
        const FilterHeaderModel: FilterHeaderModel = this.filterHeaderform.getRawValue();
        console.log("Update:"+ JSON.stringify(FilterHeaderModel));
        let obj =  this.filterHeaderform.getRawValue();
        if (obj.filterId) {
            // Update existing FilterHeaderModel
            let res = this.FilterHeaderModelService.updateFilterHeader(obj);
            console.log("Update:"+ res);
            if (res) {
                this.router.navigate(['/filterHeaderlist']);
                this.alertify.success("Data Updated Successfully");
                this.filterHeaderform.reset();
                this.selectedFilterHeaderModel = null;
            }
        }
    }

    onEdit(FilterHeaderModel: FilterHeaderModel) {
        // Load the selected FilterHeaderModel into the form for editing
        this.selectedFilterHeaderModel = FilterHeaderModel;
        this.filterHeaderform.patchValue(FilterHeaderModel);
    }

}


export { FilterHeaderModel };

