import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { NewBlogComponent } from './components/admin/newBlog/newBlog.component';
import { AdminPanelComponent } from './components/admin/adminPanel/adminPanel.component';
import { UserDasboardComponent } from './components/admin/userDasboard/userDasboard.component';
import { BlogUserComponent } from './components/admin/blog/blog-user.component';
import { BlogDetailsViewComponent } from './components/admin/blog-details/blog-details-view.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { BlogDetailsModifyComponent } from './components/admin/blog-details/blog-details-modify.component';
import { BlogDetailsAddComponent } from './components/admin/blog-post/blog-details-add.component';
import { SubCategoryFormComponent } from './components/admin/subcategory/subcategory';
import { CategoryFormComponent } from './components/admin/category/category';
import { MenuFormComponent } from './components/admin/Menu/menu'
import { SubMenuFormComponent } from './components/admin/Sub menu/sub-menu';
import { MenuContentFormComponent } from './components/admin/Menu Content/menu-content';
import { ProfileFormComponent } from './components/pages/profile/profile';
import { AuthGuard } from './auth.guard';
import { DocumenttypeFormComponent } from './components/admin/documenttype/documenttype';
import { GalleryAddComponent } from './components/admin/gallery/gallery-add.component';
import { GalleryListComponent } from './components/admin/gallery/gallery-list.component';
import { GalleryEditComponent } from './components/admin/gallery/gallery-edit.component';
import { TypeListComponent } from './components/admin/type/type-list.component';
import { TypeEditComponent } from './components/admin/type/type-edit.component';
import { TypeAddComponent } from './components/admin/type/type-add.component';

import { MenuPageAddContentformComponent } from './components/admin/menupagecontent/menupage-addcontent';
import { MenuPageEditContentformComponent } from './components/admin/menupagecontent/menupage-editcontent';
import { MenuPageListContentformComponent } from './components/admin/menupagecontent/menupage-listcontent';

import { BannerListComponent } from './components/admin/banner/banner-list.component';
import { BannerAddComponent } from './components/admin/banner/banner-add.component';
import { FilterHeaderAddformComponent } from './components/admin/filterHeader/filterHeader_add';
import { FilterHeaderListformComponent } from './components/admin/filterHeader/filterHeader_list';
import { FilterHeaderEditformComponent } from './components/admin/filterHeader/filterHeader_edit';
import { FilterHeaderAddSearchformComponent } from './components/admin/filterHeaderSearch/filterHeader_addSearch';
import { FilterHeaderEditSearchformComponent } from './components/admin/filterHeaderSearch/filterHeader_editSearch';
import { FilterHeaderListSearchformComponent } from './components/admin/filterHeaderSearch/filterHeader_listSearch';
import { FilterHeaderAddValueformComponent } from './components/admin/filterHeaderValues/filterHeader_addValue';
import { FilterHeaderEditValueformComponent } from './components/admin/filterHeaderValues/filterHeader_editValue';
import { FilterHeaderListValueformComponent } from './components/admin/filterHeaderValues/filterHeader_listValue';
import { QuestionnaireAddComponent } from './components/admin/questionnairefrm/questionnaire-add.component';
import { QuestionnaireEditComponent } from './components/admin/questionnairefrm/questionnaire-edit.component';
import { QuestionnaireListComponent } from './components/admin/questionnairefrm/questionnaire-list.component';
import { QuestionsAddComponent } from './components/admin/questionfrm/questions-add.component';
import { QuestionsListComponent } from './components/admin/questionfrm/questions-list.component';

import { QuestionsEditComponent } from './components/admin/questionfrm/questions-edit.component';
import { QuestionChoiceGridComponent } from './components/admin/questionchoice/questionchoice-grid';
import { ChoiceFormComponent } from './components/admin/questionchoice/questionchoice';
import { QuestiontypeFormComponent } from './components/admin/questiontype/questiontype';
import { BannerEditsComponent } from './components/admin/banner/banner-edits.component';
import { Reset_PasswordComponent } from './components/pages/login-register/reset_password';
import { QuestionsListSubComponent } from './components/admin/questionfrmsub/questions-listSub.component';
import { QuestionsEditSubComponent } from './components/admin/questionfrmsub/questions-editSub.component';
import { QuestionsAddSubComponent } from './components/admin/questionfrmsub/questions-addSub.component';
import { VideoGalleryListComponent } from './components/admin/videogallery/videogallery-list.component';
import { VideoGalleryEditComponent } from './components/admin/videogallery/videogallery-edit.component';
import { VideoGalleryAddComponent } from './components/admin/videogallery/videogallery-add.component';
import { ImageGalleryListComponent } from './components/admin/imagegallery/imagegallery-list.component';
import { ImageGalleryEditComponent } from './components/admin/imagegallery/imagegallery-edit.component';
import { ImageGalleryAddComponent } from './components/admin/imagegallery/imagegallery-add.component';
import { logoFormComponent } from './components/pages/Logo/logo';
import { blogcommentListComponent } from './components/admin/blogcomments/blogcomments-list.component';
import { BlogDetailsUserViewComponent } from './components/admin/blogcomments/blog-details-user-view.component';
import { responseListComponent } from './components/admin/response/response-list.component';
import { faqListComponent } from './components/admin/faq/faq-list.component';
import { faqEditComponent } from './components/admin//faq/faq-edit.component';
import { faqAddComponent } from './components/admin/faq/faq-add.component';
export const routes: Routes = [

    { path: '', component: AdminPanelComponent },
    { path: 'home', component: AdminPanelComponent },
    { path: 'error', component: ErrorComponent, data: { isAdminshow: true } },
    { path: 'login-register', component: LoginRegisterComponent, data: { isAdminshow: true } },
    { path: 'coming-soon', component: ComingSoonComponent, data: { isAdminshow: true } },
    { path: 'blog-user', component: BlogUserComponent, data: { isAdminshow: true } },
    { path: 'blog-details-view/:id', component: BlogDetailsViewComponent, data: { isAdminshow: true } },
    { path: 'blog-details-modify/:id', component: BlogDetailsModifyComponent, canActivate: [AuthGuard] },
    { path: 'blog-details-add', component: BlogDetailsAddComponent, canActivate: [AuthGuard] },
    { path: 'newBlog', component: NewBlogComponent, data: { isAdminshow: true } },
    { path: 'adminPanel', component: AdminPanelComponent, canActivate: [AuthGuard] },
    { path: 'userdashboard', component: UserDasboardComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'mastercategory', component: CategoryFormComponent, canActivate: [AuthGuard] },
    { path: 'masterMenu', component: MenuFormComponent, canActivate: [AuthGuard] },
    { path: 'SubMasterMenu', component: SubMenuFormComponent, canActivate: [AuthGuard] },
    { path: 'MenuContent', component: MenuContentFormComponent, canActivate: [AuthGuard] },

    { path: 'AddMenuPageContent', component: MenuPageAddContentformComponent, canActivate: [AuthGuard] },
    { path: 'EditMenuPageContent/:id', component: MenuPageEditContentformComponent, canActivate: [AuthGuard] },
    { path: 'MenuPageContent', component: MenuPageListContentformComponent, canActivate: [AuthGuard] },

    { path: 'mastersubcategory', component: SubCategoryFormComponent, canActivate: [AuthGuard] },
    { path: 'profiles', component: ProfileFormComponent, canActivate: [AuthGuard] },
    { path: 'documenttype', component: DocumenttypeFormComponent, canActivate: [AuthGuard] },
    { path: 'addgallery', component: GalleryAddComponent, canActivate: [AuthGuard] },
    { path: 'gallerylist', component: GalleryListComponent, canActivate: [AuthGuard] },
    { path: 'editgallery/:id', component: GalleryEditComponent, canActivate: [AuthGuard] },
    { path: 'typelist', component: TypeListComponent, canActivate: [AuthGuard] },
    { path: 'typeedit/:id', component: TypeEditComponent, canActivate: [AuthGuard] },
    { path: 'typeadd', component: TypeAddComponent, canActivate: [AuthGuard] },
    // { path: 'typeadd', component: TypeAddComponent, canActivate: [AuthGuard] },
    { path: 'addbanner', component: BannerAddComponent, canActivate: [AuthGuard] },
    { path: 'bannerlist', component: BannerListComponent, canActivate: [AuthGuard] },
    { path: 'editsbanner/:id', component: BannerEditsComponent, canActivate: [AuthGuard] },

    { path: 'filterHeaderadd', component: FilterHeaderAddformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeaderedit/:id', component: FilterHeaderEditformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeaderlist', component: FilterHeaderListformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeaderaddSearch', component: FilterHeaderAddSearchformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeadereditSearch/:id', component: FilterHeaderEditSearchformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeaderlistSearch', component: FilterHeaderListSearchformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeaderaddValue', component: FilterHeaderAddValueformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeadereditValue/:id', component: FilterHeaderEditValueformComponent, canActivate: [AuthGuard] },
    { path: 'filterHeaderlistValue', component: FilterHeaderListValueformComponent, canActivate: [AuthGuard] },
    { path: 'questionnairelist', component: QuestionnaireListComponent, canActivate: [AuthGuard] },
    { path: 'questionnaireedit/:id', component: QuestionnaireEditComponent, canActivate: [AuthGuard] },
    { path: 'questionnaireadd', component: QuestionnaireAddComponent, canActivate: [AuthGuard] },
    { path: 'questionslist', component: QuestionsListComponent, canActivate: [AuthGuard] },
    { path: 'questionsedit/:id', component: QuestionsEditComponent, canActivate: [AuthGuard] },
    { path: 'questionsadd', component: QuestionsAddComponent, canActivate: [AuthGuard] },
    { path: 'choiceformadd/:id', component: ChoiceFormComponent, canActivate: [AuthGuard] },
    { path: 'questionChoice', component: QuestionChoiceGridComponent, canActivate: [AuthGuard] },
    { path: 'questiontypeform/:id', component: QuestiontypeFormComponent, canActivate: [AuthGuard] },
    { path: 'reset_password', component: Reset_PasswordComponent },
    { path: 'questionslistSub/:id', component: QuestionsListSubComponent, canActivate: [AuthGuard] },
    { path: 'questionseditSub/:id', component: QuestionsEditSubComponent, canActivate: [AuthGuard] },
    { path: 'questionsaddSub', component: QuestionsAddSubComponent, canActivate: [AuthGuard] },
    { path: 'Videogallerylist', component: VideoGalleryListComponent, canActivate: [AuthGuard] },
    { path: 'VideoGalleryEdit/:id', component: VideoGalleryEditComponent, canActivate: [AuthGuard] },
    { path: 'VideoGalleryAdd', component: VideoGalleryAddComponent, canActivate: [AuthGuard] },
    { path: 'ImageGalleryList', component: ImageGalleryListComponent, canActivate: [AuthGuard] },
    { path: 'ImageGalleryEdit/:id', component: ImageGalleryEditComponent, canActivate: [AuthGuard] },
    { path: 'ImageGalleryAdd', component: ImageGalleryAddComponent, canActivate: [AuthGuard] },
    { path: 'logo', component: logoFormComponent, canActivate: [AuthGuard] },
    { path: 'blogcomments', component: blogcommentListComponent, canActivate: [AuthGuard] },
    { path: 'blog-details-user-view/:id', component: BlogDetailsUserViewComponent, data: { isAdminshow: true } },
    { path: 'faqList', component: faqListComponent, data: { isAdminshow: true } },
    { path: 'faqEdit/:id', component: faqEditComponent, data: { isAdminshow: true } },
    { path: 'faqAdd', component: faqAddComponent, data: { isAdminshow: true } },

    { path: 'response', component: responseListComponent, data: { isAdminshow: true } },

    // Here add new pages component
    { path: '**', component: ErrorComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
