<app-navbar-style-admin></app-navbar-style-admin>
<!-- your-component.component.html -->
<div class="blog-details-area pt-50 pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <!-- <div class="d-flex justify-content-center signBody align-middle "> -->
                    <div class="card loginCard">
                        <header class="card-header" style="background: var(--mainColor);border-radius:100px;">
                            <h4 class="card-title mt-2 text-center" style="color:white">Create Questionnaire</h4>
                        </header>
                        <article class="card-body">
                            <div class="container">
                                <form [formGroup]="questionnaireForm" (ngSubmit)="onSubmit()">
                                    <!-- General properties -->
                                    <div class="form-group">
                                        <label tooltip="select category here">Select category:</label>
                                        <ng-select formControlName="CategoryId" #myselect
                                            (change)="onMaterialGroupChange($event)" [(ngModel)]="category">
                                            <ng-option *ngFor="let obj of categories" [value]="obj.categoryId"
                                                class="form-control">{{obj.categoryName}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="questionnaireForm.get('CategoryId')?.hasError('required') && questionnaireForm.get('CategoryId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label tooltip="select subcategory here">Select Sub category:</label>
                                        <ng-select [multiple]="true" formControlName="SubcategoryId" #myselect
                                            (change)="onMaterialGroupChange($event)" [(ngModel)]="subcategoty">
                                            <ng-option *ngFor="let obj of subcategories" [value]="obj.subcategoryId"
                                                class="form-control">{{obj.subcategoryName}}</ng-option>
                                        </ng-select>
                                        <div *ngIf="questionnaireForm.get('SubcategoryId')?.hasError('required') && questionnaireForm.get('SubcategoryId')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Identifier" tooltip="Enter Identifier here">Identifier:</label>
                                        <input type="text" class="form-control" formControlName="Identifier">
                                        <div *ngIf="questionnaireForm.get('Identifier')?.hasError('required') && questionnaireForm.get('Identifier')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Name" tooltip="Enter Name here">Name:</label>
                                        <input type="text" class="form-control" formControlName="Name">
                                        <div *ngIf="questionnaireForm.get('Name')?.hasError('required') && questionnaireForm.get('Name')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>
                                    </div>


                                    <div class="form-group">

                                        <label for="Description" tooltip="Enter Description here">Description:</label>
                                        <quill-editor [styles]="{height: '200px'}" (onFocus)="focus($event)"
                                            formControlName="Description" (onEditorChanged)="changedEditor($event)"
                                            (onBlur)="blur($event)" (onEditorCreated)="created($event)"></quill-editor>
                                        <div *ngIf="questionnaireForm.get('Description')?.hasError('required') && questionnaireForm.get('Description')?.touched"
                                            class="error-block">
                                            This field is required.
                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <label for="CategoryNameHyphenated" tooltip="Enter CategoryNameHyphenated here">CategoryNameHyphenated:</label>
                                        <input type="text" class="form-control"
                                            formControlName="CategoryNameHyphenated">
                                        <div
                                            *ngIf="questionnaireForm.get('CategoryNameHyphenated').hasError('required') && questionnaireForm.get('CategoryNameHyphenated').touched">
                                            Category Name Hyphenated
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <label for="Language">Language:</label>
                                        <select class="form-control" formControlName="LanguageId">
                                            <option *ngFor="let lang of languageOptions" [value]="lang.code">{{
                                                lang.name }}</option>
                                        </select>
                                        <div *ngIf="questionnaireForm.get('LanguageId')?.hasError('required') && questionnaireForm.get('LanguageId')?.touched"
                                            class="error-block">
                                            Please select a language.
                                        </div>
                                    </div> -->
                                    <div class="form-group">
                                        <label for="Language" tooltip="Select Language here">Language:</label>
                                        <!-- <select class="form-control" formControlName="LanguageId">
                                            <option *ngFor="let lang of languageOptions" [value]="lang.code">{{
                                                lang.name }}</option>
                                        </select> -->
                                        <ng-select formControlName="LanguageId" #myselect
                                        [(ngModel)]="LanguageId">
                                            <ng-option *ngFor="let lang of languageOptions" [value]="lang.code"
                                                class="form-control">{{lang.name}}</ng-option>
                                        </ng-select>
                                      
                                        <div *ngIf="questionnaireForm.get('LanguageId')?.hasError('required') && questionnaireForm.get('LanguageId')?.touched"
                                            class="error-block">
                                            Please select a language.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary">Save</button>
                                        <button (click)="cancel()" class="btn btn-secondary "tooltip="you will land in Questionnaries dashboard"> Cancel </button>
                                    </div>
                                </form>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-footer-style-two></app-footer-style-two>
