

<table class="table table-striped table-bordered">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Menu Name</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of menu">
        <td>{{ item.menuName }}</td>
        <td>
          <button class="btn btn-warning btn-sm" (click)="onEdit(item)">Edit</button>
          <button class="btn btn-danger btn-sm" (click)="onDelete(item.menuId)">Delete</button>
        </td>
      </tr>
    </tbody>
  </table>
