// category-grid.component.ts

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SubCategory } from '../../model/subcategory';

@Component({
  selector: 'app-subcategory-grid',
  templateUrl: './subcategory-grid.html',
  styleUrls: ['./subcategory-grid.css'],
})
export class SubCategoryGridComponent implements OnInit {
  @Input() subcategories: SubCategory[] = [];
  @Output() editSubCategory = new EventEmitter<SubCategory>();
  @Output() deleteSubCategory = new EventEmitter<number>();
    subcategory: SubCategory;

  ngOnInit() {}

  onEdit(subcategory: SubCategory) {
    console.log(JSON.stringify(subcategory));
    this.editSubCategory.emit(subcategory);

  }

  onDelete(subcategoryId: number) {
    this.deleteSubCategory.emit(subcategoryId);
  }
}
